import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Chip from '@material-ui/core/Chip';
import cardBgImage from '../../images/basketball.png';
import TeamShopIcon from '../TeamShopIcon/TeamShopIcon';
import SponsorAd from '../SponsorContainer/SponsorAd';
import { withRouter } from 'react-router-dom';
import OutfitIcon from '../CustomIcons/OutfitIcon';
import UniformMatchup from '../UniformMatchup/UniformMatchup';
import NoGameInSessionContainer from '../noGameContainer/NoGameInSessionContainer';
import AppDataContext from '../../common/AppContext';
import RouteConstants from '../../common/RouteConstants';
import AppConstants from '../../common/AppConstants';
import Helper from '../../common/Helper';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(5),
    },
    mainContainer: {
        background: 'rgba(0, 0, 0, .65) !important',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(-3),
    },
    cardRoot: {
        background: 'rgba(87, 87, 87, .5) !important',
        border: '1px solid rgba(255, 255, 255, .3)',
        boxShadow: 'none',
        height: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: theme.spacing(1.25),
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: '50% 100% !important',
        '& .MuiCardHeader-root': {
            padding: theme.spacing(0, 2, 1, 2),
        },
        '& .MuiCardContent-root': {
            padding: theme.spacing(1, 2),
        },
        '& .MuiCardHeader-avatar': {
            display: 'flex',
            width: '100%',
            marginRight: '0',
        },
    },
    matchCenter: {
        '& .MuiTypography-h6': {
            color: '#fff',
            fontFamily: '"Action NBA  Web" !important',
            fontSize: '1.1rem',
            fontWeight: '300',
            lineHeight: '1',
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.2rem',
            },
        },
        '& .MuiSvgIcon-root': {
            color: '#888888',
            width: '20px',
            height: '20px',
            marginRight: '5px',
        },
    },
    matchLocation: {
        borderLeft: '2px solid #888 !important',
        color: '#888888 !important',
        paddingLeft: theme.spacing(1),
        marginLeft: theme.spacing(3),
        fontSize: '.625rem !important',
        textTransform: 'uppercase',
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1.5),
        },
    },
    MatchTime: {
        color: '#fff !important',
        fontSize: '0.9rem',
        // position: 'relative',
        lineHeight: '1',
        padding: theme.spacing(1, 0, 0, 2),
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.0rem',
        },
        '&:after': {
            content: '""',
            // position: 'absolute',
            bottom: '0',
            left: '0',
            width: '90%',
            height: '1px',
        },
        '& span': {
            color: '#888888',
            fontSize: '.625rem',
            textTransform: 'uppercase',
            // position: 'absolute',
            right: '-8px',
            top: '0',
        },
    },
    MatchSeason: {
        paddingTop: theme.spacing(.5),
        fontSize: '.75rem',
        color: '#888888',
        [theme.breakpoints.only('xs')]: {
            whiteSpace: 'nowrap',
        },
    },
    teamIcons: {
        maxWidth: '110px',
        border: '1px solid #888888',
        cursor: 'pointer',
        [theme.breakpoints.only('md')]: {
            maxWidth: '100px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100px',
        },
    },
    versus: {
        fontFamily: '"Action NBA  Web" !important',
        fontWeight: '500 !important',
        color: '#fff !important',
    },
    outfitCartIcon: {
        border: '1px solid #888888',
        borderRadius: '6px',
        background: 'transparent',
        height: '40px',
        width: '100%',
        maxWidth: '100px',
        color: '#ffffff',
        margin: '5px',
        textTransform: 'uppercase',
        fontSize: '.75rem',
        '& a, & a:hover, & a:focus,': {
            textDecoration: 'none',
            color: '#ffffff',
        },
        '& img': {
            filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(15deg) brightness(100%) contrast(103%)',
        },
        cursor: 'pointer'
    },
    teamName: {
        fontFamily: '"Action NBA  Web" !important',
        fontWeight: '300',
        color: '#fff !important',
        lineHeight: '1 !important',
    },
    textCenter: {
        textAlign: 'center',
        color: '#888888',
    },
    NoGamesFoundTxt: {
        textAlign: 'center',
        color: '#FFF',
    },
    gamesHeading: {
        color: '#888888',
        paddingBottom: theme.spacing(1.5),
    },
    customChip: {
        height: 'auto',
        padding: '2px 0',
        '& .MuiChip-label': {
            paddingLeft: theme.spacing(.75),
            paddingRight: theme.spacing(.75),
        },
    },
    customEditionChip: {
        position: 'absolute',
        zIndex: '1',
        top: '0',
        width: '100%',
        left: '0',
        right: '0',
        '& .MuiChip-root': {
            height: 'auto',
            fontSize: '.70rem',
            fontWeight: '500',
            border: '1px solid #b5b5b5',
            '& .MuiChip-label': {
                paddingLeft: theme.spacing(.75),
                paddingRight: theme.spacing(.75),
            },
        },
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    teamOutfitIcons: {
        maxWidth: '120px',
        margin: '0 auto',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100px',
        },
    },
    chipPosition: {
        position: 'relative',
    },
    chip: {
        fontSize: '.60rem',
        letterSpacing: '0.0500em',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.5, 0, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        [theme.breakpoints.down('md')]: {
            fontSize: '.60rem',
        },
        '& .MuiChip-label': {
            padding: theme.spacing(0, 1, 0, 1),
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, .5, 0, .5)
            },
        },
    },
    teamLogo: {
        maxWidth: '35px',
        left: theme.spacing(.5),
        top: theme.spacing(.75),
    },
    seasonSpecialLogo: {
        maxWidth: '60px',
        paddingTop: '20px',
        left: theme.spacing(.5),
        top: theme.spacing(.75),
    },
    link: {
        color: "rgba(11, 11, 11, .5) !important",
        cursor: "pointer",
        textDecoration: "none",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
    }
}));

function CardContainer(props) {
    const classes = useStyles();
    const Games = props.games;
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [gameId, setGameId] = useState("")
    const [homeTeam, setHomeTeamName] = useState("");
    const [awayTeam, setAwayTeamName] = useState("");
    const [homeTeamId, setHomeTeamId] = useState("");
    const [awayTeamId, setAwayTeamId] = useState("");
    const [homeTeamUniformImg, setHomeTeamUniformImage] = useState("");
    const [awayTeamUniformImg, setAwayTeamUniformImage] = useState("");
    const [homeTeamEdition, setHomeTeamEdition] = useState("");
    const [awayTeamEdition, setAwayTeamEdition] = useState("");
    const [homeTeamNameSEO, setHomeTeamNameSEO] = useState("");
    const [awayTeamNameSEO, setAwayTeamNameSEO] = useState("");
    const { allTeams, AssetFactory } = useContext(AppDataContext);

    const pushToGameOutfit = (gameId, teamId, teamName) => {

        let teams = allTeams.filter(function (el) {
            return el.teamName == teamName;
        });
        let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";
        props.history.push({
            pathname: `${RouteConstants.GAME_OUTFIT}/${teamSEOName}?gameid=${gameId}`,
            state: { gameId: gameId, teamId: teamId }
        })
    }
    const pushToOutfitDetail = (gameId, teamId, teamName) => {

        let teams = allTeams.filter(function (el) {
            return el.teamName == teamName;
        });
        let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";
        props.history.push({
            pathname: `${RouteConstants.GAME_OUTFIT}/${teamSEOName}?gameid=${gameId}`,
            state: { outfitGameId: gameId, outfitTeamId: teamId }
        })
    }
    const uniformMatchUp = (gameId, homeTeam, awayTeam, homeTeamId, awayTeamId, homeTeamUniformImg, awayTeamUniformImg, homeTeamEdition, awayTeamEdition, homeTeamNameSEO, awayTeamNameSEO) => {
        setGameId(gameId);
        setHomeTeamName(homeTeam);
        setAwayTeamName(awayTeam);
        setHomeTeamId(homeTeamId);
        setAwayTeamId(awayTeamId);
        setHomeTeamUniformImage(homeTeamUniformImg);
        setAwayTeamUniformImage(awayTeamUniformImg);
        setHomeTeamEdition(homeTeamEdition);
        setAwayTeamEdition(awayTeamEdition);
        setHomeTeamNameSEO(homeTeamNameSEO);
        setAwayTeamNameSEO(awayTeamNameSEO);
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    return (
        <>
            <Grid container spacing={2}>
                {props.games.map((item, index) => {
                    if (!item.isSponserAd) {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                <Card className={`${classes.cardRoot} cardRoot`}>
                                    <Link className={classes.link} onClick={(e) => {
                                        e.preventDefault();
                                        uniformMatchUp(item.gameId, item.homeTeam, item.awayTeam, item.homeTeamId, item.awayTeamId, item.homeTeamUniformImg, item.awayTeamUniformImg, item.homeTeamEdition, item.awayTeamEdition, item.homeTeamNameSEO, item.awayTeamNameSEO);
                                    }}>
                                        <CardContent>
                                            <Grid container spacing={2} alignItems="center" justify="center" className={classes.textCenter}>
                                                <Grid item xs={5}>
                                                    <Box mb={.75} display="flex" alignItems="center" justifyContent="center" position="relative" left="-4px">
                                                        <img src={item.awayTeamLogoURL} className={`${classes.teamLogo} teamLogo`} alt={`NBA LockerVision ${item.awayTeam}`} title={`NBA LockerVision ${item.awayTeam}`} />
                                                        <Typography variant="h5" className={classes.teamName}>
                                                            {item.awayTeam}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}></Grid>
                                                <Grid item xs={5}>
                                                    <Box mb={.75} display="flex" alignItems="center" justifyContent="center" position="relative" left="-4px">
                                                        <img src={item.homeTeamLogoURL} className={`${classes.teamLogo} teamLogo`} alt={`NBA LockerVision ${item.homeTeam}`} title={`NBA LockerVision ${item.homeTeam}`} />
                                                        <Typography variant="h5" className={classes.teamName}>
                                                            {item.homeTeam}
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                
                                            </Grid>
                                            <Grid container spacing={2} alignItems="center" justify="center" className={classes.textCenter}>
                                                <Grid item xs={5} className={classes.chipPosition}>
                                                    <Box display="flex" justifyContent="center" className={`${classes.customEditionChip} customEditionChip`}>
                                                        <Chip label={item.awayTeamEdition} style={Helper.checkForComingSoon(item.awayTeamUniformImg) ? { background: "black", color: "white" } : { background: `${item.awayTeamBGColor}`, color: `${item.awayTeamTextColor}` }} />
                                                    </Box>
                                                    <Box display="block" className={`${classes.teamOutfitIcons} teamOutfitIcons`}>
                                                        <img src={Helper.getAssetType(item.awayTeamUniformImg, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.THUMBNAIL_KEY))}
                                                            className={`${classes.teamIcons} teamIcons`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                pushToGameOutfit(item.gameId, item.awayTeamId, item.awayTeam);
                                                            }}
                                                            alt={`NBA LockerVision ${item.awayTeam} ${item.awayTeamEdition}`} title={`NBA LockerVision ${item.awayTeam} ${item.awayTeamEdition}`} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography variant="h5" className={classes.versus}>
                                                        AT
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} className={classes.chipPosition}>
                                                    <Box display="flex" justifyContent="center" className={`${classes.customEditionChip} customEditionChip`}>
                                                        <Chip label={item.homeTeamEdition} style={Helper.checkForComingSoon(item.homeTeamUniformImg) ? { background: "black", color: "white" } : { background: `${item.homeTeamBGColor}`, color: `${item.homeTeamTextColor}` }} />
                                                    </Box>
                                                    <Box display="block" className={`${classes.teamOutfitIcons} teamOutfitIcons`}>
                                                        <img src={Helper.getAssetType(item.homeTeamUniformImg, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.THUMBNAIL_KEY))}
                                                            className={`${classes.teamIcons} teamIcons`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                pushToGameOutfit(item.gameId, item.homeTeamId, item.homeTeam);
                                                            }}
                                                            alt={`NBA LockerVision ${item.homeTeam} ${item.homeTeamEdition}`} title={`NBA LockerVision ${item.homeTeam} ${item.homeTeamEdition}`} />
                                                    </Box>
                                                </Grid>

                                            </Grid>

                                            <Grid container spacing={2} alignItems="center" justify="center" className={classes.textCenter}>
                                                <Grid item xs={5} alignItems="center" justify="center">
                                                    <Box alignItems="center" display="flex" justifyContent="center" flexDirection="column">
                                                        <Chip
                                                            label="Game Outfitting"
                                                            clickable
                                                            component="a"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                pushToGameOutfit(item.gameId, item.awayTeamId, item.awayTeam);
                                                            }}
                                                            onDelete={() => { return }}
                                                            deleteIcon={<></>}
                                                            variant="outlined"
                                                            color="primary"
                                                            className={`${classes.chip} btnChip`}
                                                        />
                                                        <TeamShopIcon teamName={item.awayTeam} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {item.seasonSpecial === true && <Box alignItems="center" display="flex" justifyContent="center" flexDirection="column">
                                                        <img src={item.seasonSpecialLogoUrl}
                                                            className={`${classes.seasonSpecialLogo} seasonSpecialLogo`}
                                                            alt={`NBA LockerVision ${item.seasonSpecialName}`} title={`NBA LockerVision ${item.seasonSpecialName}`} />
                                                    </Box>
                                                    }
                                                </Grid>
                                                <Grid item xs={5} alignItems="center" justify="center">
                                                    <Box alignItems="center" display="flex" justifyContent="center" flexDirection="column">

                                                        <Chip
                                                            label="Game Outfitting"
                                                            clickable
                                                            component="a"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                pushToGameOutfit(item.gameId, item.homeTeamId, item.homeTeam);
                                                            }}
                                                            onDelete={() => { return }}
                                                            deleteIcon={<></>}
                                                            variant="outlined"
                                                            color="primary"
                                                            className={`${classes.chip} btnChip`}
                                                        />

                                                        <TeamShopIcon teamName={item.homeTeam} />
                                                    </Box>
                                                </Grid>

                                            </Grid>
                                        </CardContent>
                                        <CardHeader
                                            avatar={
                                                <React.Fragment>
                                                    <Box component="div" justifyContent="space-between" alignItems="center" display="flex" flexGrow="1" flexDirection="column">
                                                        <Box component="div" alignItems="center" display="flex">
                                                            <Box component="div" display="flex" alignItems="flex-start" className={classes.matchCenter}>
                                                                <LocationOnIcon />
                                                                <Typography variant="h6"> {item.arena}</Typography>
                                                            </Box>
                                                            <Typography variant="caption" className={`${classes.matchLocation} matchLocation`}>{item.city}</Typography>
                                                        </Box>
                                                        <Box component="div" display="flex">
                                                            <Typography variant="caption" component="p" className={`${classes.MatchTime} MatchTime`}>{item.gameTime.slice(0, 6)} <span>{item.gameTime.slice(6, 13)}</span></Typography>
                                                            {/* <Typography variant="caption" component="p" noWrap className={`${classes.MatchSeason} MatchSeason`}>{item.season}</Typography> */}
                                                        </Box>
                                                    </Box>
                                                </React.Fragment>
                                            }
                                        />
                                    </Link>
                                </Card>
                            </Grid>
                        );
                    } else {
                        //if (item.sponserAd !== undefined && AppConstants.DISPLAY_SPONSORS) {
                        //    return (
                        //        <SponsorAd from="Card" sponsorsAdSource={item.sponserAd.adSource} sponsorsAdLink={item.sponserAd.adLink} />
                        //    );
                        //}
                    }
                })}
            </Grid>

            <UniformMatchup
                isDialogOpen={isDialogOpen}
                gameId={gameId}
                homeTeamNameSEO={homeTeamNameSEO}
                awayTeamNameSEO={awayTeamNameSEO}
                homeTeamName={homeTeam}
                awayTeamName={awayTeam}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                homeTeamUniformImg={homeTeamUniformImg}
                awayTeamUniformImg={awayTeamUniformImg}
                homeTeamEdition={homeTeamEdition}
                awayTeamEdition={awayTeamEdition}
                uniformType={"NBAUniform"}
                handleDialogClose={(data, index) => {
                    handleDialogClose(false);
                }}
            />

        </>
    );
}
export default withRouter(CardContainer);