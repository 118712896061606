import React, { useState, useEffect, useContext } from "react";
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppConstants from '../../common/AppConstants';
import Container from '@material-ui/core/Container';
import CircularLoader from '../../sharedComponents/emptyStateUIContainers/CircularLoader';

const useStyles = makeStyles(theme => ({
    
    SignupComponent: {
        maxWidth: '600px !important',
        background: '#000 !important',
        minWidth: 'unset !important',
        boxShadow: "0px 0px 5px rgb(255 255 255 / 30%)  !important",
        border: '2px solid #b5b5b5',
        '& .uwStyleDialogCloseButton': {
            color: '#888888 !important',
            background: 'transparent',
            '&:hover, &:focus': {
                background: 'transparent',
            },
        },
        '& .uwTextControlStyle': {
            '&:disabled': {
                color: '#888888 !important',
            },
        },
        '& .header': {
            color: '#888888 !important',          
        },
        '& .uwCheckBoxContainerStyle': {
            color: '#888888 !important',          
        },
        '& .uwCheckMarkStyle': {
            background: 'transparent !important', 
            border: '1px solid #b5b5b5',         
        } 
    },

    signupBtn: {
        color: "#888888",
        backgroundColor: "#fff !important",
        cursor: "pointer",
        padding: theme.spacing(1, 2),
        // margin: theme.spacing(.5),
        lineHeight: "1.75",
        borderRadius: "4px",
        letterSpacing: "0.02857em",
        border: "0",
        width: "100%",
        textTransform: 'uppercase',
    },
    
    SignInContent:{
        color: "#000",
        backgroundColor: "#fff !important",
    },
    
    SignUpSubContent: {
        margin: theme.spacing(-1),
        '& > div': {
            padding: theme.spacing(1),
            color: '#888888 !important',
            '& .uvLoginLink': {
                color: '#888888 !important',
                '&:hover, &:focus': {
                    color: '#888888 !important',
                },
            },
            '& .subheader': {
                color: '#fff !important',
                '&:hover, &:focus': {
                    color: '#888888 !important',
                },
            },
            '& .uwsignupAccount': {
                color: '#888888 !important',
                '&:hover, &:focus': {
                    color: '#888888 !important',
                },
            }        
        }
    },

    signup: {
        boxShadow: "0px 0px 5px rgb(255 255 255 / 30%)  !important",
        border: '2px solid #b5b5b5',
        '& .header': {
            color: '#888888 !important',          
        },
        '& .uwCheckBoxContainerStyle': {
            color: '#888888 !important',   
            '& a, & a:hover, & a:focus': {
                color: '#888888 !important',   
            },       
        },
        '& .uwCheckMarkStyle': {
            background: 'transparent !important', 
            border: '1px solid #b5b5b5',         
        },       
        '& .uwStyleDialogCloseButton': {
            color: '#888888 !important',
            background: 'transparent',
            '&:hover, &:focus': {
                background: 'transparent',
            },
        },
        '& .uwTextControlStyle': {
            '&:disabled': {
                color: '#888888 !important',
            },
        }
    },

    signupBox: {
        //height: '100%',
        height: 'auto',
        '& > div': {
            background: 'rgba(0, 0, 0, .6)',
            height: '100%',
            padding: theme.spacing(3),
            '& .header': {
                color: 'rgba(255, 255, 255, .54)',
            },
        },
    },

    styleDropDownCls: {
        background: 'rgba(0, 0, 0, .6)',
        fontSize: 10,
        color: '#8f8f8f',
        '&:hover, &:focus': {
            background: 'black',
        },
    }

}));


const Signup = (props) => {
    const classes = useStyles();
    const [Loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        scriptLoaded();       
    }, []);

    const scriptLoaded = () => {
        setTimeout(function(){ 
            const mylib = window.UVWidget;
            const myWidget = mylib.SignUp.new({
                modal: AppConstants.MODEL,
                apiKey: AppConstants.WIDGET_API_KEY,
                appId: AppConstants.WIDGET_API_ID,
                env: AppConstants.WIDGET_ENV,
                selector: 'divSignUp',
                redirectUrl: AppConstants.QA_URL,
                cssStyle: {
                    SignUp: {
                        styleComponent: classes.SignupComponent,
                        styleDialogCloseButton: 'uvLoginCloseButton',
                        styleButton: classes.signupBtn,
                        styleSubContent: classes.SignUpSubContent,
                        styleDropDown: classes.styleDropDownCls
                    },
                    LogIn: {
                        styleComponent: classes.SignupComponent,
                        styleImage: 'uvLoginImage',
                        styleDialogCloseButton: 'uvLoginCloseButton',
                        styleLink: 'uvLoginLink',
                        styleButton: classes.signupBtn,
                        styleSubContent: classes.SignUpSubContent
                    },
                    ForgotPassword: {
                        styleComponent: classes.SignupComponent,
                        styleDialogCloseButton: 'uvLoginCloseButton',
                        styleButton: classes.signupBtn
                    }
                }
            });
            myWidget.Show();
            setLoading(false);
            myWidget.On("onSignUpSuccess", function (response) {
               // console.log("onSignUpSuccess", response)
                if (response !== undefined && response.status !== undefined) {
                    // setUserLoggedin(true);
                    // setUserFirstName(response.data.userDetails.firstName);
                    // setUserLastName(response.data.userDetails.lastName);
                    localStorage.setItem("UserToken", response.data.token);
                    localStorage.setItem("UserResetToken", response.data.resetToken);
                    localStorage.setItem("UserDetail", JSON.stringify(response.data.userDetails));
                    props.history.push("/");
                }
            });
        }, 3000); 
    };
  
    return (
        <>
        {Loading && <CircularLoader />}
        <Container maxWidth="sm" className={classes.signupBox}>
            <div id="divSignUp" className={classes.signup}></div>
        </Container>
        </>
    )
}
export default withRouter(Signup);