import Moment from 'moment';
import CultureInfo from './CultureInfo';
import APIHelper from './ApiHelper';
import APIURLConstants from "./ApiURLConstants";
import AppDataContext from './AppContext';
import AppConstants from './AppConstants'

let BASE_URL = "https://localhost:60000/";
let QA_URL = "https://njvqwebext1.nba-hq.com:44095";
let PROD_URL = "https://lockervision.nba.com";
let urlMap = {
    "Atlanta Hawks": "https://store.nba.com/atlanta-hawks/t-36038306+z-831088-1797977209",
    "Boston Celtics": "https://store.nba.com/boston-celtics/t-25690564+z-9125894-3332489531",
    "Brooklyn Nets": "https://store.nba.com/brooklyn-nets/t-81810521+z-810547-1196300411",
    "Charlotte Hornets": "https://store.nba.com/charlotte-hornets/t-69479566+z-835073-448928118",
    "Chicago Bulls": "https://store.nba.com/chicago-bulls/t-70255189+z-849802-2710025869",
    "Cleveland Cavaliers": "https://store.nba.com/cleveland-cavaliers/t-25925179+z-850286-3863856449",
    "Dallas Mavericks": "https://store.nba.com/dallas-mavericks/t-25921725+z-9808307-1099833793",
    "Denver Nuggets": "https://store.nba.com/denver-nuggets/t-36709537+z-9818217-2061726982",
    "Detroit Pistons": "https://store.nba.com/detroit-pistons/t-25585150+z-9946885-597818103",
    "Golden State Warriors": "https://store.nba.com/golden-state-warriors/t-25697330+z-9481965-3978686848",
    "Houston Rockets": "https://store.nba.com/houston-rockets/t-25701720+z-9900621-3740132585",
    "Indiana Pacers": "https://store.nba.com/indiana-pacers/t-14365165+z-804513-2032639652",
    "LA Clippers": "https://store.nba.com/la-clippers/t-70587401+z-887467-3046626999",
    "Los Angeles Lakers": "https://store.nba.com/los-angeles-lakers/t-25031846+z-9030758-2040509788",
    "Memphis Grizzlies": "https://store.nba.com/memphis-grizzlies/t-36141825+z-9395229-4160547856",
    "Miami Heat": "https://store.nba.com/miami-heat/t-14817459+z-9038978-4289575825",
    "Milwaukee Bucks": "https://store.nba.com/milwaukee-bucks/t-81589638+z-896889-3081800506",
    "Minnesota Timberwolves": "https://store.nba.com/minnesota-timberwolves/t-36817417+z-9628361-630200030",
    "New Orleans Pelicans": "https://store.nba.com/new-orleans-pelicans/t-14928541+z-9278305-1529874652",
    "New York Knicks": "https://store.nba.com/new-york-knicks/t-25360764+z-9272060-390849173",
    "Oklahoma City Thunder": "https://store.nba.com/oklahoma-city-thunder/t-25920710+z-880821-1731662351",
    "Orlando Magic": "https://store.nba.com/orlando-magic/t-92582033+z-811953-2826302465",
    "Philadelphia 76ers": "https://store.nba.com/philadelphia-76ers/t-47149789+z-9326979-3026664379",
    "Phoenix Suns": "https://store.nba.com/phoenix-suns/t-36365357+z-9435785-1133976337",
    "Portland Trail Blazers": "https://store.nba.com/portland-trail-blazers/t-14585303+z-9802245-1123535554",
    "Sacramento Kings": "https://store.nba.com/sacramento-kings/t-25706493+z-9748930-696651166",
    "San Antonio Spurs": "https://store.nba.com/san-antonio-spurs/t-25479738+z-9599295-1603295541",
    "Toronto Raptors": "https://store.nba.com/toronto-raptors/t-58147539+z-874340-2792724186",
    "Utah Jazz": "https://store.nba.com/utah-jazz/t-36582018+z-9026731-4239061728",
    "Washington Wizards": "https://store.nba.com/washington-wizards/t-25690831+z-9858548-3921087139",
    "All-Star": "https://store.nba.com/all-star-gear/c-4359?s=bm-nbacom-banners-ALLSTAR23-STOREUNIT",
};

const DATE_FORMAT_MM_DD_YYYY_FORWARD_SLASH = 'MM/DD/YYYY';
const DATE_FORMAT_DD_MM_YYYY_FORWARD_SLASH = 'DD/MM/YYYY';
const DATE_FORMAT_DASH_MMDDYYYY = 'MM-DD-YYYY';
const DATE_FORMAT_DASH_YYYYMMDD = 'YYYY-MM-DD';
const DATE_FORMAT_MM_DD_YYYY_FORWARD_SLASH_WITHOUT_TIME = 'MM/DD/YYYY';

export default class Helper {

    static getBaseURL() {
        return BASE_URL;
    }
    static QA_URL() {
        return QA_URL;
    }
    static PROD_URL() {
        return PROD_URL;
    }

    static FormatDate(value) {
        return value ? Moment(value).format(CultureInfo.GetCultureInfo().DateFormat) : '';
    }

    static FormatTime(value) {
        return value ? Moment(value).format(CultureInfo.GetCultureInfo().TimeFormat) : '';
    }

    static FormatDayWithDate(value) {
        return value ? Moment(value).format(CultureInfo.GetCultureInfo().DayWithDateFormat) : '';
    }
    static GetPresentDate() {
        return Moment().format(CultureInfo.GetCultureInfo().DayWithDateFormat);
    }

    static FormatDateToLocalTimeZone(value) {
        return value ? Moment(value).local().format(CultureInfo.GetCultureInfo().DateFormat) : '';
    }

    static FormatDateTime(value) {
        return value ? Moment(value).format(CultureInfo.GetCultureInfo().DateTimeFormat) : '';
    }

    static FormatDateTime24(value) {
        return value ? Moment(value).format(CultureInfo.GetCultureInfo().DateTimeFormat24) : '';
    }

    static FormatDateTimeToLocalTimeZone(value) {
        return value ? Moment(value).local().format(CultureInfo.GetCultureInfo().DateTimeFormat24) : '';
    }

    static FormatDateToMonthDateYear(value) {
        return value ? Moment(value).local().format(CultureInfo.GetCultureInfo().MonthDateYear) : 'TBD';
    }

    static FormatDateToMonthDateYearTime12(value) {
        return value ? Moment(value).local().format(CultureInfo.GetCultureInfo().MonthDateYearTime12) : 'TBD';
    }

    static FormatDateMMDDYYYY(value) {
        return value ? Moment.utc(value).format(DATE_FORMAT_MM_DD_YYYY_FORWARD_SLASH) : '';
    }

    static FormatDateDDMMYYYY(value) {
        return value ? Moment.utc(value).format(DATE_FORMAT_DD_MM_YYYY_FORWARD_SLASH) : '';
    }

    static FormatDateDashMMDDYYYY(value) {
        return value ? Moment.utc(value).format(DATE_FORMAT_DASH_MMDDYYYY) : '';
    }

    static FormatDateDashYYYYMMDD(value) {
        return value ? Moment.utc(value).format(DATE_FORMAT_DASH_YYYYMMDD) : '';
    }

    static FormatDateMMDDYYYYWithoutTime(value) {
        return value ? Moment.utc(value).format(DATE_FORMAT_MM_DD_YYYY_FORWARD_SLASH_WITHOUT_TIME) : '';
    }

    static FormatDateDayMonthDateYear(value) {
        let day = value.split(',')[0];
        let formattedDate = value ? Moment.utc(value).format('LL') : '';
        return `${day}, ${formattedDate}`
    }

    static IsValidDate(dateToValidate) {
        let date = Moment(dateToValidate);
        let result = date.isValid();
        return result;
    }

    //... Returns a random Id string. Id length is based on the supplied length.
    static GetRandomId(length = 5) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    static IfDateIsNULL(inputDate) {
        var minDate = Moment.utc("01/01/0001"); // minimum value as per UTC

        var receiveDate = Moment(this.FormatDate(inputDate)); // replace with variable
        if (Moment.utc(receiveDate).isAfter(minDate)) {
            return false;
        }
        else {
            return true;
        }
    }

    static GetNameInitials(name) {
        if (!name) {
            return "--";
        }

        var iChars = "";
        name.replace(/[a-z]{2,}/gi, function (c) { iChars += c.charAt(0) });
        return iChars.toUpperCase();
    }

    static Validate(value, params) {
        let obj = { isValid: true, validationMessage: '' };

        if (params.Required && (value === null || value === '' || value.length === 0)) {
            obj.isValid = false;
            obj.validationMessage = 'Please enter value'
        }
        return obj;
    }

    static GetEmptyUser() {
        return {
            firstName: "",
            lastName: "",
            userName: "",
            userLevel: "",
            teamId: ""
        }
    }

    static GetRandomBackground() {
        const bgColor = ['#583d72', '#9f5f80', '#ffba93', '#ff8e71', '#c6ebc9', '#70af85', '#f0e2d0', '#aa8976', '#cd5d7d', '#f6ecf0', '#a7c5eb', '#949cdf', '#75cfb8', '#bbdfc8', '#f0e5d8', '#ffc478', '#1687a7', '#276678', '#726a95', '#719fb0', '#a0c1b8'];
        return bgColor[Math.floor(Math.random() * bgColor.length)];
    }

    static GetStringToColour(str) {
        if (!str) return '#bdbdbd';
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var j = 0; j < 3; j++) {
            var value = (hash >> (j * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    static LogOutApplication(signoutURL) {

        //... Signout from the local application first.
        APIHelper.get(APIURLConstants.SIGNOUT).then(success => {
            if (success) {
                //... Redirect to the ADFS signout page for remote signout.
                window.location.href = `${signoutURL}`;
            }
        }).catch(err => {
            //... Redirect to the ADFS signout page for remote signout.
            window.location.href = `${signoutURL}`;
        });
    }

    static OpenShopLink(team) {
        window.open(urlMap[team]);
    }
    static getShopUrl(team) {
        return urlMap[team];
    }
    static checkForComingSoon(urlString) {
        if (urlString !== undefined && urlString.toLowerCase().includes('coming'))
            return true;
        else return false;
    }
    static InserTeamNameInPlace(str, teamName, editionName) {
        if (str) {
            if (teamName)
                str = str.replace(/TeamName/g, teamName);
            else str = str.replace(/TeamName/g, '');
            if (editionName)
                str = str.replace(/EditionName/g, editionName);
            else str = str.replace(/EditionName/g, '');

            return str;
        }
    }

    static getAssetType(url, DefaultKeyName, keyName) {
        if (url) {
            let stringFinal = url.replace(DefaultKeyName, keyName)
            // console.log(stringFinal)
        }
        return url
    }
    static getElemValue(arr, keyName) {
        if (arr.length > 0) {
            let val = arr.find((obj) => {
                if (obj.key === keyName) {
                    return obj.value;
                };
            });
            return val;
        }
    }

    static trimVideoFromStory(story) {
        let storyEndIndex = story.search(/video/i);
        let storyData, videoLink;
        if (storyEndIndex !== -1) {
            storyData = story.substring(0, storyEndIndex);
            videoLink = story.substring(storyEndIndex + 6, story.length).replace('</p>', '');
            return { storyData, videoLink };

        }
        else {
            storyData = story;
            videoLink = null;
            return { storyData, videoLink };
        }
    }

    static GetRefHeader() {
        try {
            let header = '';
            let refHeader = localStorage.getItem("refHeader");
            if (refHeader === null || refHeader === undefined) {
                //Generate
                header = this._createRefHeader();
            }
            else {
                //Validate the date and generate 
                let enRefHeader = window.atob(unescape(decodeURIComponent(refHeader)));
                let headerData = enRefHeader.split('_');
                let headerDt = headerData[1];
                let currDate = this.FormatDateDDMMYYYY(new Date());
                header = headerDt !== currDate ? this._createRefHeader() : refHeader;
            }

            return header;
        }
        catch (e) {
            throw (e);
        }
    }

    static _createRefHeader() {
        let rheader = AppConstants.REF_HEADER;
        let dateAndTime = this.FormatDateDDMMYYYY(new Date());
        let rHeaderDt = `${rheader}_${dateAndTime}`;
        let enRefHeader = window.btoa(unescape(encodeURIComponent(rHeaderDt)));
        localStorage.setItem("refHeader", enRefHeader);
        return enRefHeader;
    }

    static IsValidURL = (url) => {
        try {
            new URL(url);
        } catch (e) {
            console.error(e);
            return false;
        }
        return true;
    };

    static ValidateAndFetchURL = (url) => {
        try {
            new URL(url);
        } catch (e) {
            console.error(e);
            return '';
        }
        return url;
    };

    static GetPageAds = (pageName) => {
        let localSponsorAds = JSON.parse(localStorage.getItem('SponsorAds'));
        return localSponsorAds ? localSponsorAds.find(ad => ad.page === pageName) : null;
    }

    static GetEditionName = (name) => {
        if (name.toLowerCase().includes("edition")) {
            let wordEditionCount = 0;
            let words = name.split(" ");
            words.map((item) => {
                if (item.toLowerCase() === "edition") {
                    wordEditionCount++;
                }
            })

            if (wordEditionCount > 1) {
                let lastWord = words[words.length - 1];
                if (lastWord.toLowerCase() === 'edition') {
                    words.pop(); //remove the last word 'Edition'
                }

                return words.join(' ');
            }
        }
        else {
            return name;
        }
    }

}