import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Slider from "react-slick";
import ReactPlayer from 'react-player';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from '@material-ui/core/styles';
import { CardActionArea, CardActions, Box, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardMedia from '@material-ui/core/CardMedia';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Chip from '@material-ui/core/Chip';
import Helper from '../../common/Helper';
import SocialMediaContainer from '../../sharedComponents/SocialMedia/SocialMediaContainer';
import { GetRisingStarUniformDetails } from "../../services/uniform.service";
import LoadingAppUI from '../../sharedComponents/emptyStateUIContainers/LoadingAppUI';
import CircularLoader from '../../sharedComponents/emptyStateUIContainers/CircularLoader';
import SponsorAd from '../../sharedComponents/SponsorContainer/SponsorAd';
import PreviewShopIcon from '../../sharedComponents/TeamShopIcon/PreviewShopIcon';
import OutfitIcon from '../../sharedComponents/CustomIcons/OutfitIcon';
import AppDataContext from '../../common/AppContext';
import AppConstants from '../../common/AppConstants';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import HelmetMetaData from '../../sharedComponents/SocialMedia/HelmetMetaDeta';
import Hidden from '@material-ui/core/Hidden';
import ImageSlider from '../../sharedComponents/ImageSlider/ImageSlider';
//import RisingStarLogo from '../../images/rising_star.jpg';
import RisingStarLogo from '../../images/rising_stars.png';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(5),
    },

    mainContainer: {
        background: 'rgba(0, 0, 0, .65) !important',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(0),
    },

    marginTop: {
        marginTop: theme.spacing(3),
    },

    cardRoot: {
        background: 'transparent !important',
        border: 'none',
        boxShadow: 'none',
        position: 'relative',
        paddingTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        borderRadius: `${theme.spacing(.5)}px !important`,
    },

    teamIcons: {
        maxWidth: theme.spacing(15),
    },

    outfitText: {
        color: '#b5b5b5',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
    },

    customChip: {
        position: 'absolute',
        zIndex: '1',
        top: '0',
        width: '100%',
        '& .MuiChip-root': {
            height: 'auto',
            padding: '2px 0',
            fontSize: '.78rem',
            fontWeight: '500',
            border: '1px solid #b5b5b5',
            '& .MuiChip-label': {
                paddingLeft: theme.spacing(.75),
                paddingRight: theme.spacing(.75),
            },
        },
    },

    title: {
        flexGrow: 1,
        '& .MuiChip-clickable': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        '& > div:nth-child(2)': {
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
            },
        },
        '& h5': {
            fontFamily: '"Action NBA  Web"',
            color: '#b5b5b5',
        },
        '& p': {
            color: '#b5b5b5',
        },
    },

    teamName: {
        fontFamily: '"Action NBA  Web"',
        fontWeight: '500',
        color: '#fff',
    },

    backchip: {
        width: '50px',
        fontSize: '.63rem',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.5, .5, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        '& .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1, 0, 1)
            },
        },
    },

    headerSection: {
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            height: '1px',
            background: '#888888 !important',
            width: '40%',
            left: '0',
            bottom: '0',
        },
    },

    topHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#888888 !important',
        padding: theme.spacing(1.5, 0),
        '& h6': {
            position: 'relative',
        },
        '& .MuiButton-text': {
            color: '#888888',
            fontSize: '.75rem',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },

    cardAction: {
        position: 'absolute',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'space-between',
        left: '0',
        right: '0',
        top: '2px',
        '& a, & a:hover, & a:focus': {
            color: '#e5dfcd',
            marginRight: theme.spacing(2),
        },
        '& a:last-child': {
            marginRight: theme.spacing(0),
        },
    },

    cardParentMedia: {
        display: 'flex',
        background: '#000000 !important',
        border: '1px solid #888888 !important',
        borderRadius: theme.spacing(.5),
        '& img': {
            height: '100%',
            width: '100%'
        },
    },

    lgParentMedia: {
        display: 'flex',
        background: '#000000 !important',
        border: '1px solid #888888 !important',
        borderRadius: theme.spacing(.5),
        maxHeight: '450px',
        '& img': {
            height: '100%',
            width: '100%',
        },
    },

    textColor: {
        color: '#888888 !important',
    },

    cardMedia: {
        display: 'flex',
        background: '#000000 !important',
        border: '1px solid #888888',
        //cursor: 'pointer',
        borderRadius: theme.spacing(.5),
        height: '100%',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain',
        },
    },

    chip: {
        fontSize: '.7rem',
        letterSpacing: '0.0500em',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.5, .5, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        '& .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1, 0, 1)
            },
        },
    },

    DialogRoot: {
        '& .MuiDialog-paper': {
            overflow: 'visible',
            minHeight: "80vh",
            background: '#000 !important',
            boxShadow: '0px 0px 5px rgb(255 255 255 / 30%)',
            '& .MuiDialogContent-root': {
                overflowX: 'hidden',
            },
            '& .MuiDialogTitle-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: 'rgba(255, 255, 255, .5)',
                padding: theme.spacing(0, 1.5),
                '& .MuiIconButton-root': {
                    color: 'rgba(255, 255, 255, .5)',
                },
            },
            [theme.breakpoints.only('xs')]: {
                maxWidth: '302px',
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '444px',
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '444px',
            },
            [theme.breakpoints.up('xl')]: {
                maxWidth: '960px',
            },
        },

    },
    imgWidth: {
        width: '100%',
        cursor: "pointer",
        boxShadow: '0px 0px 5px rgb(255 255 255 / 30%)',
    },
    chip: {
        fontSize: '.63rem',
        letterSpacing: '0.0500em',
        color: '#8f8f8f !important',
        borderColor: '#8f8f8f !important',
        margin: theme.spacing(.5, .5, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        '& .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1, 0, 1)
            },
        },
    },
    DialogHeader: {
        '& br': {
            display: 'none',
            [theme.breakpoints.down('lg')]: {
                display: 'block',
            },
        },
    },
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25%',
        cursor: 'pointer',
    },
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    playThumb: {
        textAlign: 'center',
        height: '100px',
        width: '160px',
        background: 'black',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: theme.spacing(.5),
    },
    playThumbWrap: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    customChipBottom: {
        position: 'absolute',
        zIndex: '1',
        bottom: '0',
        width: '100%',
        '& .MuiChip-root': {
            height: 'auto',
            padding: '2px 0',
            fontSize: '.78rem',
            fontWeight: '500',
            border: '1px solid #b5b5b5',
            '& .MuiChip-label': {
                paddingLeft: theme.spacing(.75),
                paddingRight: theme.spacing(.75),
            },
        },
    },

    paddingBottom: {
        paddingBottom: theme.spacing(1.5),
    },

    boxBanner: {
        background: "#c8c8c8",
        minHeight: theme.spacing(10),
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        opacity: 0.5
    },

}));

function RisingStarOutfitPreview(props) {
    const classes = useStyles();
    const history = useHistory();
    let temp = decodeURI(window.location.pathname);
    let gameid = (new URLSearchParams(window.location.search)).get("gameid")
    let QueryData = temp.split("/");
    const { AssetFactory, sponsorAds, sponsorAdsFetched, GetSponsorAds } = useContext(AppDataContext);

    const [outfitPreviewAdDetails, setOutfitPreviewAdDetails] = useState(!sponsorAds || sponsorAds.length === 0 ?
        Helper.GetPageAds("outfit-preview") : sponsorAds.find(ad => ad.page === "outfit-preview"));

    const [gameIdQueryParam, setGameIdFromQueryParam] = useState((gameid !== undefined) ? gameid : "");
    const [teamNameQueryParam, setTeamNameFromQueryParam] = useState((QueryData[2] !== undefined) ? QueryData[2].split('-').join(' ') : "");
    const teamNameSEO = (QueryData[2] !== undefined) ? QueryData[2] : ""

    const [loading, setLoading] = useState(true);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [GameId, setGameId] = useState('');
    const [storyData, setStoryData] = useState([]);


    const [gameDate, setGameDate] = useState('');
    const [gameTime, setGameTime] = useState('');
    const [AwayTeamId, setAwayTeamId] = useState('');
    const [AwayTeamName, setAwayTeamName] = useState('');

    const [TeamId, setTeamId] = useState('');
    const [TeamName, setTeamName] = useState('');
    const [TeamType, setTeamType] = useState('Home Team');
    const [TeamLogoURL, setTeamLogoURL] = useState('');
    const [BGColor, setBGColor] = useState('');
    const [TextColor, setTextColor] = useState('');
    const [ShopImgUrl, setShopImgUrl] = useState('');
    const [CourtName, setCourtName] = useState('');
    const [CourtImg, setCourtImg] = useState('');
    const [EditionName, setEditionName] = useState('');
    const [EditionImg, setEditionImg] = useState('');
    const [EditionCardImg, setEditionCardImg] = useState('');
    const [EditionStory, setEditionStory] = useState('');
    const [title, setTitle] = useState('');

    const [JacketPantName, setJacketPantName] = useState('');
    const [JacketPantImg, setJacketPantImg] = useState('');
    const [LongSleeveName, setLongSleeveName] = useState('');
    const [LongSleeveImg, setLongSleeveImg] = useState('');
    const [ShortSleeveName, setShortSleeveName] = useState('');
    const [ShortSleeveImg, setShortSleeveImg] = useState('');
    const [TrophyName, setTrophyName] = useState('');
    const [TrophyImg, setTrophyImg] = useState('');
    const [BasketballName, setBasketballName] = useState('');
    const [BasketballImg, setBasketballImg] = useState('');
    const [sponsorsData, setSponsorsData] = useState([]);
    const [LargeImages, setLargeImages] = useState([]);

    const [GameIDForShare, setGameIDForShare] = useState(null);
    const [TeamIDForShare, setTeamIDForShare] = useState(null);
    const [keywords, setKeywords] = useState('');
    const [videoLink, setVideoLink] = useState(null);
    const [play, setPlay] = useState(true);
    const [risingStarData, setRisingStarData] = useState('');
    const description = process.env.REACT_APP_SEO_DESCRIPTION_FOR_RISING_STAR_OUTFIT_PREVIEW_PAGE;
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);

        GetRisingStarUniformDetails().then(data => {
            if (data) {
                setRisingStarData(data);
                setGameDate(data.gameDate);
                setGameTime(data.gameTime);
                if (!sponsorAdsFetched) {
                    GetSponsorAds();
                }
                else {
                    setOutfitPreviewAdDetails(Helper.GetPageAds("outfit-preview"));
                }
                setLoading(false);
            }
        });


    }, [sponsorAdsFetched])

    useEffect(() => {
        setKeywords(Helper.InserTeamNameInPlace(process.env.REACT_APP_SEO_KEYWORDS_FOR_RISING_STAR_OUTFIT_PREVIEW_PAGE, "Rising Stars"));
    }, [])

    const backToDashboard = () => {
        history.goBack();
    }

    // helper function to toggle to the opposite team uniform
    const oppositeTeam = () => {
        if (storyData !== undefined) {

            setLoading(true);

            let team = (TeamType === "Home Team") ? "homeTeamUniform" : "awayTeamUniform";

            let teamName = (storyData[team].teamName !== undefined) ? storyData[team].teamName : "";
            let awayTeamName = (storyData[(TeamType === "Home Team") ? "awayTeamUniform" : "homeTeamUniform"].teamName !== undefined) ? storyData[(TeamType === "Home Team") ? "awayTeamUniform" : "homeTeamUniform"].teamName : "";
            let teamId = (storyData[team].teamId !== undefined) ? storyData[team].teamId : "";
            let gameId = (storyData.gameId !== undefined) ? storyData.gameId : "";
            if (teamName !== undefined && storyData[team].edition_Name !== undefined)
                setTitle(`NBA LockerVision - ${teamName.toLowerCase().split(' ').join('-')} - ${storyData[team].edition_Name.toLowerCase().split(' ').join('-')} - Game Outfit`);
            setGameId(gameId);
            setTeamId(teamId);
            setTeamName(teamName);
            setAwayTeamName(awayTeamName);
            // setEditionStory((storyData[team].edition_Story !== undefined) ? storyData[team].edition_Story : "");
            setEditionStory((storyData[team].edition_Story !== undefined) ? Helper.trimVideoFromStory(storyData[team].edition_Story).storyData : "");
            setVideoLink((storyData[team].edition_Story !== undefined) ? Helper.trimVideoFromStory(storyData[team].edition_Story).videoLink : null);
            setTeamLogoURL((storyData[team].teamLogoURL !== undefined) ? storyData[team].teamLogoURL : "");
            setBGColor((storyData[team].bG_Color !== undefined) ? storyData[team].bG_Color : "");
            setTextColor((storyData[team].text_Color !== undefined) ? storyData[team].text_Color : "");
            setShopImgUrl((storyData[team].shop_Img_Url !== undefined) ? storyData[team].shop_Img_Url : "");
            setCourtName((storyData[team].court_Name !== undefined) ? storyData[team].court_Name : "");
            setCourtImg((storyData[team].court_Img_Url !== undefined) ? Helper.getAssetType(storyData[team].court_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY)) : "");
            setEditionName((storyData[team].edition_Name !== undefined) ? storyData[team].edition_Name : "");
            setEditionImg((storyData[team].edition_Img_Url !== undefined) ? storyData[team].edition_Img_Url : "");
            setEditionCardImg((storyData[team].edition_Img_Url !== undefined) ? Helper.getAssetType(storyData[team].edition_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY)) : "");
            setJacketPantName((storyData[team].jacketPant_Name !== undefined) ? storyData[team].jacketPant_Name : "");
            setJacketPantImg((storyData[team].jacketPant_Img_Url) !== undefined ? Helper.getAssetType(storyData[team].jacketPant_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY)) : "");
            setLongSleeveName((storyData[team].lS_Name !== undefined) ? storyData[team].lS_Name : "");
            setLongSleeveImg((storyData[team].lS_Img_Url !== undefined) ? Helper.getAssetType(storyData[team].lS_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY)) : "");
            setShortSleeveName((storyData[team].sS_Name !== undefined) ? storyData[team].sS_Name : "");
            setShortSleeveImg((storyData[team].sS_Img_Url !== undefined) ? Helper.getAssetType(storyData[team].sS_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY)) : "");
            setTrophyName((storyData[team].trophy_Name !== undefined) ? storyData[team].trophy_Name : "");
            setTrophyImg((storyData[team].trophy_Img_Url !== undefined) ? Helper.getAssetType(storyData[team].trophy_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.HAT_KEY)) : "");
            setBasketballName((storyData[team].basketball_Name !== undefined) ? storyData[team].basketball_Name : "");
            setBasketballImg((storyData[team].basketball_Img_Url !== undefined) ? Helper.getAssetType(storyData[team].basketball_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.BASKETBALL_KEY)) : "");

            setTeamType((TeamType === "Home Team") ? "Away Team" : "Home Team");
            setLoading(false);

            //If customer wants to be redirect on SEO freindly URL
            // let teams = allTeams.filter(function (el) {
            //     return el.teamName == teamName;
            // });
            // let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";
            // props.history.push({
            //     pathname: '/storyguide/' + gameId + "/" + teamSEOName,
            //     state: { outfitGameId: gameId, outfitTeamId: teamId }
            // });

        }
    }
    // function to set current preview image for Large Popup Slider
    const LargeImageSlider = (tab) => {
        let largeImagesArr = [];
        if (CourtImg !== "") {
            if (tab === "edition")
                largeImagesArr = [EditionCardImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, BasketballImg, TrophyImg, CourtImg];
            else if (tab === "jacket")
                largeImagesArr = [JacketPantImg, LongSleeveImg, ShortSleeveImg, BasketballImg, TrophyImg, CourtImg, EditionImg];
            else if (tab === "longsleeve")
                largeImagesArr = [LongSleeveImg, ShortSleeveImg, BasketballImg, TrophyImg, CourtImg, JacketPantImg, EditionImg];
            else if (tab === "shortsleeve")
                largeImagesArr = [ShortSleeveImg, BasketballImg, TrophyImg, CourtImg, JacketPantImg, LongSleeveImg, EditionImg];
            else if (tab === "towel")
                largeImagesArr = [BasketballImg, TrophyImg, CourtImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, EditionImg];
            else if (tab === "hat")
                largeImagesArr = [TrophyImg, CourtImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, BasketballImg, EditionImg];
            else if (tab === "court")
                largeImagesArr = [CourtImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, BasketballImg, TrophyImg, EditionImg];
            else
                largeImagesArr = [EditionCardImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, BasketballImg, TrophyImg, CourtImg];

        } else {
            if (tab === "edition")
                largeImagesArr = [EditionCardImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, BasketballImg, TrophyImg];
            else if (tab === "jacket")
                largeImagesArr = [JacketPantImg, LongSleeveImg, ShortSleeveImg, BasketballImg, TrophyImg, EditionImg];
            else if (tab === "longsleeve")
                largeImagesArr = [LongSleeveImg, ShortSleeveImg, BasketballImg, TrophyImg, JacketPantImg, EditionImg];
            else if (tab === "shortsleeve")
                largeImagesArr = [ShortSleeveImg, BasketballImg, TrophyImg, JacketPantImg, LongSleeveImg, EditionImg];
            else if (tab === "towel")
                largeImagesArr = [BasketballImg, TrophyImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, EditionImg];
            else if (tab === "hat")
                largeImagesArr = [TrophyImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, BasketballImg, EditionImg];
            else if (tab === "court")
                largeImagesArr = [JacketPantImg, LongSleeveImg, ShortSleeveImg, BasketballImg, TrophyImg, EditionImg];
            else
                largeImagesArr = [EditionCardImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, BasketballImg, TrophyImg];
        }

        setLargeImages(largeImagesArr);
        setDialogOpen(true);
    }

    const handleDialogClose = (tab) => {
        setDialogOpen(false);
    }

    return (
        <Container maxWidth="false">
            <HelmetMetaData
                title={title}
                quote={"Every Locker, Every Player, Every Game."}
                url={window.location.href}
                hashtag={"#nba"}
                description={description}
                keywords={keywords}
            ></HelmetMetaData>
            {loading && <CircularLoader />}

            {!loading && <>
                <Grid container spacing={2} alignItems="center">
                    <Grid item sm={6} md={3} xs={12}>
                        <Box component="div" display="flex" alignItems="center" mt={3} mb={3} justifyContent="space-between" className={classes.title}>
                            <Box display="flex" alignItems="center" flex="1">
                                <img src={RisingStarLogo} className={classes.teamIcons} alt={`NBA LockerVision ${TeamName}`} title={`NBA LockerVision ${TeamName}`} />
                                <Box ml={1} display="flex" flexDirection="column" paddingTop={4}>
                                    <Typography variant="h5" component="h5">
                                        Rising Stars
                                    </Typography>
                                    <Typography variant="caption" component="p">
                                        {`${Helper.FormatDayWithDate(risingStarData.gameDate)}  -  ${gameTime}`}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={6} md={9} xs={12}>
                        {AppConstants.DISPLAY_SPONSORS && outfitPreviewAdDetails && <>
                            <Hidden smDown>
                                <SponsorAd
                                    adDisplay={AppConstants.AD_DISPLAY_DESKTOP}
                                    adDetails={outfitPreviewAdDetails}
                                />
                            </Hidden>
                            <Hidden mdUp>
                                <Box display="flex" alignItems="center" justifyContent="center" flex="1" marginTop="0">
                                    <SponsorAd
                                        adDisplay={AppConstants.AD_DISPLAY_MOBILE}
                                        adDetails={outfitPreviewAdDetails}
                                    />
                                </Box>
                            </Hidden>
                        </>
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={5} className={classes.mainContainer}>
                    <Grid item xs={12} sm={12}>
                        <Container maxWidth="false">
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Box paddingBottom={1} mb={1} className={classes.headerSection}>
                                        <Box className={classes.topHeading}>
                                            <Box flexDirection="column">
                                                <Typography component="h6" variant="h6">UNIFORM DETAILS</Typography>
                                                <Typography component="h4" variant="subtitle">Rising Stars Edition</Typography>
                                                {videoLink !== null && videoLink !== '' &&
                                                    play ? <Box flexDirection="row" className={classes.playThumbWrap}>
                                                    <Box title={`Play: ${AppConstants.SEASON_YEAR} Nike NBA ${EditionName} Uniform Unveil Video`} className={classes.playThumb} onClick={() => {
                                                        setPlay(false);
                                                    }} >
                                                        <PlayCircleOutlineIcon style={{ fontSize: 72, color: '#8f8f8f', 'margin-top': '14px' }} />
                                                    </Box>
                                                    <Typography component="h4" variant="subtitle" style={{ 'margin-left': '12px' }}>{`Play: ${AppConstants.SEASON_YEAR} Nike NBA`}< br />{`${EditionName} Uniform Unveil Video`}</Typography>
                                                </Box> : <>
                                                </>}
                                            </Box>
                                            <Box>
                                                <Chip variant="outlined"
                                                    label=""
                                                    icon={<ArrowBackIcon style={{ fontSize: 17, color: '#8f8f8f', 'margin-left': '12px' }} />}
                                                    className={classes.backchip}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        backToDashboard();
                                                    }} />
                                                {<PreviewShopIcon teamName="All-Star" />}
                                            </Box>
                                        </Box>
                                        {!play &&
                                            <>
                                                <Box display="flex" justifyContent="space-between" flex="1" p={1}>
                                                    <Typography variant="subtitle2" className={classes.textColor} title={`${AppConstants.SEASON_YEAR} Nike NBA ${EditionName} Uniform Unveil Video`} >{AppConstants.SEASON_YEAR} Nike NBA {TeamName} ({EditionName}) Uniform Unveil Video</Typography>
                                                    <IconButton aria-label="close" size="small" className={classes.textColor}
                                                        title={`Click to hide the Unveil Video`}
                                                        onClick={() => {
                                                            setPlay(true);
                                                        }} >
                                                        <HighlightOffIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box className={classes.playerWrapper}>
                                                    <ReactPlayer
                                                        className={classes.reactPlayer}
                                                        playing={true}
                                                        controls={true}
                                                        // light={true}
                                                        url={videoLink}
                                                        width="100%"
                                                        height="100%"
                                                        title={`${AppConstants.SEASON_YEAR} Nike NBA ${EditionName} Uniform Unveil Video`}
                                                    />
                                                </Box></>}
                                    </Box>
                                </Grid>
                                <Grid xs={12}>
                                    <Box className={classes.textColor}>
                                        <Typography variant="body1" component="p">
                                            <div dangerouslySetInnerHTML={{ __html: risingStarData.uniform_Story }} />
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} justifyContent="center">
                                <Grid item sm={3} xs={12}>
                                    <Card className={classes.cardRoot}>
                                        <Box display="flex" justifyContent="center" className={classes.customChip} >
                                            <Chip
                                                label={risingStarData.uniforms[0].uniform_Name}
                                                style={Helper.checkForComingSoon(risingStarData.uniforms[0].uniform_Img_Url) ?
                                                    { background: "black", color: "white" } : { background: risingStarData.uniforms[0].bG_Color, color: risingStarData.uniforms[0].text_Color }} />
                                        </Box>
                                        <Box>
                                            <CardActions className={classes.cardAction}>
                                                <Link>
                                                    <SocialMediaContainer
                                                        awayTeamName={AwayTeamName}
                                                        pageName="risingStarOutfitPreview"
                                                        name="Uniform"
                                                        teamName={""}
                                                        date={Helper.FormatDayWithDate(gameDate)}
                                                        img={risingStarData.uniforms[0].uniform_Img_Url}
                                                        editionname={"Rising Stars"}
                                                        GameIDForShare={""}
                                                        TeamIDForShare={""}
                                                        teamNameSEO={""} />
                                                </Link>
                                                {/* <Link>
                                                    <FavoriteBorderIcon />
                                                </Link> */}
                                            </CardActions>
                                        </Box>
                                        <CardMedia className={classes.cardMedia}>
                                            <img src={risingStarData.uniforms[0].uniform_Img_Url}
                                                alt={`NBA LockerVision Rising Stars Uniform ${risingStarData.uniforms[0].uniform_Name}`}
                                                title={`NBA LockerVision Rising Stars Uniform ${risingStarData.uniforms[0].uniform_Name}`} />

                                        </CardMedia>
                                        <CardContent>
                                            <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                {/*JACKET/PANT*/}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                    <Card className={classes.cardRoot}>
                                        <Box display="flex" justifyContent="center" className={classes.customChip} >
                                            <Chip
                                                label={risingStarData.uniforms[1].uniform_Name}
                                                style={Helper.checkForComingSoon(risingStarData.uniforms[1].uniform_Img_Url) ?
                                                    { background: "black", color: "white" } : { background: risingStarData.uniforms[1].bG_Color, color: risingStarData.uniforms[1].text_Color }} />
                                        </Box>
                                        <Box>
                                            <CardActions className={classes.cardAction}>
                                                <Link>
                                                    <SocialMediaContainer
                                                        awayTeamName={AwayTeamName}
                                                        pageName="risingStarOutfitPreview"
                                                        name="Uniform"
                                                        teamName={""}
                                                        date={Helper.FormatDayWithDate(gameDate)}
                                                        img={risingStarData.uniforms[1].uniform_Img_Url}
                                                        editionname={"Rising Stars"}
                                                        GameIDForShare={""}
                                                        TeamIDForShare={""}
                                                        teamNameSEO={""} />
                                                </Link>
                                                {/* <Link>
                                                    <FavoriteBorderIcon />
                                                </Link> */}
                                            </CardActions>
                                        </Box>
                                        <CardMedia className={classes.cardMedia}>
                                            <img src={risingStarData.uniforms[1].uniform_Img_Url}
                                                alt={`NBA LockerVision Rising Stars Uniform ${risingStarData.uniforms[1].uniform_Name}`}
                                                title={`NBA LockerVision Rising Stars Uniform ${risingStarData.uniforms[1].uniform_Name}`} />
                                        </CardMedia>

                                        <CardContent>
                                            <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                {/*LONG SLEEVE*/}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                    <Card className={classes.cardRoot}>
                                        <Box display="flex" justifyContent="center" className={classes.customChip} >
                                            <Chip
                                                label={risingStarData.uniforms[2].uniform_Name}
                                                style={Helper.checkForComingSoon(risingStarData.uniforms[2].uniform_Img_Url) ?
                                                    { background: "black", color: "white" } : { background: risingStarData.uniforms[2].bG_Color, color: risingStarData.uniforms[2].text_Color }} />
                                        </Box>
                                        <Box>
                                            <CardActions className={classes.cardAction}>
                                                <Link>
                                                    <SocialMediaContainer
                                                        awayTeamName={AwayTeamName}
                                                        pageName="risingStarOutfitPreview"
                                                        name="Uniform"
                                                        teamName={""}
                                                        date={Helper.FormatDayWithDate(gameDate)}
                                                        img={risingStarData.uniforms[2].uniform_Img_Url}
                                                        editionname={"Rising Stars"}
                                                        GameIDForShare={""}
                                                        TeamIDForShare={""}
                                                        teamNameSEO={""} />
                                                </Link>
                                                {/* <Link>
                                                    <FavoriteBorderIcon />
                                                </Link> */}
                                            </CardActions>
                                        </Box>
                                        <CardMedia className={classes.cardMedia}>
                                            <img src={risingStarData.uniforms[2].uniform_Img_Url}
                                                alt={`NBA LockerVision Rising Stars Uniform ${risingStarData.uniforms[2].uniform_Name}`}
                                                title={`NBA LockerVision Rising Stars Uniform ${risingStarData.uniforms[2].uniform_Name}`} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                {/*SHORT SLEEVE*/}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                    <Card className={classes.cardRoot}>
                                        <Box display="flex" justifyContent="center" className={classes.customChip} >
                                            <Chip
                                                label={risingStarData.uniforms[3].uniform_Name}
                                                style={Helper.checkForComingSoon(risingStarData.uniforms[3].uniform_Img_Url) ?
                                                    { background: "black", color: "white" } : { background: risingStarData.uniforms[3].bG_Color, color: risingStarData.uniforms[2].text_Color }} />
                                        </Box>
                                        <Box>
                                            <CardActions className={classes.cardAction}>
                                                <Link>
                                                    <SocialMediaContainer
                                                        awayTeamName={AwayTeamName}
                                                        pageName="risingStarOutfitPreview"
                                                        name="Uniform"
                                                        teamName={""}
                                                        date={Helper.FormatDayWithDate(gameDate)}
                                                        img={risingStarData.uniforms[3].uniform_Img_Url}
                                                        editionname={"Rising Stars"}
                                                        GameIDForShare={""}
                                                        TeamIDForShare={""}
                                                        teamNameSEO={""} />
                                                </Link>
                                                {/* <Link>
                                                    <FavoriteBorderIcon />
                                                </Link> */}
                                            </CardActions>
                                        </Box>
                                        <CardMedia className={classes.cardMedia}>
                                            <img src={risingStarData.uniforms[3].uniform_Img_Url}
                                                alt={`NBA LockerVision Rising Stars Uniform ${risingStarData.uniforms[3].uniform_Name}`}
                                                title={`NBA LockerVision Rising Stars Uniform ${risingStarData.uniforms[3].uniform_Name}`} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                {/*BASKETBALL*/}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>                                
                                <Grid item sm={6} xs={12}>
                                    <Card className={classes.cardRoot}>
                                            <Box display="flex" justifyContent="center" className={classes.customChip} >
                                                <Chip
                                                    label={risingStarData.court_Name}
                                                    style={Helper.checkForComingSoon(risingStarData.court_Img_Url) ?
                                                        { background: "black", color: "white" } : { background: BGColor, color: TextColor }} />
                                            </Box>
                                            <CardMedia className={classes.cardMedia}> 
                                            <img src={risingStarData.court_Img_Url} className="imgHeightVariant"
                                                    alt={`NBA LockerVision Rising Stars Court ${risingStarData.court_Name}`}
                                                    title={`NBA LockerVision Rising Stars Court ${risingStarData.court_Name}`} />
                                            </CardMedia>
                                            <CardContent>
                                                <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                    COURT
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                <Grid item sm={3} xs={12}>
                                    <Card className={classes.cardRoot}>
                                        <Box display="flex" justifyContent="center" className={classes.customChip} >
                                            <Chip label={risingStarData.basketball_Name}
                                                style={Helper.checkForComingSoon(risingStarData.basketball_Img_Url) ? { background: "black", color: "white" } : { background: BGColor, color: TextColor }} />
                                        </Box>
                                        <Box>
                                            <CardActions className={classes.cardAction}>
                                                <Link>
                                                    <SocialMediaContainer
                                                        awayTeamName={AwayTeamName}
                                                        pageName="risingStarOutfitPreview"
                                                        name="Basketball"
                                                        iconColor='gray'
                                                        teamName={""}
                                                        date={Helper.FormatDayWithDate(gameDate)}
                                                        img={risingStarData.basketball_Img_Url}
                                                        editionname={"Rising Stars"}
                                                        GameIDForShare={""}
                                                        TeamIDForShare={""}
                                                        teamNameSEO={""} />
                                                </Link>
                                                {/* <Link>
                                                    <FavoriteBorderIcon />
                                                </Link> */}
                                            </CardActions>
                                        </Box>
                                        <CardMedia className={classes.cardMedia}>
                                            <img src={risingStarData.basketball_Img_Url}
                                                alt={`NBA LockerVision Rising Stars Basketball ${risingStarData.basketball_Name}`}
                                                title={`NBA LockerVision Rising Stars Basketball ${risingStarData.basketball_Name}`} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                BASKETBALL
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                    <Card className={classes.cardRoot}>
                                        <Box display="flex" justifyContent="center" className={classes.customChip} >
                                            <Chip label={risingStarData.trophy_Name}
                                                style={Helper.checkForComingSoon(risingStarData.trophy_Img_Url) ?
                                                    { background: "black", color: "white" } : { background: BGColor, color: TextColor }} />
                                        </Box>
                                        <Box>
                                            <CardActions className={classes.cardAction}>
                                                <Link>
                                                    <SocialMediaContainer
                                                        awayTeamName={AwayTeamName}
                                                        pageName="risingStarOutfitPreview"
                                                        name="Trophy"
                                                        iconColor='gray'
                                                        teamName={""}
                                                        date={Helper.FormatDayWithDate(gameDate)}
                                                        img={risingStarData.trophy_Img_Url}
                                                        editionname={"Rising Stars"}
                                                        GameIDForShare={""}
                                                        TeamIDForShare={""}
                                                        teamNameSEO={""} />
                                                </Link>
                                                {/* <Link>
                                                    <FavoriteBorderIcon />
                                                </Link> */}
                                            </CardActions>
                                        </Box>
                                        <CardMedia className={classes.cardMedia}>
                                            <img src={risingStarData.trophy_Img_Url}
                                                alt={`NBA LockerVision Rising Stars Trophy ${risingStarData.trophy_Name}`}
                                                title={`NBA LockerVision Rising Stars Trophy ${risingStarData.trophy_Name}`} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                TROPHY
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </>}
            <Dialog onClose={handleDialogClose} className={classes.DialogRoot} aria-labelledby="simple-dialog-title" open={isDialogOpen}>
                <MuiDialogTitle disableTypography>
                    <Box display="flex" justifyContent="space-between" flex="1" p={1}>
                        {/*<Hidden>*/}
                        {/*    <Typography variant="subtitle2" className={classes.DialogHeader}>{TeamName} <br />({EditionName})</Typography>*/}
                        {/*</Hidden>*/}
                        <IconButton aria-label="close" size="small" className={classes.closeButton}
                            onClick={(data) => {
                                handleDialogClose();
                            }} >
                            <HighlightOffIcon />
                        </IconButton>
                    </Box>

                </MuiDialogTitle>

                <ImageSlider images={LargeImages} />
            </Dialog>

        </Container>
    );
}

export default withRouter(RisingStarOutfitPreview);