import React, { useState, useEffect, useContext } from "react";
import { GetGamesData, GetCalenderDates, GetTeamList, GetCalenderData, GetGame } from "../../services/home.service";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Helper from '../../common/Helper';
import CircularLoader from '../../sharedComponents/emptyStateUIContainers/CircularLoader';
import SliderCalendar from "../../sharedComponents/SliderCalendar/SliderCalendar";
import CardContainer from "../../sharedComponents/CardContainer/CardContainer";
import SponsorAd from '../../sharedComponents/SponsorContainer/SponsorAd';
import NoGameInSessionContainer from '../../sharedComponents/noGameContainer/NoGameInSessionContainer';
import SideBarFilter from '../../screens/GameSchedule/SideBarFilter';
import StarEventsSlider from "../../sharedComponents/StarsEventSlider/StarEventSlider";
import AppConstants from '../../common/AppConstants';
import { AppDataContext } from "../../common/AppContext";

// For Filter Search Box //
import TuneIcon from '@material-ui/icons/Tune';
import HelmetMetaData from "../../sharedComponents/SocialMedia/HelmetMetaDeta";
//import SponsorAd from "../../sharedComponents/SponsorContainer/SponsorAd";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    input: {
        marginLeft: theme.spacing(0.2),
        flex: 1,
        color: '#fff !important',
    },
    iconButton: {
        padding: 1,
        color: '#fff !important',
    },
    showFilter: {
        display: 'inline-flex',
        marginTop: theme.spacing(2),
        background: '#000 !important',
        color: '#fff !important',
        border: '1px solid #666',
        [theme.breakpoints.only('xs')]: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(1.5),
        },
    },
    content: {
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        // overflow: 'auto',
        // overflowX: 'hidden',
        //padding: theme.spacing(0, 3),
        // [theme.breakpoints.only('sm')]: {
        //   padding: theme.spacing(0, 2),
        // },
        [theme.breakpoints.only('xs')]: {
            marginTop: theme.spacing(4),
        },
    },
    mainContainer: {
        background: 'rgba(0, 0, 0, .65) !important',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(-3),
    },
    gamesHeading: {
        color: '#888888',
    },
    filters: {
        color: "#B5B5B5",
        cursor: "pointer",
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(0.5),
        marginBottom: theme.spacing(-1),
    },
    filterIcon: {
        verticalAlign: "middle",
        marginLeft: theme.spacing(.5),
    },
    headerText: {
        color: '#fff',
        fontFamily: '"Action NBA  Web" !important',
        fontSize: '1.8rem',
        fontWeight: '300',
        lineHeight: '1'
    },
    link: {
        cursor: 'pointer'
    },
    headerContainer: {
        color: '#ffffff !important',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 3.5),
        },
    },
}));

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const GameScheduleDashboard = (props) => {
    const classes = useStyles();

    const [openMessage, setOpenMessage] = useState();
    const [message, setMessage] = useState("");
    const [isLoading, setLoading] = useState(true);
    const [showDrawer, setShowDrawer] = useState(false);
    const keywords = process.env.REACT_APP_SEO_KEYWORDS_FOR_SCHEDULE_PAGE;
    const description = process.env.REACT_APP_SEO_DESCRIPTION_FOR_SCHEDULE_PAGE;

    const checkInLocal = () => {
        let inLocal = false;
        if (localStorage.getItem("calendarMinDate")
            && localStorage.getItem("calendarMaxDate")) {
            inLocal = true;
        }
        return inLocal
    }

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMessage(false);
    };

    //Team Filter
    let teamFilter = sessionStorage.getItem(AppConstants.TEAM_FILTER);
    if (teamFilter == null) { sessionStorage.setItem(AppConstants.TEAM_FILTER, AppConstants.ALL_TEAMS); }

    //GameType Filter
    let gameTypeFilter = sessionStorage.getItem(AppConstants.GAME_TYPE_FILTER);
    if (gameTypeFilter == null) { sessionStorage.setItem(AppConstants.GAME_TYPE_FILTER, AppConstants.ALL_GAMES); }

    //Start Date Filter    
    let startDateFilter = sessionStorage.getItem(AppConstants.START_DATE_FILTER);
    if (startDateFilter == null) { sessionStorage.setItem(AppConstants.START_DATE_FILTER, ""); }

    //End Date Filter
    let endDateFilter = sessionStorage.getItem(AppConstants.END_DATE_FILTER);
    if (endDateFilter == null) { sessionStorage.setItem(AppConstants.END_DATE_FILTER, ""); }

    const [dates, setDatesList] = useState('');
    const [isFilterApply, setFilterApplyStatus] = useState(true);
    const [calenderDates, setCalenderDates] = useState([]);
    const [ActiveGames, setActiveGames] = useState([]);
    const [TilesGameState, setGameTilesState] = useState([]);
    const [calenderMinDate, setCalenderMinDate] = useState("");
    const [calenderMaxDate, setCalenderMaxDate] = useState("");
    const [noResults, setNoResults] = useState(false);
    const { allTeams, allTeamsForMenu, sponsorAds, sponsorAdsFetched, GetSponsorAds, starGames, GetStarGames } = useContext(AppDataContext);
    const [scheduleAdDetails, setScheduleAdDetails] = useState(!sponsorAds || sponsorAds.length === 0 ?
        Helper.GetPageAds("schedule") : sponsorAds.find(ad => ad.page === "schedule"));

    const [forceKey, setForceKey] = useState(0);
    const [showAllStarGameTile, setShowAllStarGameTile] = useState(false);
    const [showRisingStarsGameTile, setShowRisingStarsGameTile] = useState(false);
    const [filtersReset, setFiltersReset] = useState(undefined);

    const getDateRange = () => {
        GetCalenderDates()
            .then(data => {
                let minDate = "";
                let maxDate = "";
                if (data.length > 0) {
                    minDate = data[0].gameDate;
                    let lastIndex = data.length - 1;
                    let lastDate = data[lastIndex];
                    maxDate = lastDate.gameDate;
                }
                else {
                    minDate = data[0].gameDate;
                    maxDate = data[0].gameDate;
                }
                localStorage.setItem("calendarMinDate", minDate)
                localStorage.setItem("calendarMaxDate", maxDate)
                setCalenderMinDate(minDate);
                setCalenderMaxDate(maxDate);

            }).catch(err => {
                throw err;
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!starGames) {
            GetStarGames();
        }

        if (!checkInLocal)
            getDateRange();
        else {
            setCalenderMinDate(localStorage.getItem("calendarMinDate"));
            setCalenderMaxDate(localStorage.getItem("calendarMaxDate"));
        }
        sessionStorage.setItem("selectedTab", "Schedule");
        props.changeTabInHeader("schedule");
        
        getGamesData();

        if (!sponsorAdsFetched) {
            GetSponsorAds();
        }
        else {
            setScheduleAdDetails(Helper.GetPageAds("schedule"));
        }
    }, [sponsorAdsFetched, starGames])

    const checkSetStarGamesDisplay = (date) => {
        let gamesAvailable = false;
        debugger;
        if (Helper.FormatDateDashMMDDYYYY(starGames.eventDates.allStar) === Helper.FormatDateDashMMDDYYYY(date)) {
            setShowAllStarGameTile(true);
            if (!gamesAvailable) {
                gamesAvailable = true;
            }
        }
        if (Helper.FormatDateDashMMDDYYYY(starGames.eventDates.risingStars) === Helper.FormatDateDashMMDDYYYY(date)) {
            setShowRisingStarsGameTile(true);
            if (!gamesAvailable) {
                gamesAvailable = true;
            }
        }
        return gamesAvailable;
    }

    const setActiveTilesSession = () => {
        sessionStorage.setItem(AppConstants.ACTIVE_TILE, 0);
    }

    const validDates = (startDate, endDate) => {
        startDate = new Date(startDate);
        endDate = new Date(endDate);

        if (startDate.getTime() < new Date(calenderMinDate).getTime() ||
            startDate.getTime() > new Date(calenderMaxDate).getTime()) {
            setOpenMessage(true);
            setMessage(`Selected start date is outside season's date range ${calenderMinDate} - ${calenderMaxDate}`);
            return false;
        }

        if (endDate.getTime() < new Date(calenderMinDate).getTime() ||
            endDate.getTime() > new Date(calenderMaxDate).getTime()) {
            setOpenMessage(true);
            setMessage(`Selected end date is outside season's date range ${calenderMinDate} - ${calenderMaxDate}`);
            return false;
        }

        if (startDate.getTime() > endDate.getTime()) {
            setOpenMessage(true);
            setMessage("Start date cannot be greater than end date.");
            return false;
        }

        if (endDate.getTime() < startDate.getTime()) {
            setOpenMessage(true);
            setMessage("End date cannot be less than start date.");
            return false;
        }

        return true;
    }

    const getGamesData = (reset) => {
        setShowDrawer(false);
        setShowAllStarGameTile(false);
        setShowRisingStarsGameTile(false);
        let startDate = sessionStorage.getItem(AppConstants.START_DATE_FILTER);
        let endDate = sessionStorage.getItem(AppConstants.END_DATE_FILTER);

        let team = sessionStorage.getItem(AppConstants.TEAM_FILTER);
        let gameType = sessionStorage.getItem(AppConstants.GAME_TYPE_FILTER);

        if (gameType !== null) {
            if (gameType === 'Home Games') {
                gameType = "H";
            }
            else if (gameType === 'Away Games') {
                gameType = "A";
            }
        }

        let initialLoad = (startDate === "" && endDate === "" && team === AppConstants.ALL_TEAMS && gameType === AppConstants.ALL_GAMES) ? true : false;

        //validate Dates
        if (!validDates(startDate, endDate)) {
            setShowDrawer(true);
            setLoading(false);
            return;
        }

        let reqObj = {};
        reqObj.StartDate = Helper.FormatDateDashMMDDYYYY(startDate);
        reqObj.EndDate = Helper.FormatDateDashMMDDYYYY(endDate);

        reqObj.TeamId = team;
        reqObj.GameType = gameType;
        GetGamesData(reqObj).then(data => {
            if (data.length > 0) {
                setNoResults(false);
                if (initialLoad) {
                    setFilterApplyStatus(true);
                    GetCalenderDates().then(calenderData => {
                        sessionStorage.setItem(AppConstants.END_DATE_FILTER, "");
                        let tilesIndex = sessionStorage.getItem(AppConstants.ACTIVE_TILE);
                        let selectedTilesGameDate = "";

                        if (tilesIndex == undefined || tilesIndex == null || reset) {
                            let index = calenderData.findIndex(x => x.gameDate === Helper.FormatDate(new Date()));
                            if (index !== -1) {
                                sessionStorage.setItem(AppConstants.ACTIVE_TILE, index);
                            } else {
                                sessionStorage.setItem(AppConstants.ACTIVE_TILE, 0);
                            }
                            let filterCalenderArr = calenderData.filter(obj => obj.gameDate === Helper.FormatDate(new Date()));
                            selectedTilesGameDate = (filterCalenderArr !== undefined && filterCalenderArr.length > 0) ? filterCalenderArr[0]['gameDate'] : calenderData[0]['gameDate'];

                        } else {
                            selectedTilesGameDate = calenderData[tilesIndex]['gameDate'];
                        }

                        setCalenderDates(calenderData);
                        populateGamesOnDate(selectedTilesGameDate)
                        makeActiveTileState(calenderData);
                        setForceKey(forceKey + 1);
                    });
                }
                else {
                    setFilterApplyStatus(false);
                    if (starGames) {
                        let tempCalDates = [];
                        if (starGames.eventDates.allStar) {
                            if (new Date(starGames.eventDates.allStar).getTime() >= new Date(startDate).getTime() &&
                                new Date(starGames.eventDates.allStar).getTime() <= new Date(endDate).getTime()) {
                                let allStarGames = [];
                                allStarGames.push(starGames.allStarGames[0]);
                                tempCalDates.push({
                                    day: new Date(starGames.eventDates.allStar).getDate().toString(),
                                    gameDate: Helper.FormatDateMMDDYYYYWithoutTime(starGames.eventDates.allStar),
                                    games: allStarGames,
                                    gamesCount: 1,
                                    month: monthNames[new Date(starGames.eventDates.allStar).getMonth()],
                                    weekDay: days[new Date(starGames.eventDates.allStar).getDay()],
                                    year: new Date(starGames.eventDates.allStar).getFullYear()
                                });
                            }
                        }
                        if (starGames.eventDates.risingStars) {
                            if (new Date(starGames.eventDates.risingStars).getTime() >= new Date(startDate).getTime() &&
                                new Date(starGames.eventDates.risingStars).getTime() <= new Date(endDate).getTime()) {
                                let risingStarsGames = [];
                                risingStarsGames.push(starGames.risingStarData);
                                tempCalDates.push({
                                    day: new Date(starGames.eventDates.risingStars).getDate().toString(),
                                    gameDate: Helper.FormatDateMMDDYYYYWithoutTime(starGames.eventDates.risingStars),
                                    games: risingStarsGames,
                                    gamesCount: 1,
                                    month: monthNames[new Date(starGames.eventDates.risingStars).getMonth()],
                                    weekDay: days[new Date(starGames.eventDates.risingStars).getDay()],
                                    year: new Date(starGames.eventDates.risingStars).getFullYear()
                                });
                            }
                        }
                        let updatedData = [...data, ...tempCalDates]
                        updatedData = updatedData.sort(function (a, b) {
                            return new Date(a.gameDate) - new Date(b.gameDate);
                        });
                        setCalenderDates(updatedData);
                        let selGame = updatedData[parseInt(sessionStorage.getItem(AppConstants.ACTIVE_TILE))];
                        checkSetStarGamesDisplay(selGame.gameDate);
                        makeActiveTileState(updatedData);
                        getActiveTiles(updatedData, parseInt(sessionStorage.getItem(AppConstants.ACTIVE_TILE)));
                    }
                    else {
                        setCalenderDates(data);
                        makeActiveTileState(data);
                        getActiveTiles(data, parseInt(sessionStorage.getItem(AppConstants.ACTIVE_TILE)));
                    }
                    
                    setForceKey(forceKey + 1);
                }
            }
            else {
                //Check Star Games
                let tempCalDates = [];
                if (starGames) {
                    setFilterApplyStatus(false);
                    if (starGames.eventDates.allStar) {
                        if (new Date(starGames.eventDates.allStar).getTime() >= new Date(startDate).getTime() &&
                            new Date(starGames.eventDates.allStar).getTime() <= new Date(endDate).getTime()) {
                            let allStarGames = [];
                            allStarGames.push(starGames.allStarGames[0]);
                            tempCalDates.push({
                                day: new Date(starGames.eventDates.allStar).getDate().toString(),
                                gameDate: Helper.FormatDateMMDDYYYYWithoutTime(starGames.eventDates.allStar),
                                games: allStarGames,
                                gamesCount: 1,
                                month: monthNames[new Date(starGames.eventDates.allStar).getMonth()],
                                weekDay: days[new Date(starGames.eventDates.allStar).getDay()],
                                year: new Date(starGames.eventDates.allStar).getFullYear()
                            });
                        }
                    }
                    if (starGames.eventDates.risingStars) {
                        if (new Date(starGames.eventDates.risingStars).getTime() >= new Date(startDate).getTime() &&
                            new Date(starGames.eventDates.risingStars).getTime() <= new Date(endDate).getTime()) {
                            let risingStarsGames = [];
                            risingStarsGames.push(starGames.risingStarData);
                            tempCalDates.push({
                                day: new Date(starGames.eventDates.risingStars).getDate().toString(),
                                gameDate: Helper.FormatDateMMDDYYYYWithoutTime(starGames.eventDates.risingStars),
                                games: risingStarsGames,
                                gamesCount: 1,
                                month: monthNames[new Date(starGames.eventDates.risingStars).getMonth()],
                                weekDay: days[new Date(starGames.eventDates.risingStars).getDay()],
                                year: new Date(starGames.eventDates.risingStars).getFullYear()
                            });
                        }
                    }
                }
                else {
                    setCalenderDates([]);
                }

                setNoResults(true);

                if (tempCalDates.length > 0) {
                    tempCalDates = tempCalDates.sort(function (a, b) {
                        return new Date(a.gameDate) - new Date(b.gameDate);
                    });

                    setCalenderDates(tempCalDates);
                    checkSetStarGamesDisplay(tempCalDates[parseInt(sessionStorage.getItem(AppConstants.ACTIVE_TILE))].gameDate);
                    makeActiveTileState(tempCalDates);
                    getActiveTiles(tempCalDates, parseInt(sessionStorage.getItem(AppConstants.ACTIVE_TILE)));
                    setForceKey(forceKey + 1);
                }
                else {
                    setCalenderDates([]);
                }

                setLoading(false);
            }
        }).catch(err => {
            throw err;
        });
    }

    const getGamesOnDate = (date) => {
        setShowAllStarGameTile(false);
        setShowRisingStarsGameTile(false);
        checkSetStarGamesDisplay(date);
        let team = sessionStorage.getItem(AppConstants.TEAM_FILTER);
        let gameType = sessionStorage.getItem(AppConstants.GAME_TYPE_FILTER);

        if (gameType !== null) {
            if (gameType === 'Home Games') {
                gameType = "H";
            }
            else if (gameType === 'Away Games') {
                gameType = "A";
            }
        }

        let reqObj = {};
        reqObj.StartDate = Helper.FormatDateDashMMDDYYYY(date);
        reqObj.EndDate = Helper.FormatDateDashMMDDYYYY(date);
        reqObj.TeamId = team;
        reqObj.GameType = gameType;

        GetGamesData(reqObj).then(data => {
            if (data.length > 0) {
                setActiveGames(data[0]);
                setNoResults(false);
            }
            else {
                setActiveGames({})
                setNoResults(true);
            }
            setLoading(false);
        }).catch(err => {
            throw err;
        });
    }

    // helper function to create the state of tile (active/inactive) dynamically
    const makeActiveTileState = (data) => {
        let lastIndex = parseInt(sessionStorage.getItem(AppConstants.ACTIVE_TILE));
        let tempArr = [...TilesGameState];
        tempArr = data.map((item, i) => {
            if (lastIndex != undefined && lastIndex != null && !isNaN(lastIndex)) {
                if (i == lastIndex)
                    return true;
                else
                    return false;
            }
            else if (i === 0) {
                lastIndex = 0;
                return true;
            }
            else {
                return false;
            }                
        });
        setGameTilesState(tempArr);
    }

    const getActiveTiles = (data, index) => {
        let ActiveGameData = {};
        if (index != undefined && index != null && !isNaN(index)) {
            for (let i = 0; i < data.length; i++) {
                if (i === index)
                    ActiveGameData = data[i];
            }
        }
        setActiveGames(ActiveGameData);
        setLoading(false);
    };

    const setActiveIndex = (index) => {
        let tempArr = [...TilesGameState];
        tempArr = tempArr.map((item, i) => {
            if (i == index)
                item = true;
            else
                item = false;
            return item;
        });

        sessionStorage.setItem("activeTile", index);
        setGameTilesState(tempArr);
    }

    const showSelectTeamMessage = () => {
        setOpenMessage(true);
        setMessage("Please first select the value from teams filter.");
    }

    const showAllGameTypeSelectedMessage = () => {
        setOpenMessage(true);
        setMessage("Since 'All Teams' is selected, game type is set to 'All Games'. Team must be selected to apply game type filter.");
    }

    const populateGamesOnDate = (gameDate, index) => {
        //Check star games
        setShowRisingStarsGameTile(false);
        setShowAllStarGameTile(false);
        checkSetStarGamesDisplay(gameDate);

        GetGame(gameDate).then(d => {
            if (d !== undefined && d.gameDate !== undefined && d !== "") {
                setActiveGames(d)
                setLoading(false)
                setNoResults(false);
            } else {
                setActiveGames({})
                setNoResults(true);
                setLoading(false);
            }
        }).catch(err => {
            throw err;
        });
    }

    const HeaderText = (props) => {
        const classes = useStyles();
        return (
            <Container className={classes.headerContainer} maxWidth={false}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4" component="h4" className={classes.headerText}><span>{props.text}</span>
                    </Typography>
                    {/*{props.showAd &&*/}
                    {/*    <Box alignItems="center">*/}
                    {/*        <SponsorAd from="teams" sponsorsAdSource={""} sponsorsAdLink={""} />*/}
                    {/*    </Box>*/}
                    {/*}*/}
                </Box>
            </Container>
        )
    };


    return (
        <>
            <HelmetMetaData
                title={"NBA LockerVision - Schedule"}
                quote={"Every Locker, Every Player, Every Game."}
                url={window.location.href}
                hashtag={"#nbagameschedule"}
                description={description}
                keywords={keywords}
            ></HelmetMetaData>



            {!isLoading && <Button
                variant="contained"
                style={{ 'background-color': 'transparent' }}
                className={classes.filters}
                onClick={() => { setShowDrawer(true) }}
                endIcon={<TuneIcon className={classes.filterIcon}>send</TuneIcon>}
            >
                Filters
            </Button>}
            <Box component="div" className={classes.root}>

                {!isLoading && <>
                    <SideBarFilter
                        getData={(reset) => {
                            setLoading(true);
                            setFiltersReset(reset);
                            if (reset) {
                                getGamesData(reset);
                            } else {
                                setActiveTilesSession();
                                getGamesData();
                            }
                        }}
                        teams={allTeamsForMenu}
                        calenderMinDate={calenderMinDate}
                        calenderMaxDate={calenderMaxDate}
                        isShowDrawer={showDrawer}
                        showSelectTeamMessage={showSelectTeamMessage}
                        showAllGameTypeSelectedMessage={showAllGameTypeSelectedMessage}
                        changeDrawerStatus={(drawer) => {
                            setShowDrawer(false);
                        }}></SideBarFilter>
                </>
                }

                <Box className={classes.content}>
                    {isLoading && <CircularLoader />}

                    {!isLoading &&
                        <Grid container spacing={2} alignItems="center">
                            <Grid item sm={4} xs={12}>
                                {noResults === false && showRisingStarsGameTile === false && showAllStarGameTile === false &&
                                    <HeaderText text={"NBA Uniform Schedule For Upcoming Games"} onClick={(e) => { e.preventDefault(); }} />}
                                {noResults === true && (showRisingStarsGameTile === true || showAllStarGameTile === true) &&
                                    <HeaderText renderAd={true} showViewAll={false} text="Uniforms for NBA All-Star Weekend" />}
                                {noResults === false && (showRisingStarsGameTile === true || showAllStarGameTile === true) &&
                                    <HeaderText renderAd={true} showViewAll={false} text="Uniforms for NBA All-Star Weekend" />}
                            </Grid>

                            <Grid item sm={8} xs={12}>
                                <Box display="flex" flex="1" justifyContent="flex-end" alignItems="center">
                                    {AppConstants.DISPLAY_SPONSORS && scheduleAdDetails &&
                                        <>
                                            <Hidden smDown>
                                                <SponsorAd
                                                    sponsorAds={sponsorAds}
                                                    adDisplay={AppConstants.AD_DISPLAY_DESKTOP}
                                                    adDetails={scheduleAdDetails}
                                                />
                                            </Hidden>
                                            <Hidden mdUp>
                                                <Box display="flex" alignItems="center" justifyContent="center" flex="1" marginTop="1">
                                                    <SponsorAd
                                                        adDisplay={AppConstants.AD_DISPLAY_MOBILE}
                                                        adDetails={scheduleAdDetails}
                                                    />
                                                </Box>
                                            </Hidden>
                                        </>
                                    }
                                </Box>
                            </Grid>
                        </Grid>

                    }
                    <SliderCalendar
                        populateGames={(gameDate, index) => {
                            setLoading(true);
                            getGamesOnDate(gameDate)
                            setActiveIndex(index);
                        }}
                        intialTiles={parseInt(sessionStorage.getItem(AppConstants.ACTIVE_TILE))}
                        isFilterApply={isFilterApply}
                        tilesDates={calenderDates}
                        ActiveState={TilesGameState}
                        forceKey={forceKey}
                    />

                    {!isLoading && starGames && (showRisingStarsGameTile || showAllStarGameTile) && <>
                        <Container className={classes.mainContainer} maxWidth="false">
                            <Grid container spacing={2} className={classes.gamesMainContainerForXScroll} style={{ 'margin-top': '25x' }}>
                                <StarEventsSlider
                                    highlightInHeader={() => {
                                        props.changeTabInHeader("schedule");
                                    }}
                                    displayPage="Schedule"
                                    starGames={starGames}
                                    xScroll={true}
                                    showRisingStarsGameTile={showRisingStarsGameTile}
                                    showAllStarGameTile={showAllStarGameTile}
                                />
                            </Grid>
                        </Container>
                    </>
                    }

                    {!noResults && ActiveGames !== undefined && ActiveGames.gamesCount !== undefined && ActiveGames.gamesCount > 0 && !showAllStarGameTile && !showRisingStarsGameTile &&
                        <>
                            {noResults === false && (showRisingStarsGameTile === true || showAllStarGameTile === true) &&
                                <HeaderText renderAd={true} showViewAll={false} text="NBA Uniform Schedule For Upcoming Games" />}
                            <Box marginTop={4}>
                                <Container className={classes.mainContainer} maxWidth="false">
                                    <Box display="flex" flexWrap="wrap" alignItems="center">
                                        <Typography variant="caption" component="p" className={classes.gamesHeading}>
                                            {ActiveGames.gamesCount} GAME(S)
                                        </Typography>
                                    </Box>
                                    <CardContainer games={ActiveGames.games} />
                                </Container>
                            </Box>
                        </>
                    }
                    {noResults && !showAllStarGameTile && !showRisingStarsGameTile && <>
                        <Container className={classes.mainContainer} maxWidth="false">
                            <NoGameInSessionContainer />
                        </Container>
                    </>}
                </Box>
            </Box>

            {openMessage && <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={openMessage}
                    autoHideDuration={8000}
                    onClose={handleCloseMessage}
                    message={message}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseMessage}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </div>}
        </>
    )
}

export default GameScheduleDashboard;
