import React, { useState, useEffect } from 'react';
import 'react-app-polyfill/stable';
import "./App.css";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { AppDataProvider, AppDataConsumer } from './common/AppContext';

import Snackbar from '@material-ui/core/Snackbar';
import HeaderBar from './sharedComponents/HeaderBar';
import FooterBar from './sharedComponents/FooterBar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import PageNotFoundUI from './sharedComponents/emptyStateUIContainers/PageNotFoundUI';
import LoadingAppUI from './sharedComponents/emptyStateUIContainers/LoadingAppUI';
import CircularLoader from './sharedComponents/emptyStateUIContainers/CircularLoader';
import GenericMessageUI from './sharedComponents/emptyStateUIContainers/GenericMessageUI';
import HelmetMetaData from './sharedComponents/SocialMedia/HelmetMetaDeta';

import RouteConstants from './common/RouteConstants';
import useServerPing from './common/useServerPing';

import GameOutfitContainer from './screens/gameOutfit/GameOutfitContainer';
import OutfitPreview from './screens/gameOutfit/OutfitPreview';
import AllStarOutfitPreview from './screens/allStar/AllStarOutfitPreview';
import RisingStarOutfitPreview from'./screens/risingStar/RisingStarOutfitPreview';
import LockerByTeam from './screens/LockerByCategory/LockerByTeam';
import Home from './screens/Home/Home';
import Signup from './screens/UniversalWidget/Signup';
import Signin from './screens/UniversalWidget/Signin';
import Aboutus from './screens/UniversalWidget/Aboutus';

import GameScheduleDashboard from './screens/GameSchedule/GameScheduleDashboard';
import OutfitPreviewFav from './screens/gameOutfit/OutfitPreviewFav';

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    content: {
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        overflowX: 'hidden',
        padding: theme.spacing(0, 3),
        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(0, 2),
        },
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(0),
        },
    },
}));

function App() {
    try {
        //Below line is required for Adobe Analytics and needs to be enabled when personalization is implemented.
        //s.visitor.getMarketingCloudVisitorID();
        console.log('Adobe ID: ', s.visitor.getMarketingCloudVisitorID());
    } catch (e) {
        //console.log('Err: ', e);
    }

    const { pingSuccess, isUserSessionValid } = useState(true);
    const [activeTab, setActive] = useState("home");

    const theme = getTheme({
        paletteType: 'light',
    });

    const classes = useStyles();

    useEffect(() => {
        if (isUserSessionValid) {
            let date = new Date();
            let ts = date.getTime();

            //... Append a time stamp so that the browsers don't cache the url and send the request to the server.
            //... This will force the server to re-direct user to the ADFS login page.
            window.location.href = `/?ts=${ts}`;
        }

    }, [isUserSessionValid]);


    function unauthorized() {
        return (
            <GenericMessageUI title="Permission Denied"
                message="You do not have permission to access this space."
                icon={<LockOutlinedIcon />}>
            </GenericMessageUI>
        )
    }

    function isAuthorized(userPermissions) {
        //... Check if the user has got access to the app or not.
        return (!userPermissions || userPermissions.filter(p => p.key === "Auth" && p.value === "HasAccess").length > 0);
    }

    return (

        <ThemeProvider theme={theme}>

            <AppDataProvider>
                <AppDataConsumer>
                    {props => {
                        return (
                            <div>
                                <HelmetMetaData
                                    title={"NBA LockerVision"}
                                    quote={"Every Locker, Every Player, Every Game."}
                                    url={window.location.href}
                                    hashtag={"#nba"}
                                    description={"NBA - LockerVision."}
                                ></HelmetMetaData>
                                {
                                    (!props.userPermissions) ? <CircularLoader /> :
                                        <div className={classes.container}>
                                            <div className={classes.toolbar} />
                                            <Router onUpdate={() => window.scrollTo(0, 0)}>
                                                <HeaderBar activeTab={activeTab} />
                                                <div className={classes.content}>

                                                    <Switch>

                                                        <Route path={RouteConstants.SIGN_UP}>
                                                            <Signup />
                                                        </Route>

                                                        <Route path={RouteConstants.SIGN_IN}>
                                                            <Signin />
                                                        </Route>

                                                        <Route path={RouteConstants.ABOUT_US}>
                                                            <Aboutus changeTabInHeader={(tab) => setActive(tab)} />
                                                        </Route>

                                                        <Route exact path={RouteConstants.HOME}>
                                                            <Home changeTabInHeader={(tab) => setActive(tab)} />
                                                        </Route>

                                                        <Route exact path={RouteConstants.SCHEDULE}>
                                                            <GameScheduleDashboard changeTabInHeader={(tab) => setActive(tab)} />
                                                        </Route>

                                                        <Route path={RouteConstants.GAME_OUTFIT}>
                                                            <OutfitPreview />
                                                        </Route>

                                                        <Route path={RouteConstants.OUTFIT}>
                                                            <OutfitPreviewFav />
                                                        </Route>

                                                        <Route path={RouteConstants.LOCKER_BY_TEAM}>
                                                            <LockerByTeam changeTabInHeader={(tab) => setActive(tab)} />
                                                        </Route>

                                                        <Route path={RouteConstants.LOCKER_BY_EDITION} >
                                                            <LockerByTeam changeTabInHeader={(tab) => setActive(tab)} />
                                                        </Route>

                                                        <Route path={RouteConstants.ALL_STAR_GAME_OUTFIT}>
                                                            <AllStarOutfitPreview />
                                                        </Route>

                                                        <Route path={RouteConstants.RISING_STAR_GAME_OUTFIT}>
                                                            <RisingStarOutfitPreview />
                                                        </Route>

                                                        <Route path=''>
                                                            <PageNotFoundUI />
                                                        </Route>

                                                    </Switch>
                                                </div>
                                            </Router>
                                            <FooterBar />
                                        </div>
                                }

                                {/* <Snackbar
                  open={!pingSuccess}
                  ContentProps={{
                    'aria-describedby': 'check-network',
                  }}
                  message={<span id="check-network">Oops! It seems that we can't reach to the server right now.</span>}
                /> */}
                            </div>
                        )
                    }
                    }
                </AppDataConsumer>
            </AppDataProvider>
        </ThemeProvider>
    );
}

export default App;

//... Setting colors for the light and dark theme. 
function getTheme(theme) {
    return createMuiTheme({
        palette: {
            type: theme.paletteType,
            primary: {
                main: (theme.paletteType === 'light') ? '#000' : '#90caf9',
                dark: (theme.paletteType === 'dark') ? "#172b5b" : "#648dae"
            },
            secondary: {
                main: (theme.paletteType === 'light') ? '#C8102E' : '#f48fb1',
            },
        }
    });
}