import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Container, } from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from 'react-router-dom';
import GameTile from '../../screens/Home/GameTile';
import RisingStarGameTile from './RisingStarGameTile';
import AllStarGameTile from './AllStarGameTile';
import RouteConstants from '../../common/RouteConstants';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "nowrap",
    },
    gameSlider: {
        color: '#ffffff !important',
        height: theme.spacing(40.5),
        // height: theme.spacing(37),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 2.25)
        },
        '& .slick-slider': {
            height: "100%"
        },
    },
    viewAll: {
        textAlign: "center",
        justifyContent: "center !important",
        cursor: "pointer",
    },
    cardRoot: {
        background: 'rgba(87, 87, 87, .5) !important',
        border: '1px solid rgba(255, 255, 255, .3)',
        boxShadow: 'none',
        height: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: theme.spacing(1.25),
        width: '100%',
        margin: theme.spacing(0, 1.5),
        '& .MuiCardHeader-root': {
            padding: theme.spacing(0, 2, 1, 2),
        },
        '& .MuiCardContent-root': {
            padding: theme.spacing(1, 2),
        },
        '& .MuiCardHeader-avatar': {
            display: 'flex',
            width: '100%',
            marginRight: '0',
        },
    },
    versus: {
        fontFamily: '"Action NBA  Web" !important',
        fontWeight: '500 !important',
        color: '#fff !important',
    },

}));

const GameSlider = (props) => {
    const classes = useStyles();
    const [starGames, setStarGames] = useState([]);
    const settings = {
        infinite: false,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 1000,

                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 1000,
                }
            },
            {
                breakpoint: 823,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 812,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 740,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 720,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 667,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 568,
                settings: {
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,

                }
            },
            {
                breakpoint: 540,
                settings: {
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,

                }
            }
        ]
    }

    useEffect(() => {
        setStarGames(props.starGames);
    }, [props.starGames]);

    const pushToScheduleRoute = (param) => {
        props.history.push({
            pathname: RouteConstants.SCHEDULE
        });
    }
    
    return (
        <Container maxWidth={false} className={`${classes.gameSlider} gameSlider`}>
            <Slider {...settings}>
                {props.displayPage === "Home" && starGames.risingStarData && starGames.risingStarData.uniforms.length > 0 &&
                    <RisingStarGameTile risingStarData={starGames.risingStarData} uniforms={starGames.risingStarData.uniforms} xScroll={props.xScroll} />
                }
                {props.displayPage === "Home" && starGames.allStarGames && starGames.allStarGames.length > 0 && starGames.allStarGames.map((item, index) => {
                    return <AllStarGameTile item={item} xScroll={props.xScroll} />
                })}
                {props.displayPage === "Schedule" && starGames.risingStarData && props.showRisingStarsGameTile === true &&
                    <RisingStarGameTile risingStarData={starGames.risingStarData} uniforms={starGames.risingStarData.uniforms} xScroll={props.xScroll} />}
                {props.displayPage === "Schedule" && starGames.allStarGames && props.showAllStarGameTile === true && starGames.allStarGames.map((item, index) => {
                    return <AllStarGameTile item={item} xScroll={props.xScroll} />
                })}
            </Slider>
        </Container>
    )
}

export default withRouter(GameSlider);