import React, { useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RouteConstants from '../../common/RouteConstants';
import AppDataContext from '../../common/AppContext';

const useStyles = makeStyles((theme) => ({
    DialogRoot: {
        '& .MuiDialog-paper': {
            background: '#000',
            width: '100%',
            borderRadius: theme.spacing(2),
            overflowY: 'unset',
            boxShadow: '0px 0px 5px rgb(255 255 255 / 30%)',
            '& .MuiDialogTitle-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: 'rgba(255, 255, 255, .5)',
                padding: theme.spacing(0, 1.5),
                '& .MuiIconButton-root': {
                    color: 'rgba(255, 255, 255, .5)',
                },
            },
            '& .MuiDialogContent-root h6': {
                color: 'rgba(255, 255, 255, .5)',
                paddingBottom: theme.spacing(1),
            },
            [theme.breakpoints.down('lg')]: {
                maxWidth: '960px',
            },
        },
        '& .slick-slide img': {
            width: '100%',
        },
    },
    imgWidth: {
        width: '100%',
        cursor: "pointer",
        boxShadow: '0px 0px 5px rgb(255 255 255 / 30%)',
    },
    fontColor: {
        color: "white !important",
    }

}));

function UniformMatchup(props) {
    const classes = useStyles();
    const history = useHistory();
    const { allTeams } = useContext(AppDataContext);
    const pushToOutfitDetail = (gameId, teamId, teamName) => {
        let teams = allTeams.filter(function (el) {
            return el.teamName == teamName;
        });
        let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";
       
        props.history.push({
            pathname: `${RouteConstants.GAME_OUTFIT}/${teamSEOName}?gameid=${gameId}`,
            state: { outfitGameId: gameId, outfitTeamId: teamId }
        })
    }

    const pushToAllStarOutfitDetail = (gameId, teamSEOName) => {
        props.history.push({
            pathname: `${RouteConstants.ALL_STAR_GAME_OUTFIT}/${teamSEOName}?gameid=${gameId}`,
            state: { outfitGameId: gameId }
        })
    }

    return (
        <Dialog onClose={(data) => { props.handleDialogClose(); }}
            maxWidth="lg"
            className={classes.DialogRoot}
            aria-labelledby="simple-dialog-title"
            open={props.isDialogOpen}>

            <MuiDialogTitle disableTypography>
                <Box display="flex" justifyContent="space-around" flex="1">
                    <Hidden xsDown>
                        <Typography variant="subtitle2" className={classes.fontColor}>{props.awayTeamName} ({props.awayTeamEdition})</Typography>
                        <Typography variant="subtitle3" className={classes.fontColor}>UNIFORM MATCHUP</Typography>
                        <Typography variant="subtitle2" className={classes.fontColor}>{props.homeTeamName} ({props.homeTeamEdition})</Typography>    
                    </Hidden>
                </Box>
                <IconButton aria-label="close" size="small" className={classes.closeButton}
                    onClick={(data) => {
                        props.handleDialogClose();
                    }} >
                    <HighlightOffIcon />
                </IconButton>
            </MuiDialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Hidden smUp>
                            <Typography variant="subtitle2" className={classes.fontColor}>{props.awayTeamName} ({props.awayTeamEdition})</Typography>
                        </Hidden>
                        <img src={props.awayTeamUniformImg}
                            className={`${classes.imgWidth}`}
                            onClick={(e) => {
                                e.preventDefault();
                                if (props.uniformType === "NBAUniform") {
                                    pushToOutfitDetail(props.gameId, props.awayTeamId, props.awayTeamName);
                                }
                                if (props.uniformType === "AllStarUniform") {
                                    pushToAllStarOutfitDetail(props.gameId, props.awayTeamNameSEO);
                                }
                            }} alt={`NBA LockerVision ${props.awayTeamName} ${props.awayTeamEdition}`} title={`NBA LockerVision ${props.awayTeamName} ${props.awayTeamEdition}`} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Hidden smUp>
                            <Typography variant="subtitle2" className={classes.fontColor}>UNIFORM MATCHUP</Typography>
                            <Typography variant="subtitle2" className={classes.fontColor}>{props.homeTeamName} ({props.homeTeamEdition})</Typography>
                        </Hidden>
                        <img src={props.homeTeamUniformImg}
                            className={`${classes.imgWidth}`}
                            onClick={(e) => {
                                e.preventDefault();
                                if (props.uniformType === "NBAUniform") {
                                    pushToOutfitDetail(props.gameId, props.homeTeamId, props.homeTeamName);
                                }
                                if (props.uniformType === "AllStarUniform") {
                                    pushToAllStarOutfitDetail(props.gameId, props.homeTeamNameSEO);
                                }
                                
                            }} alt={`NBA LockerVision ${props.homeTeamName} ${props.homeTeamEdition}`} title={`NBA LockerVision ${props.homeTeamName} ${props.homeTeamEdition}`} />

                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    );
}
export default withRouter(UniformMatchup);