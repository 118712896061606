import React, { useState, useEffect, useContext } from "react";
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';

import NBALogo from './nbaLogo/NBALogo';
import AppConstants from '../common/AppConstants';
import presentedBy from '../images/presentedby.png';
import RouteConstants from "../common/RouteConstants";
import AppBarMenu from "../sharedComponents/AppBarMenu";
import DialogBox from "../sharedComponents/DialogBox";
import AppDataContext from '../common/AppContext';


const useStyles = makeStyles(theme => ({

    AppBar: {
        background: '#000 !important',
        color: '#ffffff !important',
        zIndex: theme.zIndex.drawer + 1,
    },

    menu: {
        width: "250px",
    },

    itemIcon: {
        minWidth: "35px",
    },

    drawer: {
        width: 240,
        flexShrink: 0,
    },

    drawerPaper: {
        width: 240,
        background: "#1f1f1f"
    },

    title: {
        flexGrow: 1,
        '& h3': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '2.8rem',
            },
        },
        '& h3, & h6': {
            fontFamily: '"Action NBA  Web" !important',
            color: '#ffffff',
            lineHeight: '1',
        },
        '& h6': {
            borderLeft: '1px solid #b5b5b5',
            marginLeft: theme.spacing(.75),
            paddingLeft: theme.spacing(.75),
            lineHeight: '1.2',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.0rem',
            },
        },
        cursor: "pointer",
    },

    presentedBy: {
        color: '#ffffff',
        fontSize: '0.7rem',
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2.5),
        '& img': {
            marginLeft: theme.spacing(1),
        },
    },

    appVersion: {
        marginLeft: theme.spacing(2),
        fontSize: '10px'
    },

    formRoot: {
        display: "flex",
        flexWrap: "nowrap",
        color: "white",
        alignItems: 'center',
    },

    menuLabel: {
        color: "#b5b5b5",
        alignItems: 'center',
        fontSize: '0.75rem',
        marginLeft: theme.spacing(2.5),
        borderBottom: '3px solid transparent',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(1),
        },
    },

    highlightedMenu: {
        color: '#b5b5b5',
        alignItems: 'center',
        background: "transparent",
        fontSize: '0.75rem',
        marginLeft: theme.spacing(2.5),
        borderBottom: '3px solid #c8102e',
        borderRadius: '0',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(1),
        },
    },


}));

const HeaderBar = (props) => {
    const classes = useStyles();
    const [drawer, showDrawer] = useState(false);
    const [selectedTab, setSelectedTabs] = useState("Home");
    const [showDialog, setOpenDialog] = useState(false);
    const { allTeams, allEditions } = useContext(AppDataContext);


    let userData = {};
    if (localStorage.getItem("UserDetail") !== null && localStorage.getItem("UserDetail") !== undefined) {
        userData = JSON.parse(localStorage.getItem("UserDetail"));
    }
    const [fName, setUserFirstName] = useState((localStorage.getItem("UserDetail") !== null && localStorage.getItem("UserDetail") !== undefined) ? userData.firstName : "");
    const [lName, setUserLastName] = useState((localStorage.getItem("UserDetail") !== null && localStorage.getItem("UserDetail") !== undefined) ? userData.lastName : "");
    const [isUserLoggedin, setUserLoggedin] = useState((localStorage.getItem("UserToken") !== null && localStorage.getItem("UserToken") !== undefined) ? true : false);

    useEffect(() => {
        if (sessionStorage.getItem("selectedTab") !== null && sessionStorage.getItem("selectedTab") !== undefined)
            setSelectedTabs(sessionStorage.getItem("selectedTab"));

    }, []);

    useEffect(() => {
        setHighlightedMenu(props.activeTab);
    }, [props.activeTab]);

    const pushToRoute = (param) => {

        let byTeam = param === "team";
        let byEdition = param === "edition";
        let index = byTeam ? parseInt(sessionStorage.getItem("activeTileTeam")) : parseInt(sessionStorage.getItem("activeTileEdition"));
        if (byTeam) {
            let teams = [];
            let FavTeamId = "";
            if (localStorage.getItem("UserDetail") !== null && localStorage.getItem("UserDetail") !== undefined) {
                let userData = JSON.parse(localStorage.getItem("UserDetail"));
                FavTeamId = ((localStorage.getItem("UserDetail") !== null && localStorage.getItem("UserDetail") !== undefined && userData.favoriteTeams && userData.favoriteTeams.length > 0) ? userData.favoriteTeams[0] : "");
                teams = allTeams.filter(function (el, k) {
                    return parseInt(el.teamId) == parseInt(FavTeamId);
                });
                sessionStorage.removeItem("activeTileTeam");
            } else {
                if (index != undefined && index != null && !isNaN(index)) {
                    sessionStorage.setItem("activeTileTeam", index);
                } else {
                    index = 0;
                    sessionStorage.setItem("activeTileTeam", 0);
                }
                teams = allTeams.filter(function (el, k) {
                    return k == index;
                });
            }
            let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";
            if (teamSEOName !== "" && teamSEOName !== null) {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_TEAM + "/" + teamSEOName,
                    state: { team: byTeam, edition: byEdition, teamName: teamSEOName }
                });
            } else {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_TEAM + "/atlanta-hawks",
                    state: { team: byTeam, edition: byEdition, teamName: "atlanta-hawks" }
                });
            }
        } else {
            let editions = [];

            if (index != undefined && index != null && !isNaN(index)) {
                sessionStorage.setItem("activeTileEdition", index);
            } else {
                index = 0;
                sessionStorage.setItem("activeTileEdition", 0);
            }
            editions = allEditions.filter(function (el, k) {
                return k == index;
            });
            let editionSEOName = (editions !== undefined && editions.length > 0) ? editions[0].editioN_NAME_SEO : "";

            if (editionSEOName !== "" && editionSEOName !== null) {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_EDITION + "/" + editionSEOName,
                    state: { team: byTeam, edition: byEdition, editionName: editionSEOName }
                });
            } else {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_EDITION + "/association-edition",
                    state: { team: byTeam, edition: byEdition, editionName: "association-edition" }
                });
            }
        }
    }

    const setHighlightedMenu = (param) => {

        if (param !== undefined && param === "schedule") {
            setSelectedTabs("Schedule");
            sessionStorage.setItem("selectedTab", "Schedule");
        } else if (param !== undefined && param === "team") {
            setSelectedTabs("Team");
            sessionStorage.setItem("selectedTab", "Team");
        } else if (param !== undefined && param === "edition") {
            setSelectedTabs("Edition");
            sessionStorage.setItem("selectedTab", "Edition");
            // }else if(param !== undefined && param === "trending"){
            //     setSelectedTabs("Trending");
            //     sessionStorage.setItem("selectedTab", "Trending");
        } else if (param !== undefined && param === "about") {
            setSelectedTabs("About");
            sessionStorage.setItem("selectedTab", "About");
        } else {
            setSelectedTabs("Home");
            sessionStorage.setItem("selectedTab", "Home");
        }
    }

    const pushToAboutusRoute = (param) => {
        props.history.push({
            pathname: RouteConstants.ABOUT_US
        });
    }

    const pushToScheuleRoute = (param) => {
        props.history.push({
            pathname: RouteConstants.SCHEDULE
            //state: { team: byTeam, edition: byEdition }
        });
    }

    const pushToHome = (param) => {
        props.history.push({
            pathname: RouteConstants.HOME
            //state: { team: byTeam, edition: byEdition }
        });
    }

    const openSignInWidget = () => {
        const mylib = window.UVWidget;
        const myWidget = mylib.Login.new({
            modal: AppConstants.WIDGET_MODEL,
            apiKey: AppConstants.WIDGET_API_KEY,
            appId: AppConstants.WIDGET_API_ID,
            env: AppConstants.WIDGET_ENV,
            cssStyle: {
                LogIn: {
                    styleComponent: "SignupComponent",
                    styleImage: 'uvLoginImage',
                    styleDialogCloseButton: 'uvLoginCloseButton',
                    styleLink: 'uvLoginLink',
                    styleButton: "signupBtn",
                    styleSubContent: "SignUpSubContent"
                },
                SignUp: {
                    styleComponent: "SignupComponent",
                    styleDialogCloseButton: 'uvLoginCloseButton',
                    styleButton: "signupBtn",
                    styleSubContent: "SignUpSubContent"
                },
                ForgotPassword: {
                    styleComponent: "SignupComponent",
                    styleDialogCloseButton: 'uvLoginCloseButton',
                    styleButton: "signupBtn"
                },

            }
        });
        myWidget.Show();

        myWidget.On("onSignUpSuccess", function (response) {
            // console.log("onSignUpSuccess", response)
            if (response !== undefined && response.status !== undefined) {
                setUserLoggedin(true);
                setUserFirstName(response.data.userDetails.firstName);
                setUserLastName(response.data.userDetails.lastName);
                localStorage.setItem("UserToken", response.data.token);
                localStorage.setItem("UserResetToken", response.data.resetToken);
                localStorage.setItem("UserDetail", JSON.stringify(response.data.userDetails));
                //window.location.reload();

            }
        });
        myWidget.On("onLoginSuccess", function (response) {
            // console.log("onLoginSuccess", response)
            if (response !== undefined && response.status !== undefined) {
                setUserLoggedin(true);
                setUserFirstName(response.data.userDetails.firstName);
                setUserLastName(response.data.userDetails.lastName);
                localStorage.setItem("UserToken", response.data.token);
                localStorage.setItem("UserResetToken", response.data.resetToken);
                localStorage.setItem("UserDetail", JSON.stringify(response.data.userDetails));
                window.location.reload();

            }
        });
        // myWidget.On("onLoginFailure", function (response) {
        //     console.log("onLoginFailure",response)
        // })
    }

    const openProfileWidget = () => {
        const mylib = window.UVWidget;
        const myWidget = mylib.UpdateProfile.new({
            modal: AppConstants.WIDGET_MODEL,
            apiKey: AppConstants.WIDGET_API_KEY,
            appId: AppConstants.WIDGET_API_ID,
            env: AppConstants.WIDGET_ENV,
            //token: localStorage.getItem("UserToken"),
            cssStyle: {
                UpdateProfile: {
                    styleComponent: "SignupComponent",
                    styleDialogCloseButton: 'uvLoginCloseButton',
                    styleButton: "signupBtn",
                    styleSubContent: "SignUpSubContent"
                },

            }
        });
        myWidget.Show();
        myWidget.On("onUpdateProfileSuccess", function (response) {
            //console.log("onUpdateProfileSuccess", response);
            if (response !== undefined && response.status !== undefined) {
                // setUserLoggedin(true);
                // setUserFirstName(response.data.userDetails.firstName);
                // setUserLastName(response.data.userDetails.lastName);
                // localStorage.setItem("UserToken", response.data.token);
                // localStorage.setItem("UserResetToken", response.data.resetToken);
                // localStorage.setItem("UserDetail", JSON.stringify(response.data.userDetails));
            }
        });

    }

    const openChangePasswordWidget = () => {
        //console.log("Local Staorage", localStorage.getItem("UserToken"));
        const mylib = window.UVWidget;
        const myWidget = mylib.ChangePassword.new({
            modal: AppConstants.WIDGET_MODEL,
            apiKey: AppConstants.WIDGET_API_KEY,
            appId: AppConstants.WIDGET_API_ID,
            env: AppConstants.WIDGET_ENV,
            token: localStorage.getItem("UserToken"),

            cssStyle: {
                ChangePassword: {
                    styleComponent: "SignupComponent",
                    styleDialogCloseButton: 'uvLoginCloseButton',
                    styleButton: "signupBtn"
                },

            }
        });
        myWidget.Show();
        myWidget.On("onChangePasswordSuccess", function (response) {
            console.log("onChangePasswordSuccess", response.message)
            if (response !== undefined && response.status !== undefined) {
                //alert(response.message);
            }
        });
    }

    const signOut = () => {

        setUserLoggedin(false);
        setUserFirstName("");
        setUserLastName("");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserResetToken");
        localStorage.removeItem("UserDetail");

        sessionStorage.setItem(AppConstants.START_DATE_FILTER, '');
        sessionStorage.setItem(AppConstants.END_DATE_FILTER, '');
        sessionStorage.setItem(AppConstants.GAME_TYPE_FILTER, AppConstants.ALL_GAMES);
        sessionStorage.setItem(AppConstants.TEAM_FILTER, AppConstants.ALL_TEAMS);
        props.history.push("/");
    }

    return (
        <div>
            <AppBar position="fixed" className={classes.AppBar}>
                <Toolbar>
                    <NBALogo onClick={pushToHome} />
                    <Box component="div" display="flex" flexDirection="column" className={classes.title}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography variant="h3" component="h3" onClick={pushToHome}>
                                {AppConstants.APP_NAME}
                            </Typography>
                            <Typography variant="h6" component="h6" className={'headerSubTitle'} onClick={pushToHome}>
                                Every Locker, Every Player, Every Game.
                            </Typography>
                        </Box>
                        {/*{process.env.REACT_APP_SHOW_SPONSORS == true && <Typography variant="caption" component="div" className={classes.presentedBy}>*/}
                        {/*    Presented By <img src={presentedBy} alt="NBA LockerVision" title="NBA LockerVision" />*/}
                        {/*</Typography>}*/}
                    </Box>

                    <React.Fragment>
                        <Drawer anchor={'right'} open={drawer} className={classes.drawer}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            onClose={() => {
                                showDrawer(false);
                            }}>

                            <Button color="inherit" className={(selectedTab === "Home") ? classes.highlightedMenu : classes.menuLabel} onClick={(e) => {
                                e.preventDefault();
                                showDrawer(false);
                                pushToHome();
                            }}>Home</Button>

                            <Button color="inherit" className={(selectedTab === "Schedule") ? classes.highlightedMenu : classes.menuLabel} onClick={(e) => {
                                e.preventDefault();
                                showDrawer(false);
                                pushToScheuleRoute();
                            }}>Schedule</Button>

                            <Button color="inherit" className={(selectedTab === "Team") ? classes.highlightedMenu : classes.menuLabel} onClick={(e) => {
                                e.preventDefault();
                                showDrawer(false);
                                pushToRoute("team");
                            }}>Teams</Button>

                            <Button color="inherit" className={(selectedTab === "Edition") ? classes.highlightedMenu : classes.menuLabel} onClick={(e) => {
                                e.preventDefault();
                                showDrawer(false);
                                pushToRoute("edition");
                            }}>Editions</Button>

                            <Button color="inherit" className={(selectedTab === "About") ? classes.highlightedMenu : classes.menuLabel} onClick={(e) => {
                                e.preventDefault();
                                showDrawer(false);
                                pushToAboutusRoute();
                            }}>About us</Button>

                            {/* <Button color="inherit" className={(selectedTab === "Trending") ? classes.highlightedMenu: classes.menuLabel} onClick={(e) => { 
                                e.preventDefault(); 
                                setHighlightedMenu("trending"); 
                            }}> Trending</Button> */}

                        </Drawer>
                    </React.Fragment>

                    <Hidden mdUp>
                        {!isUserLoggedin && <Box>
                            <Button color="inherit" className={classes.menuLabel} onClick={(e) => {
                                e.preventDefault();
                                openSignInWidget();
                            }}>Login</Button>
                        </Box>
                        }
                        {isUserLoggedin && <Box alignItems="center" display="flex" ml={1}>
                            <AppBarMenu openProfileWidget={(e) => {
                                openProfileWidget()
                            }} signOut={(e) => {
                                signOut()
                            }} openChangePasswordWidget={(e) => {
                                openChangePasswordWidget()
                            }} firstName={fName} lastName={lName} />
                        </Box>}
                        <MenuIcon onClick={() => {
                            showDrawer(true);
                        }} />
                    </Hidden>

                    <Hidden smDown>
                        <Box display="flex" height="38px" >
                            <Button color="inherit" className={(selectedTab === "Home") ? classes.highlightedMenu : classes.menuLabel} onClick={(e) => {
                                e.preventDefault();
                                pushToHome();
                                setHighlightedMenu("home");
                            }}>Home</Button>

                            { } <Button color="inherit" className={(selectedTab === "Schedule") ? classes.highlightedMenu : classes.menuLabel} onClick={(e) => {
                                e.preventDefault();
                                pushToScheuleRoute();
                                setHighlightedMenu("schedule");
                            }}>Schedule</Button>

                            <Button color="inherit" className={(selectedTab === "Team") ? classes.highlightedMenu : classes.menuLabel} onClick={(e) => {
                                e.preventDefault();
                                pushToRoute("team");
                                setHighlightedMenu("team");
                            }}>Teams</Button>

                            <Button color="inherit" className={(selectedTab === "Edition") ? classes.highlightedMenu : classes.menuLabel} onClick={(e) => {
                                e.preventDefault();
                                pushToRoute("edition");
                                setHighlightedMenu("edition");
                            }}>Editions</Button>

                            <Button color="inherit" className={(selectedTab === "About") ? classes.highlightedMenu : classes.menuLabel} onClick={(e) => {
                                e.preventDefault();
                                pushToAboutusRoute();
                                setHighlightedMenu("about");
                            }}>About us</Button>

                            {/* <Button color="inherit" className={(selectedTab === "Trending") ? classes.highlightedMenu: classes.menuLabel} onClick={(e) => { 
                                e.preventDefault(); 
                                setHighlightedMenu("trending"); 
                            }}> Trending</Button> */}

                            {!isUserLoggedin &&
                                <Button color="inherit" className={classes.menuLabel} onClick={(e) => {
                                    e.preventDefault();
                                    openSignInWidget();
                                }}>Login</Button>

                            }
                            {isUserLoggedin && <Box>
                                <AppBarMenu openProfileWidget={(e) => {
                                    openProfileWidget()
                                }} signOut={(e) => {
                                    signOut()
                                }} openChangePasswordWidget={(e) => {
                                    openChangePasswordWidget()
                                }} firstName={fName} lastName={lName} />
                            </Box>}
                        </Box>
                    </Hidden>

                    {showDialog && <DialogBox></DialogBox>}

                </Toolbar>
            </AppBar>
        </div>
    )
}
export default withRouter(HeaderBar);