const APIURLConstants = {
    PING: '/Ping',
    SIGNOUT: '/SignOut',
    GET_APP_DETAILS: '/GetAppDetails',
    GET_TEAMS: '/GetTeamList',
    GET_GAME : '/GetGames',
    GET_CALENDER_DATA: '/GetCalenderData',
    GET_SPONSORS_ADS: '/GetSponserAds',
    GAMES_LIST: '/SearchGames',
    GET_CLANEDER_DATES: '/GetCalenderDates',
    GET_UNIFORM_DETAIL: '/GetUniformDetails',
    GET_INSEASON_GAME_PIC: '/GetGamePicks',
    GET_TRENDING_OUTFIT: '/GetTrendingOutfits',
    GET_LOCKER_BY_TEAM:'/GetTeamLocker',
    GET_EDITION_LIST:'/GetEditionList',
    GET_EDITION_LOCKER:'GetEditionLocker',
    GET_UPCOMING_GAMES: '/GetUpcomingGames',
    GET_FILTERRED_GAMES: '/FilterGames',
    GET_VALID_KEY: '/GetValidKey',
    GET_UNIFORM_DETAILS_BY_TEAM_EDITION:'/GetUniformDetailsByTeamEdition',
    GET_TRANSFORMATION_DATA: '/GetTransformationData',
    GET_ALLSTAR_EVENTS: '/GetAllStarEvents',
    GET_ALL_STAR_UNIFORM_DETAILS: '/GetASUniformDetails',
    GET_RISING_STAR_UNIFORM_DETAILS: '/GetRSUniformDetails',
}

export default APIURLConstants;