import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from 'react-router-dom';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { CardActionArea, CardActions, Box, Button } from '@material-ui/core';
import Helper from '../../common/Helper';
import SocialMediaContainer from '../../sharedComponents/SocialMedia/SocialMediaContainer';
import SponsorAd from '../../sharedComponents/SponsorContainer/SponsorAd';
import AppDataContext from '../../common/AppContext';
import RouteConstants from '../../common/RouteConstants';
import AppConstants from '../../common/AppConstants';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(5),
    },

    mainContainer: {
        background: 'rgba(0, 0, 0, .65) !important',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(0),
    },

    cardRoot: {
        background: 'transparent !important',
        border: 'none',
        boxShadow: 'none',
        height: '100%',
        position: 'relative',
        paddingTop: theme.spacing(1),
    },
    cardRootHat: {
        background: 'transparent !important',
        border: 'none',
        boxShadow: 'none',
        height: '100%',
        position: 'relative',
        paddingTop: theme.spacing(1),
        '& .MuiCardActionArea-root': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
    },
    chip: {
        fontSize: '.63rem',
        letterSpacing: '0.0500em',
        color: '#8f8f8f',
        borderColor: '#8f8f8f !important',
        margin: theme.spacing(.5, .5, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        '& .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1, 0, 1)
            },
        },
    },
    backchip: {
        width: '50px',
        fontSize: '.63rem',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.5, .5, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        '& .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1, 0, 1)
            },
        },
    },
    cardAction: {
        position: 'absolute',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'space-between',
        left: '0',
        right: '0',
        top: '0',
        '& a, & a:hover, & a:focus': {
            color: '#e5dfcd',
        },
    },
    customChip: {
        position: 'absolute',
        zIndex: '1',
        top: '0',
        width: '100%',
        '& .MuiChip-root': {
            height: 'auto',
            padding: '2px 0',
            fontSize: '.78rem',
            fontWeight: '500',
            border: '1px solid #b5b5b5',
            '& .MuiChip-label': {
                paddingLeft: theme.spacing(.75),
                paddingRight: theme.spacing(.75),
            },
        },
    },
    cardMedia: {
        display: 'flex',
        background: '#000000',
        border: '1px solid #888888',
        padding: '0',
        cursor: 'pointer',

        borderRadius: theme.spacing(.5),
        '& img': {
            height: '100%',
            width: '100%',
            borderRadius: theme.spacing(.5),
        },
    },
    cardMediaHat: {
        display: 'flex',
        background: '#000000',
        border: '1px solid #888888',
        padding: '0',
        borderRadius: theme.spacing(.5),
        height: '100%',
        '& img': {
            height: '100%',
            width: '100%',
            borderRadius: theme.spacing(.5),
            objectFit: 'contain',
        },
    },
    outfitText: {
        color: '#b5b5b5',
        textTransform: 'uppercase',
    },
    teamIcons: {
        maxWidth: '85px',
    },
    title: {
        flexGrow: 1,
        '& .MuiChip-clickable': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        '& > div:nth-child(2)': {
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
            },
        },
        '& h5': {
            fontFamily: '"Action NBA  Web"',
            color: '#b5b5b5',
        },
        '& p': {
            color: '#b5b5b5',
        },
    },
    backDashboard: {
        border: '1px solid #fff',
        color: '#fff',
        fontWeight: '300',
        fontSize: '.63rem',
        marginTop: '5px',
    },
}));

function GameContainer(props) {
    const classes = useStyles();
    const Games = props.games;
    const [gameCnt, setGamesCnt] = useState(0);
    const { allTeams, allEditions, AssetFactory } = useContext(AppDataContext);

    useEffect(() => {
        if (props.games.length > 0) {
            var gameCntarr = props.games.filter(function (el) {
                return !el.isSponserAd;
            });
            setGamesCnt(gameCntarr.length);
        } else {
            setGamesCnt(0);
        }
    }, [props.games])

    const pushToOutfitDetail = (gameId, teamId, teamName) => {

        let teams = allTeams.filter(function (el) {
            return el.teamName == teamName;
        });

        let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";
        props.history.push({
            pathname: `${RouteConstants.GAME_OUTFIT}/${teamSEOName}?gameid=${gameId}`,
            state: { outfitGameId: gameId, outfitTeamId: teamId }
        })

    }
    const pushToOutfitPreview = (editionName, teamName) => {

        let teams = allTeams.filter(function (el) {
            return el.teamName == teamName;
        });
        let editions = allEditions.filter(function (el) {
            return el.editioN_NAME == editionName;
        });

        let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";
        let editionSEOName = (editions !== undefined && editions.length > 0) ? editions[0].editioN_NAME_SEO : "";
        props.history.push({
            pathname: `${RouteConstants.OUTFIT}/${teamSEOName}/${editionSEOName}`,
            state: { teamName: teamSEOName, editionName: editionSEOName, type: props.type, teamid:props.selectedTeamId, editionid: props.selectedEditionId }
        })

    }
    const cardType = props.type;
    return (
        <Container maxWidth="false">
            <Grid container className={classes.mainContainer} spacing={5}>
                {Games !== undefined && Games.length > 0 && Games.map((item, index) => {
                    if (!item.isSponserAd) {
                        return (
                            <Grid item xs={6} sm={4} md={3}>
                                <Card className={classes.cardRoot}>
                                    {cardType === "team" ?
                                        <Box display="flex" justifyContent="center" className={classes.customChip}>
                                            <Chip label={item.editionName}
                                                style={Helper.checkForComingSoon(item.uniformImg) ? { background: "black", color: "white" } : { background: item.bgColor, color: item.textColor }} />
                                        </Box> : <Box display="flex" justifyContent="center" className={classes.customChip}>
                                            <Chip label={item.teamName}
                                                style={Helper.checkForComingSoon(item.uniformImg) ? { background: "black", color: "white" } : { background: item.bgColor, color: item.textColor }} />
                                        </Box>}
                                    {/* <CardActionArea> */}
                                    <Box component="span" display="flex" p={1} className={classes.cardAction}>
                                        <Link>
                                            <SocialMediaContainer
                                                name="Uniform Edition"
                                                teamName={item.teamName}
                                                date={Helper.FormatDayWithDate(item.gameDate)}
                                                img={item.uniformImg}
                                                editionname={item.editionName}
                                                key={item.editionName}
                                            />
                                        </Link>
                                        {/* <Link>
                                                <FavoriteBorderIcon onClick={(e)=>{
                                                    e.preventDefault();
                                                    return;
                                                }}/>
                                            </Link> */}
                                    </Box>
                                    <CardMedia
                                        className={classes.cardMedia}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            pushToOutfitPreview(item.editionName, item.teamName);
                                        }}
                                    >
                                        <img src={Helper.getAssetType(item.uniformImg, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY))} title={`NBA LockerVision ${item.teamName} (${item.editionName})`} alt={`NBA LockerVision ${item.teamName} (${item.editionName})`} />
                                    </CardMedia>
                                    <CardContent>
                                        {/* <Typography variant="body2" component="p" align="center" className={classes.outfitText}>
                                                UNIFORM EDITION
                                                </Typography> */}
                                    </CardContent>
                                    {/* </CardActionArea> */}
                                </Card>

                            </Grid>
                        );
                    } else {
                        //if (item.sponserAd !== undefined && AppConstants.DISPLAY_SPONSORS) {
                        //    return (
                        //        <SponsorAd
                        //            from="Card"
                        //            sponsorsAdSource={item.sponserAd.adSource}
                        //            sponsorsAdLink={item.sponserAd.adLink} />
                        //    );
                        //}
                    }
                })}
            </Grid>
        </Container>
    );
}
export default withRouter(GameContainer);