import React, { Component } from 'react'
import ReactLightCalendar from '@lls/react-light-calendar'
import '@lls/react-light-calendar/dist/index.css'
import AppConstants from '../../common/AppConstants';
import Helper from '../../common/Helper';
import MUIDatePicker from '../../sharedComponents/MUIDatePicker';
import Box from '@material-ui/core/Box';
const DAY_LABELS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const MONTH_LABELS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

class ScheduleDateRange extends Component {

    constructor(props) {
        super(props);
        let scheduleStartDate = sessionStorage.getItem(AppConstants.START_DATE_FILTER);
         
        if (scheduleStartDate === '') {
            //On initial display, if the current date is less than seasons start date set the
            //start date of the season as the selected start date
            if (new Date().getTime() < new Date(props.calenderMinDate).getTime()) {
                scheduleStartDate = Helper.FormatDateMMDDYYYYWithoutTime(props.calenderMinDate);
            }
            else {
            //On initial display, if the current date is with in the season date range
            //set todays date as the selected start date.
                scheduleStartDate = Helper.FormatDateMMDDYYYYWithoutTime(new Date());
            }
            
            sessionStorage.setItem(AppConstants.START_DATE_FILTER, scheduleStartDate);
        }

        let scheduleEndDate = sessionStorage.getItem(AppConstants.END_DATE_FILTER);
        const displayEndDate = scheduleEndDate === '' ? '' : Helper.FormatDateMMDDYYYYWithoutTime(scheduleEndDate);

        this.state = {
            startDate: scheduleStartDate,
            endDate: displayEndDate,
            calendarMinAllowedDate: Helper.FormatDateMMDDYYYYWithoutTime(props.calenderMinDate),
            calendarMaxAllowedDate: Helper.FormatDateMMDDYYYYWithoutTime(props.calenderMaxDate),
            showDateValidationMessage: false,
            dateValidationMessage: ""
        }
    }

    handleFromDateChange = (e) => {
        //Validate start date
        let startDate = Helper.FormatDateMMDDYYYYWithoutTime(e.target.value);

        this.setState({ startDate });
        if (startDate !== '') {
            sessionStorage.setItem(AppConstants.START_DATE_FILTER,
                Helper.FormatDateMMDDYYYYWithoutTime(startDate));
        }
        else {
            sessionStorage.setItem(AppConstants.START_DATE_FILTER, '');
        }

    }

    handleToDateChange = (e) => {
        //Validate end date
        if (e.target.value && !Helper.IsValidDate(e.target.value)) {
            console.log('End date is not valid.');
            return;
        }
        
        let endDate = Helper.FormatDateMMDDYYYYWithoutTime(e.target.value);
        //Validate end date
        let startDateToCompare = new Date(this.state.startDate);    
        let endDateToCompare = new Date(endDate);    
        if (startDateToCompare.getFullYear() === endDateToCompare.getFullYear() &&
            startDateToCompare.getMonth() === endDateToCompare.getMonth() &&
            startDateToCompare.getDate() === endDateToCompare.getDate()) {
            
            //If start date is equal to end date set only start date to consider it as 
            //a single day.
            sessionStorage.setItem(AppConstants.END_DATE_FILTER, "");
        }
        else {
            //When user selects the start date, 'current date' is set as end date by default. 
            //If the current date is disabled because its out of season's date range then set the last date of the 
            //season as the end date.

            // console.log('calendarMaxAllowedDate: ', this.state.calendarMaxAllowedDate);
            if (new Date(endDate).getTime() >
                Helper.FormatDateMMDDYYYYWithoutTime(this.state.calendarMaxAllowedDate)) {
                //endDate = this.state.calendarMaxAllowedDate.getTime();
                let maxDate = Helper.FormatDateMMDDYYYYWithoutTime(this.state.calendarMaxAllowedDate);
                sessionStorage.setItem(AppConstants.END_DATE_FILTER, maxDate);
                this.setState({ endDate: maxDate});
            }
            else {                
                sessionStorage.setItem(AppConstants.END_DATE_FILTER, !endDate ? "" : Helper.FormatDateMMDDYYYYWithoutTime(endDate));
                this.setState({ endDate });
            }
        }
    }

    render = () => {
        const { startDate, endDate, calendarMinAllowedDate, calendarMaxAllowedDate } = this.state
        return (
            <Box display="flex" flexDirection="column">
                <Box>
                    <MUIDatePicker
                        id="filterStartDate"
                        label="Start Date"
                        date={this.state.startDate}
                        onDateChange={this.handleFromDateChange} />
                </Box>
                <Box>
                    <MUIDatePicker
                        id="filterEndDate"
                        label="End Date"
                        date={this.state.endDate}
                        onDateChange={this.handleToDateChange} />
                </Box>
            </Box>
        )
    }
}

export default ScheduleDateRange;