import React, { useState, useEffect, useContext } from "react";

import { GetAppDetails } from "../services/auth.service";
import {
    GetTeamList,
    GetEditionList,
    GetCalenderData,
    GetCalenderDates,
    GetTransformationData,
    GetSponsorAds,
    GetStarGames
} from "../services/home.service";

export const AppDataContext = React.createContext({
    Calender: [{
        "OptGroup": "Week",
        "Options": [
            {
                "Title": "Week 1",
                "OptCode": "Week 1"
            },
            {
                "Title": "Week 2",
                "OptCode": "Week 2"
            },
            {
                "Title": "Week 3",
                "OptCode": "Week 3"
            },
            {
                "Title": "Week 4",
                "OptCode": "Week 4"
            }]
    },
    {
        "OptGroup": "Month",
        "Options": [
            {
                "Title": "December",
                "OptCode": "December"
            },
            {
                "Title": "January",
                "OptCode": "January"
            },
            {
                "Title": "February",
                "OptCode": "February"
            },
            {
                "Title": "March",
                "OptCode": "March"
            }]
    },
    {
        "OptGroup": "Weekday",
        "Options": [
            {
                "Title": "Monday",
                "OptCode": "Monday"
            },
            {
                "Title": "Tuesday",
                "OptCode": "Tuesday"
            },
            {
                "Title": "Wednesday",
                "OptCode": "Wednesday"
            },
            {
                "Title": "Thursday",
                "OptCode": "Thursday"
            },
            {
                "Title": "Friday",
                "OptCode": "Friday"
            },
            {
                "Title": "Saturday",
                "OptCode": "Saturday"
            }]
    }],
    Games: [
        {
            "id": "xyz0",
            "Month": "April",
            "Year": "2021",
            "Day": "9",
            "WeekDay": "Friday",
            "GamesCount": 4,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz1",
            "Month": "April",
            "Year": "2021",
            "Day": "11",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz2",
            "Month": "April",
            "Year": "2021",
            "Day": "12",
            "WeekDay": "Monday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz33",
            "Month": "April",
            "Year": "2021",
            "Day": "13",
            "WeekDay": "Tuesday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz44",
            "Month": "April",
            "Year": "2021",
            "Day": "14",
            "WeekDay": "Wednesday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz55",
            "Month": "April",
            "Year": "2021",
            "Day": "15",
            "WeekDay": "Thrusday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz6",
            "Month": "April",
            "Year": "2021",
            "Day": "16",
            "WeekDay": "Friday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz22",
            "Month": "April",
            "Year": "2021",
            "Day": "17",
            "WeekDay": "Saturday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz3",
            "Month": "April",
            "Year": "2021",
            "Day": "18",
            "WeekDay": "Saturday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz4",
            "Month": "April",
            "Year": "2021",
            "Day": "19",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz5",
            "Month": "April",
            "Year": "2021",
            "Day": "20",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz66",
            "Month": "April",
            "Year": "2021",
            "Day": "21",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz7",
            "Month": "April",
            "Year": "2021",
            "Day": "22",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz8",
            "Month": "April",
            "Year": "2021",
            "Day": "23",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz9",
            "Month": "April",
            "Year": "2021",
            "Day": "24",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz10",
            "Month": "April",
            "Year": "2021",
            "Day": "25",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz11",
            "Month": "April",
            "Year": "2021",
            "Day": "26",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz12",
            "Month": "April",
            "Year": "2021",
            "Day": "27",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz13",
            "Month": "April",
            "Year": "2021",
            "Day": "28",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz14",
            "Month": "April",
            "Year": "2021",
            "Day": "29",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        },
        {
            "id": "xyz15",
            "Month": "April",
            "Year": "2021",
            "Day": "30",
            "WeekDay": "Sunday",
            "GamesCount": 5,
            "Games": [
                {
                    "GameId": "0022000790",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "NEW ORLEANS PELICANS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#fff",
                    "HomeTeamTextColor": "#000",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/NOP.svg",
                    "Arena": "Smoothie Center",
                    "City": "New Orleans",
                    "AwayTeam": "PHILADELPHIA 76ERS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#000",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000791",
                    "GameTime": "08:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "MILWAUKEE BUCKS",
                    "HomeTeamEdition": "STATEMENT",
                    "HomeTeamBGColor": "#000",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/MIL.svg",
                    "Arena": "Fiserv Forum",
                    "City": "Milwaukee",
                    "AwayTeam": "CHARLOTTE HORNETS",
                    "AwayTeamEdition": "City",
                    "AwayTeamBGColor": "#7ACCC1",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/CHA.svg"
                },
                {
                    "GameId": "0022000792",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "DENVER NUGGETS",
                    "HomeTeamEdition": "ICON",
                    "HomeTeamBGColor": "#0a2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/DEN.svg",
                    "Arena": "Ball Arena",
                    "City": "Denver",
                    "AwayTeam": "SAN ANTONIO SPURS",
                    "AwayTeamEdition": "ASSOCIATION",
                    "AwayTeamBGColor": "#fff",
                    "AwayTeamTextColor": "#000",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/SAS.svg"
                },
                {
                    "GameId": "0022000793",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                },
                {
                    "GameId": "0022000794",
                    "GameTime": "07:00 PM EST",
                    "Season": "Regular Season",
                    "HomeTeam": "GOLDEN STATE WARRIORS",
                    "HomeTeamEdition": "CITY",
                    "HomeTeamBGColor": "#0A2240",
                    "HomeTeamTextColor": "#fff",
                    "HomeTeamOutfitLink": "",
                    "HomeTeamShopLink": "",
                    "HomeTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/GSW.svg",
                    "Arena": "Chase Center",
                    "City": "San Francisco",
                    "AwayTeam": "WASHINGTON WIZARDS",
                    "AwayTeamEdition": "CITY",
                    "AwayTeamBGColor": "#9EA1A2",
                    "AwayTeamTextColor": "#fff",
                    "AwayTeamOutfitLink": "",
                    "AwayTeamShopLink": "",
                    "AwayTeamLogoURL": "https://www.nba.com/assets/logos/teams/secondary/web/PHI.svg"
                }
            ]
        }
    ],
    GameTypes: [
        {
            "Title": "All Games",
            "GameTypeCode": "All Games"
        },
        {
            "Title": "Home Games",
            "GameTypeCode": "Home Games"
        },
        {
            "Title": "Away Games",
            "GameTypeCode": "Away Games"
        }
    ]
});

export const AppDataProvider = (props) => {
    const [applicationVersion, setApplicationVersion] = useState('');
    const [username, setUsername] = useState('');
    const [userRole, setUserRole] = useState();
    const [signoutURL, setSignoutURL] = useState('');
    const [userPermissions, setUserPermissions] = useState({});
    const context = useContext(AppDataContext);
    const [Games, setGames] = useState(context.Games);
    const [Calendar, setCalendar] = useState(context.Calender);
    const [sponsorAds, setSponsorAds] = useState([]);
    const [sponsorAdsFetched, setSponsorAdsFetched] = useState(false);
    const [GameTypes, setGameTypes] = useState(context.GameTypes);
    const [allTeams, setAllTeams] = useState([]);
    const [allEditions, setAllEditions] = useState([]);
    const [allTeamsForMenu, setAllTeamsForMenu] = useState([]);
    const [CalendarDates, SetCalendarDates] = useState([]);
    const [calenderMinDate, setCalenderMinDate] = useState("");
    const [calenderMaxDate, setCalenderMaxDate] = useState("");
    const [AssetFactory, setAssetFactory] = useState([]);
    const [starGames, setStarGames] = useState([]);
    const [showStarGames, setShowStarGames] = useState(true);

    const providerValue = {
        applicationVersion,
        username,
        userRole,
        signoutURL,
        userPermissions,
        allTeams,
        allTeamsForMenu,
        allEditions,
        Games,
        Calendar,
        sponsorAds,
        sponsorAdsFetched,
        GameTypes,
        CalendarDates,
        calenderMinDate,
        calenderMaxDate,
        AssetFactory,
        GetSponsorAds: () => { getSponsorAds() },
        starGames,
        showStarGames,
        GetStarGames: () => { getStarGames() }
    };

    const checkInLocal = () => {
        let inLocal = false;
        if (localStorage.getItem("allTeams")
            && localStorage.getItem("allEditions")
            && localStorage.getItem("allTeamsForMenu")
            && localStorage.getItem("calendarMinDate")
            && localStorage.getItem("calendarMaxDate")) {
            inLocal = true;
        }
        return inLocal
    }

    const getAppDetails = () => {
        GetAppDetails()
            .then(data => {
                //check whether app version exists in local storage
                let cachedAppVersion = localStorage.getItem('ApplicationVersion');
                //If application version doesn't exists in local storage or if the local storage version 
                //is different than the fetched from backend, clear the local storage and set the data again.
                if (!cachedAppVersion || (cachedAppVersion !== data.applicationVersion)) {
                    localStorage.clear();
                    localStorage.setItem('ApplicationVersion', data.applicationVersion);
                }
                setApplicationVersion(data.applicationVersion);
                setUsername(data.userName);
                setUserRole(data.userRole);
                setUserPermissions(data.userPermissions);
                setSignoutURL(data.signoutURL);
            })
            .catch(err => { throw err; });
    }

    const getAllTeams = () => {
        GetTeamList()
            .then(data => {
                localStorage.setItem("allTeamsForMenu", JSON.stringify(data))
                setAllTeamsForMenu(data);
                // splicing 1st object as it is inappropriate coming from DB
                let temp = [...data]
                temp.splice(0, 1);
                localStorage.setItem("allTeams", JSON.stringify(temp))
                setAllTeams(temp);
            })
            .catch(err => { throw err; });
    }

    const getAllEditions = () => {
        GetEditionList()
            .then(data => {
                localStorage.setItem("allEditions", JSON.stringify(data))
                setAllEditions(data);
            })
            .catch(err => { throw err; });
    }

    const getTransformationData = () => {
        GetTransformationData()
            .then(data => {
                setAssetFactory(data);
            })
            .catch(err => { throw err; });
    }

    const getDateRange = () => {
        GetCalenderDates()
            .then(data => {
                SetCalendarDates(data)
                let minDate = "";
                let maxDate = "";
                if (data.length > 0) {
                    minDate = data[0].gameDate;
                    maxDate = data[data.length - 1].gameDate;
                }
                else {
                    minDate = data[0].gameDate;
                    maxDate = data[0].gameDate;
                }
                localStorage.setItem("calendarMinDate", minDate)
                localStorage.setItem("calendarMaxDate", maxDate)
                setCalenderMinDate(minDate);
                setCalenderMaxDate(maxDate);

            }).catch(err => {
                throw err;
            });
    }

    const getSponsorAds = () => {
        GetSponsorAds().then(data => {
            setSponsorAds(data);
            localStorage.setItem('SponsorAds', JSON.stringify(data));
            setSponsorAdsFetched(true);
        });
    }

    const getStarGames = () => {
        GetStarGames().then(games => {
            //console.log('Star Games:', games);
            setShowStarGames(games.showOnHomePage);
            setStarGames(games);
        });
    }

    useEffect(() => {        
        getSponsorAds();
        getStarGames();
        getTransformationData();

        if (!applicationVersion) {
            getAppDetails();
        }        

        if (applicationVersion) {
            if (!checkInLocal()) {
                getAllTeams();
                getAllEditions();
                getDateRange();
            } else {
                setAllTeams(JSON.parse(localStorage.getItem("allTeams")))
                setAllTeamsForMenu(JSON.parse(localStorage.getItem("allTeamsForMenu")))
                setAllEditions(JSON.parse(localStorage.getItem("allEditions")))
                setCalenderMinDate(localStorage.getItem("calendarMinDate"));
                setCalenderMaxDate(localStorage.getItem("calendarMaxDate"));
            }
        }


    }, [applicationVersion]);

    return (
        <AppDataContext.Provider value={providerValue}>
            {props.children}
        </AppDataContext.Provider>
    );
};

export const AppDataConsumer = AppDataContext.Consumer;

export default AppDataContext;