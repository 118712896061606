import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Container } from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DateTile from '../DateTile/DateTile';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "nowrap",
    },
    calenderSlider: {
        height: theme.spacing(20.5),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 3.5)
        },
        '& .slick-slider': {
            height: "100%"
        },
    }
}));

function SliderCalendar(props) {
    const classes = useStyles();
    const settings = {
        infinite: (props.isFilterApply) ? true : false,
        speed: 1000,
        slidesToShow: 11,
        slidesToScroll: 11,
        initialSlide: props.intialTiles,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 11,
                    slidesToScroll: 11,
                    infinite: (props.isFilterApply) ? true : false,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 1000,
                }
            },
            {
                breakpoint: 1198,
                settings: {
                    slidesToShow: 9,
                    slidesToScroll: 9,
                    infinite: (props.isFilterApply) ? true : false,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 1000,
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 9,
                    slidesToScroll: 9,
                    infinite: (props.isFilterApply) ? true : false,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 1000,
                }
            },
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 7,
                    infinite: (props.isFilterApply) ? true : false,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 1000,
                }
            },
            {
                breakpoint: 823,
                settings: {
                    infinite: (props.isFilterApply) ? true : false,
                    slidesToShow: 7,
                    slidesToScroll: 7,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 500,

                }
            },
            {
                breakpoint: 812,
                settings: {
                    infinite: (props.isFilterApply) ? true : false,
                    slidesToShow: 7,
                    slidesToScroll: 7,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 500,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    infinite: (props.isFilterApply) ? true : false,
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 720,
                settings: {
                    infinite: (props.isFilterApply) ? true : false,
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 667,
                settings: {
                    infinite: (props.isFilterApply) ? true : false,
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    infinite: (props.isFilterApply) ? true : false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 568,
                settings: {
                    infinite: (props.isFilterApply) ? true : false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 500,

                }
            },
            {
                breakpoint: 540,
                settings: {
                    infinite: (props.isFilterApply) ? true : false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: (props.isFilterApply) ? true : false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: props.intialTiles,
                    centerMode: false,
                    speed: 500,
                }
            }
        ]
    }

    return (
        <Container className={classes.calenderSlider} key={`xy${props.forceKey}z`}>
            {props.tilesDates !== undefined && props.tilesDates.length > 0 ?
                <Slider {...settings}>
                    {props.tilesDates.map((item, index) => {
                        return <div>
                            <DateTile data={item} key={item.gameDate} onClick={(data) => {
                                props.populateGames(item.gameDate, index);
                            }} isActive={props.ActiveState !== undefined && props.ActiveState.length > 0 ? props.ActiveState[index] : null} />
                        </div>
                    })}
                </Slider> : null
            }
        </Container>
    )
}
export default SliderCalendar;
