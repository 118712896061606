import React, { useState, useEffect, useContext } from "react";
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { List, ListItem, ListItemText } from "@material-ui/core";
import HelmetMetaData from "../../sharedComponents/SocialMedia/HelmetMetaDeta";

const useStyles = makeStyles(theme => ({

    mainContainer: {
        background: 'rgba(0, 0, 0, .65) !important',
        padding: theme.spacing(1.5),
        paddingLeft: "18px",
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
        color: 'white',
    },
    textColor: {
        color: '#888888',
    },
    headerText: {
        color: '#fff',
        fontFamily: '"Action NBA  Web" !important',
        fontSize: '1.8rem',
        fontWeight: '300',
        lineHeight: '1'
    },
    headerContainer: {
        color: '#ffffff !important',
        marginTop: theme.spacing(3.5),
        marginBottom: theme.spacing(3.5),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 3.5),
        },
    },
    MuiListPadding: {
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "6px"
    }

}));


const Aboutus = (props) => {
    const classes = useStyles();
    const keywords = process.env.REACT_APP_SEO_KEYWORDS_FOR_ABOUTUS_PAGE;
    const description = process.env.REACT_APP_SEO_DESCRIPTION_FOR_ABOUTUS_PAGE;
    useEffect(() => {
        window.scrollTo(0, 0);
        props.changeTabInHeader("about");
    })
    const HeaderText = (props) => {

        const classes = useStyles();
        return (
            <Grid item xs={12} className={classes.headerContainer}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="h4" component="h4" className={classes.headerText}><span>{props.text}</span>
                    </Typography>
                </Box>
            </Grid>
        )
    };

    return (
        <>
            <HelmetMetaData
                title={"NBA LockerVision - About Us"}
                quote={"Every Locker, Every Player, Every Game."}
                url={window.location.href}
                hashtag={"#nba"}
                description={description}
                keywords={keywords}
            ></HelmetMetaData>
            <Container maxWidth="false">
                <HeaderText text={"About Us"} onClick={() => { }} />
                <Grid container className={classes.mainContainer} spacing={5}>
                    <Typography variant="body1" component="p">
                        NBA LockerVision provides a look into the locker room of every NBA team, for every game and every player. It is the premier destination for NBA uniform schedules, design details, and on-court collections. What started out as an internal NBA scheduling platform, now provides fans with access to the most detailed information about what their favorite team is wearing on the court each night.
                    </Typography>
                </Grid>

                <HeaderText text={"FAQs"} onClick={() => { }} />
                <Grid container className={classes.mainContainer} spacing={5}>
                    <List>
                        <ListItemText><h3> How many uniforms does each NBA team have? </h3></ListItemText>
                        <ListItemText>NBA Teams can have up to five (5) uniforms per season. These uniforms are called Editions.  Association Edition, Icon Edition, and Statement Edition are known as Core Uniforms and are each on-court for multiple seasons. City Edition can change every year and is the 4th uniform in every team’s lineup. Classic Edition is worn by teams commemorating anniversaries or key moments in franchise history. </ListItemText>
                        <br />
                        <ListItemText><h3> Why don’t NBA teams wear white uniforms at Home? </h3></ListItemText>
                        <ListItemText>NBA uniforms are chosen on a home-team-decides basis. Teams use the NBA LockerVision platform to make their uniform selections for all Home games and the Road team then uses the NBA LockerVision platform to select a uniform that color-contrasts with the home team’s choice. </ListItemText>
                        <br />
                        <ListItemText><h3> Why are some City Edition uniforms different colors than the team’s primary colors? </h3></ListItemText>
                        <ListItemText>The City Edition uniform category allows Teams the color freedom to tell hyper-local stories in authentic ways. Teams can use any color to bring their City Edition theme to life, while still incorporating Team branding in other ways. </ListItemText>
                        <br />
                        <ListItemText><h3> Why are some NBA uniforms Jordan branded?</h3></ListItemText>
                        <ListItemText>All Statement Edition uniforms are Jordan branded, while the other Editions are Nike branded. Statement Edition represents the player-mindset and the Jordan Brand reflects that in a unique way.   The Charlotte Hornets Editions are all Jordan Brand</ListItemText>
                    </List>
                </Grid>
            </Container></>
    )
}
export default withRouter(Aboutus);