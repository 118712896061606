import React, { useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    slider: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin:"auto 0",
        [theme.breakpoints.up('sm')]: {
            maxWidth: "50vh",
            width:"auto"
          },
          [theme.breakpoints.up('md')]: {
            maxWidth: "50vh",
            width:"auto"
          },
    },
    slider__backarrow: {
        left: "-48px",
        transform: "rotate(180deg)",
        color:"white",
        position: "absolute",
        top: "50%",
        fontSize: "3rem",
        zIndex: "10",
        cursor: "pointer",
        userSelect: "none",
        [theme.breakpoints.down('xs')]: {
            left: "-24px",
            fontSize: "2rem",
          },
    },
    slider__forwardarrow: {
        right: "-48px",
        color:"white",
        position: "absolute",
        top: "50%",
        fontSize: "3rem",
        zIndex: 10,
        cursor: "pointer",
        userSelect: "none",
         [theme.breakpoints.down('xs')]: {
             right: "-24px",
             fontSize: "2rem",
           },
    },
    slider__image: {
        width: "100%",
        objectFit: "contain",
        transition: "transform 450ms",
    },
}));

const ImageSlider = ({ images }) => {
    const classes = useStyles(); 
  const [currValue, setCurrentValue] = useState(0);
  const imgLength = images.length;

  if (!Array.isArray(images) || images.length <= 0) {
    return null;
  }
  const prevSlide = () => {
    setCurrentValue(currValue === 0 ? imgLength - 1 : currValue - 1);
  };
  const nextSlide = () => {
    setCurrentValue(currValue === imgLength - 1 ? 0 : currValue + 1);
  };
  //console.log(currValue);
  return (
    <section className={classes.slider}>
      <ArrowForwardIosIcon className={classes.slider__backarrow} onClick={prevSlide} />
      <ArrowForwardIosIcon
        className={classes.slider__forwardarrow}
        onClick={nextSlide}
      />
      {images.map((i, index) => {
          return (
              <div key={index}>
                  {index === currValue && <img src={i} alt={i} className={classes.slider__image} />}
              </div>
          )
      })}
    </section>
  );
};

export default ImageSlider;
