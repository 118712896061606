import { GetRefHeader } from '../services/auth.service';
import Helper from '../common/Helper';

export function handleError(error) {
    return new Promise((resolve, reject) => {
        const errorState = error.response;
        if (errorState) {
            if ([401, 403].indexOf(errorState.status) !== -1) {
                // For expired key, 401 Unauthorized error will be returned.
                //Generate the key again.
                // Reload the page from which key validation was failed.
                Helper.GetRefHeader();
                window.location.reload(true);
            }
            const error = errorState && errorState.message;
            reject(error);
        }

        resolve(errorState);
    });
}