import React, { Component } from "react";
import Helper from '../../common/Helper';
import { withRouter } from 'react-router-dom';

import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    FacebookIcon,
    TwitterIcon,
    PinterestIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";
import HelmetMetaData from "./HelmetMetaDeta";

function SocialMediaShareUI(props) {

    const imageUrl = props.mediaFile;
    const url = props.shareURL;
    const content = props.content;
    // console.log(imageUrl)
    // console.log(url)
    return (
        <div>
            <FacebookShareButton
                quote={content}
                url={url}
                windowWidth={1000}
                windowHeight={730}
                data-gaeventname="product_share"
                data-gaeventcategory="Facebook Share"
                data-gaeventlabel={`${content}`}
                data-gaeventaction={`${url}`}
            >
                <FacebookIcon size="2.5rem" />
            </FacebookShareButton>

            <PinterestShareButton
                description={content}
                media={imageUrl}
                url={url}
                windowWidth={1000}
                windowHeight={730}
                data-gaeventname="product_share"
                data-gaeventcategory="Pinterest Share"
                data-gaeventlabel={`${content}`}
                data-gaeventaction={`${url}`}
            >
                <PinterestIcon size="2.5rem" />
            </PinterestShareButton>


            <TwitterShareButton
                title={content}
                url={url}
                // via={imageUrl}
                windowWidth={1000}
                windowHeight={730}
                data-gaeventname="product_share"
                data-gaeventcategory="Twitter Share"
                data-gaeventlabel={`${content}`}
                data-gaeventaction={`${url}`}
            >
                <TwitterIcon size="2.5rem" />
            </TwitterShareButton>
            <WhatsappShareButton
                title={content}
                url={url}
                windowWidth={1000}
                windowHeight={730}
                data-gaeventname="product_share"
                data-gaeventcategory="Whatsapp Share"
                data-gaeventlabel={`${content}`}
                data-gaeventaction={`${url}`}
            >
                <WhatsappIcon size="2.5rem" />
            </WhatsappShareButton>


        </div>
    );
}
export default withRouter(SocialMediaShareUI);