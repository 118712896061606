import React, { useState, useContext, useEffect } from 'react';
import AppDataContext from '../../common/AppContext';
import AppConstants from '../../common/AppConstants';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from 'react-router-dom';
import { CardActions } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import cardBgImage from '../../images/basketball.png';


const OutfitStyles = makeStyles((theme) => ({
    cardRoot: {
        background: 'rgba(11, 11, 11, .5)',
        border: '1px solid rgba(255, 255, 255, .3)',
        boxShadow: 'none',
        height: "calc(100% - 52px)",
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginTop: theme.spacing(1),
        borderRadius: theme.spacing(1.25),
        '& .MuiCardContent-root': {
            backgroundImage: `url(${cardBgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 100%',
        },
        '& .MuiCardHeader-avatar': {
            display: 'flex',
            marginRight: '0',
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        },
    },

    cardRootHat: {
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
        height: '100%',
        position: 'relative',
        paddingTop: theme.spacing(1),
        '& .MuiCardActionArea-root': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
    },
    cardAction: {
        position: 'absolute',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'flex-end',
        left: '0',
        right: '0',
        top: '0',
        '& a, & a:hover, & a:focus': {
            color: '#e5dfcd',
        },
    },
    cardMedia: {
        display: 'flex',
        background: '#000000',
        border: '1px solid #888888',
        padding: '0',
        borderRadius: theme.spacing(.5),
        height: '100%',
        '& img': {
            height: '100%',
            width: '100%',
            borderRadius: theme.spacing(.5),
            objectFit: 'contain',
        },
    },
    ad1: {
        minWidth: '728px',
        minHeight: '90px',
        //background: 'red',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    ad2: {
        minWidth: '320px',
        minHeight: '50px',
        background: 'yellow',
    },
    parentDiv: {
        display: 'flex',
        justifyContent: 'right',
        paddingTop: '5px',
        paddingBottom: '5px',
        marginRight: '0px'
    }
}));

const CardStyle = makeStyles((theme) => ({
    cardRoot: {
        background: 'rgba(11, 11, 11, .5)',
        border: '1px solid rgba(255, 255, 255, .3)',
        boxShadow: 'none',
        height: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: theme.spacing(1.25),
        '& .MuiCardContent-root': {
            backgroundImage: `url(${cardBgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 100%',
        },
        '& .MuiCardHeader-avatar': {
            display: 'flex',
            width: '100%',
        },
    },
    cardAction: {
        position: 'absolute',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'flex-end',
        left: '0',
        right: '0',
        top: '0',
        '& a, & a:hover, & a:focus': {
            color: '#e5dfcd',
        },
    },
    cardMedia: {
        height: '100%',
        '& img': {
            height: '100%',
            width: '100%',
        },
    },
}));

const SponsorAd = (props) => {
    const classes = OutfitStyles();
    const cardclasses = CardStyle();
    const { sponsorAdsFetched } = useContext(AppDataContext);
    const [headScriptRendered, setHeadScriptRendered] = useState(false);    
    const [divStyle] = useState(props.adDisplay === AppConstants.AD_DISPLAY_DESKTOP ? props.adDetails.styleDesktop : props.adDetails.styleMobile);

    useEffect(() => {
        if (sponsorAdsFetched || (props.adDetails && props.adDetails.divTagId && props.adDetails.headTag)) {
            try {
                if (!headScriptRendered && (typeof window.googletag != 'undefined' || typeof window.googletag !== undefined)) {
                    let scEle = document.getElementById(`${props.adDetails.divTagId}-head`);

                    if (scEle === null || scEle === undefined) {
                        var scriptElm = document.createElement('script');
                        scriptElm.setAttribute('id', `${props.adDetails.divTagId}-head`);
                        let headTagScript = props.adDetails.headTag;

                        if (props.adDisplay === AppConstants.AD_DISPLAY_DESKTOP) {
                            headTagScript = headTagScript.replaceAll("[[320, 50], [728, 90]]", "[728, 90]");
                        }
                        else if (props.adDisplay === AppConstants.AD_DISPLAY_MOBILE) {
                            headTagScript = headTagScript.replaceAll("[[320, 50], [728, 90]]", "[320, 50]");
                        }

                        headTagScript = headTagScript.replaceAll(/\\"/g, '"');
                        var inlineCode = document.createTextNode(headTagScript);
                        scriptElm.appendChild(inlineCode);
                        document.head.appendChild(scriptElm);
                        setHeadScriptRendered(true);
                    }
                    else {
                        window.googletag.cmd.push(window.googletag.pubads().refresh());
                    }
                }
            } catch (e) {
                console.log('Error occurred for ad: ', e);
            }
        }
    }, [sponsorAdsFetched])

    function renderAd() {
        let scEle = document.getElementById(`${props.adDetails.divTagId}-head`);
        if ((typeof window.googletag !== undefined || typeof window.googletag !== null) && (scEle !== null || scEle !== undefined)) {
            try {
                window.googletag.cmd.push(function () { window.googletag.display(`${props.adDetails.divTagId}`); });
            } catch (e) {
                console.log("Error while rendering ad: ", e);
            }
        }
    }

    return (
        sponsorAdsFetched && <div className={classes.parentDiv}>
            <div id={props.adDetails.divTagId} style={{ divStyle }}>
                {renderAd()}
            </div>
        </div>
    );
}

export default SponsorAd;