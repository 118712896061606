import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        background: 'rgba(0, 0, 0, .65) !important',
        padding: theme.spacing(1.5),
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
        color: 'white',
    },
    textColor: {
        color: '#888888',
    },
}));
const DescriptionText = (props) => {
    const classes = useStyles();
    return (
        <><Container maxWidth="false">
            <Grid container className={classes.mainContainer} spacing={5}>
                <Typography variant="body1" component="p">
                    <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(encodeURIComponent(props.text)) }} />
                </Typography>
            </Grid>
        </Container></>
    )
}
export default DescriptionText;