import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import AppConstants from "../common/AppConstants";
import { handleError } from '../helper/handleError';

export async function GetTeamList() {
    return APIHelper.get(APIURLConstants.GET_TEAMS).catch(handleError);
}
export async function GetCalenderData() {
    return APIHelper.get(APIURLConstants.GET_CALENDER_DATA).catch(handleError);
}

export async function GetCalenderDates() {
    return APIHelper.get(APIURLConstants.GET_CLANEDER_DATES).catch(handleError);
}
export async function GetTransformationData() {
    return APIHelper.get(APIURLConstants.GET_TRANSFORMATION_DATA).catch(handleError);
}

export async function GetGamesData(obj) {
    //try {
        let queryString = "?StartDate=" + obj.StartDate + "&EndDate=" + obj.EndDate;

        if (obj.TeamId !== undefined && obj.TeamId !== AppConstants.ALL_TEAMS) {
            if (obj.GameType !== undefined && obj.GameType !== AppConstants.ALL_GAMES) {
                queryString = "?StartDate=" + obj.StartDate + "&EndDate=" + obj.EndDate + "&TeamId=" + obj.TeamId + "&GameType=" + obj.GameType;
            } else {
                queryString = "?StartDate=" + obj.StartDate + "&EndDate=" + obj.EndDate + "&TeamId=" + obj.TeamId;
            }
        }
        // console.log("queryString",queryString);

    //return await ValidateApiKey().then(() => { return APIHelper.get(APIURLConstants.GET_FILTERRED_GAMES + queryString) });
    return APIHelper.get(APIURLConstants.GET_FILTERRED_GAMES + queryString).catch(handleError);
    //}
    //catch (e) {
    //    throw (e);
    //}
}

export async function GetGame(date) {
    return APIHelper.get(APIURLConstants.GET_GAME + "?GameDate=" + date).catch(handleError);
}

export async function GetSponsorAds() {
    return APIHelper.get(APIURLConstants.GET_SPONSORS_ADS).catch(handleError);}

export async function getInSeasonGamePics() {
    return APIHelper.get(APIURLConstants.GET_INSEASON_GAME_PIC).catch(handleError);
}
export async function GetTeamLocker(teamId) {
    return APIHelper.get(APIURLConstants.GET_LOCKER_BY_TEAM + "?TeamId=" + teamId).catch(handleError);
}
export async function GetEditionList() {
    return APIHelper.get(APIURLConstants.GET_EDITION_LIST).catch(handleError);
}

export async function GetEditionLocker(EditionId) {
    return APIHelper.get(APIURLConstants.GET_EDITION_LOCKER + "?EditionId=" + EditionId).catch(handleError);
}

export async function GetUpcomingGames(teamId) {
    if (teamId == undefined) {
        teamId = "";
    }
    return APIHelper.get(APIURLConstants.GET_UPCOMING_GAMES + "?teamId=" + teamId).catch(handleError);
}
export async function GetUniformDetailsByTeamEdition(teamName, editionName) {
    try {
        return await APIHelper.get(APIURLConstants.GET_UNIFORM_DETAILS_BY_TEAM_EDITION + "?teamName=" + teamName + "&editionName=" + editionName).catch(handleError);
    }
    catch (e) {
        throw (e);
    }
}

export async function GetStarGames() {
    try {
        return await APIHelper.get(APIURLConstants.GET_ALLSTAR_EVENTS).catch(handleError);
    }
    catch (e) {
        throw (e);
    }
}