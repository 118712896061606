import React, { useRef, createRef, useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import cardBgImage from '../../images/basketball.png';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(5),
    },
    gamesMainContainerForXScroll: {
        background: 'rgba(0, 0, 0, .65) !important',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(5),
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "scroll",
        '& > div': {
            minWidth: "300px",
            maxWidth: "300px",
        },
        '&::-webkit-scrollbar': {
            height: '4px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#585858',
            outline: '1px solid #585858',
        },

    },
    cardRoot: {
        minHeight: "300px",
        background: 'rgba(11, 11, 11, .5) !important',
        border: '1px solid rgba(255, 255, 255, .3)',
        boxShadow: 'none',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: theme.spacing(1.25),
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: '50% 100% !important',
        '& .MuiCardHeader-root': {
            padding: theme.spacing(0, 2, 1, 2),
        },
        '& .MuiCardContent-root': {
            padding: theme.spacing(1, 2),
        },
        '& .MuiCardHeader-avatar': {
            display: 'flex',
            width: '100%',
            marginRight: '0',
        },
    },
    teamIcons: {
        maxWidth: '120px',
        cursor: 'pointer',
        [theme.breakpoints.only('md')]: {
            maxWidth: '100px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100px',
        },
    },
    center: {
        textAlign: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontFamily: '"Action NBA  Web" !important',
        fontWeight: '500 !important',
        color: '#fff !important',
    },
    arrowIcon: {
        fontSize: theme.spacing(6),
        color: "white",
        justifyContent: "center",
        alignItems: "center"
    }

}));

const NameBreaker = (text) => {
    let arrText = text.split(" ");
    if (arrText.length >= 3) {
        let team = arrText[arrText.length - 1];
        text.trim(team);
        let city = text.substr(0, text.length - team.length);
        arrText = [city, team];
    }
    return arrText;
};

const SliderForEdition = (props) => {
    const classes = useStyles();
    const [FwBtnActive, setFwBtnActive] = useState(false);
    const [BwBtnActive, setBwBtnActive] = useState(false);
    const myRef = useRef();
    const Editions = props.Editions;
 
    const scroll = (scrollOffset) => {
        myRef.current.scrollLeft += scrollOffset;
    };

    return (
        <>
            <Grid item xs={1}>
                <Box alignItems="center" justifyContent="center" display="flex" height="100%">
                    <Button className={classes.arrowIcon} disabled={BwBtnActive} onClick={() => {
                        scroll(-80);
                    }} ><ArrowBackIosIcon className={classes.arrowIcon} /></Button>
                </Box>
            </Grid>
            {(Editions !== undefined && Editions.length > 0) ? <Container maxWidth="false">
                <Grid container spacing={2} className={classes.gamesMainContainerForXScroll} ref={myRef}>
                    {Editions.map((item, index) => {
                        let arrName = NameBreaker(item.editioN_NAME);
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                <Card key={item.teamId} className={`${classes.cardRoot}`} onClick={() => {
                                    props.populateGames(item.teamId, index);
                                }}>
                                    <CardContent>
                                        <Box component="div" justifyContent="space-between" display="flex" flexDirection="column" height="100%" alignItems="center">
                                            <img src={item.logoURL}
                                                className={`${classes.teamIcons} ${classes.center}`}
                                                alt={`NBA LockerVision ${item.editioN_NAME}`} title={`NBA LockerVision ${item.editioN_NAME}`}/>
                                            <>
                                                {
                                                    arrName.map((item, i) => {
                                                        return (
                                                            <Typography variant="h4" className={`${classes.center}`}>
                                                                {item}<br />
                                                            </Typography>
                                                        )
                                                    })
                                                }
                                            </>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container> : null}
            <Grid item xs={1}>
                <Box alignItems="center" justifyContent="center" display="flex" height="100%">
                    <Button className={classes.arrowButtonForward} disabled={FwBtnActive} onClick={() => {
                        scroll(80);
                    }}><ArrowForwardIosIcon className={classes.arrowIcon} /></Button>
                </Box>
            </Grid>
        </>
    )
}
export default SliderForEdition;