import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import { handleError } from '../helper/handleError';

export async function GetUniformDetails(gameId) {
    try {
        let queryString = "?game_Id=" + gameId;
        //return await ValidateApiKey().then(() => { return APIHelper.get(APIURLConstants.GET_UNIFORM_DETAIL + queryString) });
        return APIHelper.get(APIURLConstants.GET_UNIFORM_DETAIL + queryString).catch(handleError);
    }
    catch (e) {
        throw (e);
    }
}

export async function getTrendingOutfits() {
    try {
        //return await ValidateApiKey().then(() => { return APIHelper.get(APIURLConstants.GET_TRENDING_OUTFIT) });
        return APIHelper.get(APIURLConstants.GET_TRENDING_OUTFIT).catch(handleError);
    }
    catch (e) {
        throw (e);
    }
}

export async function GetAllStarUniformDetails(gameId) {
    try {
        let queryString = "?game_Id=" + gameId;
        return APIHelper.get(APIURLConstants.GET_ALL_STAR_UNIFORM_DETAILS + queryString).catch(handleError);
    }
    catch (e) {
        throw (e);
    }
}

export async function GetRisingStarUniformDetails() {
    try {        
        return APIHelper.get(APIURLConstants.GET_RISING_STAR_UNIFORM_DETAILS).catch(handleError);
    }
    catch (e) {
        throw (e);
    }
}

