import React from 'react'
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    rootPaper: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: theme.spacing(12),
        width: theme.spacing(12),
        borderRadius: theme.spacing(1),
        background: 'rgba(0, 0, 0, .6) !important',
        border: '1px solid rgba(255, 255, 255, .3)',
        color: '#ffffff !important',
        cursor: 'pointer',
        [theme.breakpoints.only('xs')]: {
            height: theme.spacing(10),
            width: theme.spacing(10),
            marginLeft: theme.spacing(.5),
            marginRight: theme.spacing(.5),
        },
    },
    rootPaperActive: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: theme.spacing(12),
        width: theme.spacing(12),
        borderRadius: theme.spacing(1) ,
        border: '2px solid #ffffff !important',
        background: 'rgba(0, 0, 0, .6) !important',
        color: '#ffffff !important',        
        // transform: 'scale(1, 1.25)',
        // transformOrigin: 'top',
        transition: 'all .2s ease-in-out',
        //boxShadow: 'inset 0px 0px 15px 5px rgb(138 138 138 / 70%)',
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(.5),
        '& .MuiTypography-h6, & .MuiTypography-h5': {
            fontWeight: 400,
        },
        '& .MuiTypography-h4': {
            fontWeight: '600 !important',
            fontSize: '2.5rem',
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: theme.spacing(3),
            height: theme.spacing(11),
            width: theme.spacing(12),
            marginLeft: theme.spacing(.5),
            marginRight: theme.spacing(.5),
        },
    },
    center: {
        textAlign: "center",
        textTransform: 'uppercase',
        width: theme.spacing(12),
        [theme.breakpoints.only('xs')]: {
            width: theme.spacing(6.75),
        },
    },
    month: {
        fontWeight: '300',
        lineHeight: '2 !important',
        fontSize: '.75rem !important',
        [theme.breakpoints.only('xs')]: {
            fontSize: '.6rem !important',        
        },
    },
    date: {
        fontFamily: '"Action NBA  Web" !important',   
        fontWeight: '500 !important',
        lineHeight: '1 !important',
    },
    day: {
        fontWeight: '300',
        lineHeight: '2 !important',
        fontSize: '.75rem !important',
        [theme.breakpoints.only('xs')]: {
            fontSize: '.6rem !important',        
        },
    },
}));
function DateTile(props) {
    const classes = useStyles();
    const data = props.data;
    return (
        
        <Paper elevation={4} className={props.isActive ? `${classes.rootPaperActive} paperActive` : `${classes.rootPaper} paperNormal`} onClick={(e) => {
            e.preventDefault();
            props.onClick(data);
        }}>
        <Box component="div" justifyContent="space-between" display="flex" flexDirection="column" height="100%" alignItems="center">
            <Typography variant="h6" component="h6" className={`${classes.center} ${classes.month} month`}>{data.month}</Typography>
            <Typography variant="h4" component="h4" className={`${classes.center} ${classes.date}`}>{data.day}</Typography>
            <Typography variant="h5" component="h5" className={`${classes.center} ${classes.day} day`}>{data.weekDay}</Typography>
        </Box>
        </Paper >
    )
}

export default DateTile;
