import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { GetTeamList, GetTeamLocker, GetEditionList, GetEditionLocker, GetUpcomingGames } from '../../services/home.service';
import LoadingAppUI from '../../sharedComponents/emptyStateUIContainers/LoadingAppUI';
import CircularLoader from '../../sharedComponents/emptyStateUIContainers/CircularLoader';
import SponsorAd from '../../sharedComponents/SponsorContainer/SponsorAd';
import GameContainer from './GameContainer';
import UpcomingGames from './UpcomingGames';
import TeamSlider from './TeamSlider';
import DescriptionText from './DescriptionText';
import HelmetMetaData from '../../sharedComponents/SocialMedia/HelmetMetaDeta';
import RouteConstants from "../../common/RouteConstants";
import Helper from '../../common/Helper';
import AppConstants from '../../common/AppConstants';
import AppDataContext from '../../common/AppContext';
import NoGameInSessionContainer from '../../sharedComponents/noGameContainer/NoGameInSessionContainer';
//import SponsorAd from '../../sharedComponents/SponsorContainer/SponsorAd';


const useStyles = makeStyles((theme) => ({
    headerText: {
        color: '#fff',
        fontFamily: '"Action NBA  Web" !important',
        fontSize: '1.8rem',
        fontWeight: '300',
        lineHeight: '1'
    },
    link: {
        cursor: 'pointer'
    },
    headerContainer: {
        color: '#ffffff !important',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 3.5),
        },
    },
    mainContainer: {
        background: 'rgba(0, 0, 0, .65) !important',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(3),
    },
}));

const HeaderText = (props) => {
    const classes = useStyles();
    return (
        <Container className={classes.headerContainer} maxWidth={false}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4" component="h4" className={classes.headerText}><span>{props.text}</span>
                </Typography>
            </Box>
            {/*{props.showAd &&*/}
            {/*    <Box alignItems="center">*/}
            {/*        <SponsorAd from="teams" sponsorsAdSource={""} sponsorsAdLink={""} />*/}
            {/*    </Box>*/}
            {/*}*/}
        </Container>
    )
};
const LockerByTeam = (props) => {
    const classes = useStyles();
    const { sponsorAds, sponsorAdsFetched, GetSponsorAds } = useContext(AppDataContext);
    const [teamAdDetails, setTeamAdDetails] = useState(!sponsorAds || sponsorAds.length === 0 ?
        Helper.GetPageAds("team") : sponsorAds.find(ad => ad.page === "team"));
    const [editionAdDetails, setEditionAdDetails] = useState(!sponsorAds || sponsorAds.length === 0 ?
        Helper.GetPageAds("edition") : sponsorAds.find(ad => ad.page === "edition"));    
    const [teamName, setTeamName] = useState("");
    const [selectedTeam, setSelectedTeamId] = useState('');
    const [titleName, setTitleName] = useState("");
    const [editionName, setEditionName] = useState("");
    const [Loading, setLoading] = useState(true);
    const [SliderType, setSliderType] = useState(null);
    const [TeamGames, setTeamGames] = useState([]);
    const [games, setUpcomingGames] = useState([]);
    const [EditionGames, setEditionGames] = useState([]);
    const [Teams, setTeams] = useState([]);
    const [Editions, setEditions] = useState([]);
    const [selectedEdition, setSelectedEditionId] = useState('');
    const [TilesState, setTilesState] = useState([]);
    const [Description, setDescription] = useState('');
    const [keywords, setKeywords] = useState('');
    const [Title, setTitle] = useState("");
    const [showNoDataMessage, setShowNoDataMessage] = useState(false);

    const description = process.env.REACT_APP_SEO_DESCRIPTION_FOR_TEAMS_PAGE;
    useEffect(() => {
        window.scrollTo(0, 0);
        
        if (!sponsorAdsFetched) {
            GetSponsorAds();
        }
        else {
            setTeamAdDetails(Helper.GetPageAds("team"));
            setEditionAdDetails(Helper.GetPageAds("edition"));
        }

        if (props.location.state !== undefined && props.location.state.team) {
            sessionStorage.setItem("sliderType", "team");
            sessionStorage.setItem("selectedTab", "Team");
            props.changeTabInHeader("team");
            setSliderType("team");
            if (Teams.length == 0) {
                getTeamList();
                setEditions([]);
                setEditionGames([]);
            }
        }
        else if (props.location.state !== undefined && props.location.state.edition) {
            sessionStorage.setItem("sliderType", "edition");
            sessionStorage.setItem("selectedTab", "Edition");
            props.changeTabInHeader("edition");
            setSliderType("edition");
            if (Editions.length == 0) {
                getEditionList();
                setTeams([]);
                setTeamGames([]);
            }
        } else {
            let QueryData = window.location.pathname.split("/");
            if (QueryData[1] !== undefined && QueryData[1] === "team") {
                sessionStorage.setItem("sliderType", "team");
                sessionStorage.setItem("selectedTab", "Team");
                props.changeTabInHeader("team");
                setTeamName((QueryData[2] !== undefined) ? QueryData[2] : "");
                setKeywords(Helper.InserTeamNameInPlace(process.env.REACT_APP_SEO_KEYWORDS_FOR_TEAMS_PAGE, (QueryData[2] !== undefined) ? QueryData[2] : "", null));
                setSliderType("team");
                getTeamList();
                setEditions([]);
                setEditionGames([]);

            } else if (QueryData[1] !== undefined && QueryData[1] === "edition") {
                sessionStorage.setItem("sliderType", "edition");
                sessionStorage.setItem("selectedTab", "Edition");
                props.changeTabInHeader("edition");
                setEditionName((QueryData[2] !== undefined) ? QueryData[2] : "");
                setKeywords(Helper.InserTeamNameInPlace(process.env.REACT_APP_SEO_KEYWORDS_FOR_EDITIONS_PAGE, null, (QueryData[2] !== undefined) ? QueryData[2] : ""));
                setSliderType("edition");
                setTeams([]);
                setTeamGames([]);
                getEditionList();
            } else {

            }

        }

    }, [props.location.state, teamName, editionName, sponsorAdsFetched]);

    useEffect(() => {
        if (SliderType === 'team')
            setKeywords(Helper.InserTeamNameInPlace(process.env.REACT_APP_SEO_KEYWORDS_FOR_TEAMS_PAGE, teamName, null));
        else if (SliderType === 'edition')
            setKeywords(Helper.InserTeamNameInPlace(process.env.REACT_APP_SEO_KEYWORDS_FOR_EDITIONS_PAGE, null, editionName));
    }, [teamName, editionName])

    const getTeamList = () => {
        setLoading(true);
        GetTeamList()
            .then(data => {
                if (data && data.length > 0) {
                    let teamId = "";
                    data.shift();
                    // if user directly comes from putting URL with team name instead of internal aplication page

                    if (teamName !== "") {
                        let dataObj = data.filter(obj => obj.teamNameSEO === teamName);
                        teamId = (dataObj !== undefined && dataObj.length > 0) ? dataObj[0]["teamId"] : "";
                        let index = data.findIndex(obj => obj.teamNameSEO === teamName);

                        if (index !== -1)
                            sessionStorage.setItem("activeTileTeam", index);
                        else
                            sessionStorage.setItem("activeTileTeam", 0);
                    }
                    let lastIndex = parseInt(sessionStorage.getItem("activeTileTeam"));

                    if (teamName !== undefined && teamName !== "") {
                        getTeamLocker(teamId);
                        getUpcomingGames(teamId);
                    } else if (lastIndex != undefined && lastIndex != null && !isNaN(lastIndex)) {
                        getTeamLocker(data[lastIndex].teamId);
                        getUpcomingGames(data[lastIndex].teamId);
                    }
                    else {
                        let FavTeamId = "";
                        if (localStorage.getItem("UserDetail") !== null && localStorage.getItem("UserDetail") !== undefined) {
                            let userData = JSON.parse(localStorage.getItem("UserDetail"));
                            FavTeamId = ((localStorage.getItem("UserDetail") !== null && localStorage.getItem("UserDetail") !== undefined && userData.favoriteTeams && userData.favoriteTeams.length > 0) ? userData.favoriteTeams[0] : "");
                        }
                        if (FavTeamId) {
                            getTeamLocker(FavTeamId);
                            getUpcomingGames(FavTeamId);
                            let index = data.findIndex(obj => obj.teamId === FavTeamId);
                            if (index !== -1)
                                sessionStorage.setItem("activeTileTeam", index);
                            else
                                sessionStorage.setItem("activeTileTeam", 0);
                        } else {
                            getTeamLocker(data[0].teamId);
                            getUpcomingGames(data[0].teamId);
                        }

                    }
                    setTeams(data);
                    makeActiveTilesState(data, "team");
                }
                else {
                    //console.log('getTeamList - No data found.');
                    setLoading(false);
                    setShowNoDataMessage(true);
                }
            }).catch(err => {
                throw err;
            });
    }

    const getTeamLocker = (teamId) => {
        GetTeamLocker(teamId)
            .then(data => {
                if (data && data.length > 0) {
                    setLoading(false);
                    //console.log(data)
                    setTeamGames(data);
                    setSelectedTeamId(teamId);
                }
                else {
                    //console.log('getTeamLocker - data is not available.');
                    setLoading(false);
                    setShowNoDataMessage(true);
                }
            }).catch(err => {
                //throw err;
                setLoading(false);
                setShowNoDataMessage(true);
            });
    }

    const getUpcomingGames = (teamId) => {
        GetUpcomingGames(teamId)
            .then(data => {
                if (data && data.length > 0) {
                    setLoading(false);
                    setUpcomingGames(data);
                } else {
                    setUpcomingGames([]);
                }
            }).catch(err => {
                setLoading(false);
                setShowNoDataMessage(true);
                //throw err;
            });
    }

    const getEditionList = () => {
        setLoading(true);
        GetEditionList()
            .then(data => {
                if (data && data.length > 0) {
                    let editionId = "";
                    if (editionName !== "") {
                        let dataObj = data.filter(obj => obj.editioN_NAME_SEO === editionName);
                        editionId = (dataObj !== undefined && dataObj.length > 0) ? dataObj[0]["editioN_ID"] : "";
                        let index = data.findIndex(obj => obj.editioN_NAME_SEO === editionName);
                        if (index !== -1)
                            sessionStorage.setItem("activeTileEdition", index);
                        else
                            sessionStorage.setItem("activeTileEdition", 0);
                    }

                    let lastIndex = parseInt(sessionStorage.getItem("activeTileEdition"));
                    if (editionName !== undefined && editionName !== "") {
                        getEditionLocker(editionId);
                    } else if (lastIndex != undefined && lastIndex != null && !isNaN(lastIndex)) {
                        getEditionLocker(data[lastIndex].editioN_ID);
                    } else {
                        getEditionLocker(data[0].editioN_ID);
                    }
                    setEditions(data);
                    makeActiveTilesState(data, "edition");
                }
                else {
                    setLoading(false);
                    setShowNoDataMessage(true);
                }
            }).catch(err => {
                throw err;
            });
    }

    const getEditionLocker = (editionId) => {
        GetEditionLocker(editionId)
            .then(data => {
                if (data && data.length > 0) {
                    setLoading(false);
                    setEditionGames(data);
                    setSelectedEditionId(editionId);
                }
                else {
                    //console.log('getEditionLocker - data is not available.');
                    setLoading(false);
                    setShowNoDataMessage(true);
                }
            }).catch(err => {
                setLoading(false);
                setShowNoDataMessage(true);
                //throw err;
            });
    }

    const makeActiveTilesState = (data, param) => {
        let lastIndex
        if (param === "edition")
            lastIndex = parseInt(sessionStorage.getItem("activeTileEdition"));
        else lastIndex = parseInt(sessionStorage.getItem("activeTileTeam"));
        let tempArr = [...TilesState];
        tempArr = data.map((item, i) => {
            if (lastIndex != undefined && lastIndex != null && !isNaN(lastIndex)) {
                if (i == lastIndex) {
                    if (param === "edition") {
                        setDescription(item.editioN_STORY);
                        setTitleName(item.editioN_NAME)
                        setTitle(`NBA LockerVision - ${item.editioN_NAME}`)
                    } else {
                        setTitleName(item.teamName)
                        setTitle(`NBA LockerVision - ${item.teamName}`)
                    }
                    return true;
                }
                else
                    return false;
            }
            else if (i === 0) {
                if (param === "edition") {
                    setDescription(item.editioN_STORY);
                    setTitleName(item.editioN_NAME)
                    setTitle(`NBA LockerVision - ${item.editioN_NAME}`)
                } else {
                    setTitleName(item.teamName)
                    setTitle(`NBA LockerVision - ${item.teamName}`)
                }
                return true;
            }
            else
                return false;
        });
        setTilesState(tempArr);
    }
    const setActiveAtIndex = (index) => {
        let tempArr = [...TilesState];
        tempArr = tempArr.map((item, i) => {
            if (i == index)
                item = true;
            else
                item = false;
            return item;
        });
        if (SliderType === "team")
            sessionStorage.setItem("activeTileTeam", index);
        else sessionStorage.setItem("activeTileEdition", index);
        setTilesState(tempArr);
    }
    const getDescriptionAtIndex = (index) => {
        Editions.map((item, i) => {
            if (i == index)
                setDescription(item.editioN_STORY);
        })
    }

    const pushtoRoute = (id, name, index, type, titleName) => {
        let byTeam = type === "team";
        let byEdition = type === "edition";

        if (byTeam) {
            props.history.push({
                pathname: RouteConstants.LOCKER_BY_TEAM + "/" + name,
                state: { team: byTeam, edition: byEdition, teamName: titleName }
            });
        } else {
            props.history.push({
                pathname: RouteConstants.LOCKER_BY_EDITION + "/" + name,
                state: { team: byTeam, edition: byEdition, editionName: titleName }
            });
        }
    }
    return (
        <div>
            <HelmetMetaData
                title={Title}
                quote={"Every Locker, Every Player, Every Game."}
                url={window.location.href}
                hashtag={"#nba"}
                description={description}
                keywords={keywords}
            ></HelmetMetaData>
            {Loading && <CircularLoader />}

            <>
                {/*<SponsorAd from="teams" sponsorsAdSource={""} sponsorsAdLink={""} />*/}
                {/*<HeaderText showAd text={SliderType === "team" ? "All NBA Uniforms By Team" : "All NBA Uniforms By Edition"} onClick={(e) => { e.preventDefault(); }} />*/}
                {!Loading && <>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item sm={4} xs={12}>
                            <HeaderText text={SliderType === "team" ? "All NBA Uniforms By Team" : "All NBA Uniforms By Edition"} onClick={(e) => { e.preventDefault(); }} />
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <Box display="flex" flex="1" justifyContent="flex-end" alignItems="center">
                                {AppConstants.DISPLAY_SPONSORS && teamAdDetails && SliderType === "team" && <>
                                    <Hidden smDown>
                                        <SponsorAd
                                            adDisplay={AppConstants.AD_DISPLAY_DESKTOP}
                                            adDetails={teamAdDetails}
                                        />
                                    </Hidden>
                                    <Hidden mdUp>
                                        <Box display="flex" alignItems="center" justifyContent="center" flex="1" marginTop="1">
                                            <SponsorAd
                                                adDisplay={AppConstants.AD_DISPLAY_MOBILE}
                                                adDetails={teamAdDetails}
                                            />
                                        </Box>
                                    </Hidden> </>
                                }
                                {AppConstants.DISPLAY_SPONSORS && editionAdDetails && SliderType === "edition" && <>
                                    <Hidden smDown>
                                        <SponsorAd
                                            adDisplay={AppConstants.AD_DISPLAY_DESKTOP}
                                            adDetails={editionAdDetails}
                                        />
                                    </Hidden>
                                    <Hidden mdUp>
                                        <Box display="flex" alignItems="center" justifyContent="center" flex="1" marginTop="1">
                                            <SponsorAd
                                                adDisplay={AppConstants.AD_DISPLAY_MOBILE}
                                                adDetails={editionAdDetails}
                                            />
                                        </Box>
                                    </Hidden>
                                </>
                                }
                            </Box>
                        </Grid>
                    </Grid>

                    <TeamSlider
                        populateGames={(Id, teamName, index) => {
                            setLoading(true);
                            if (SliderType === "team") {
                                //page redirection to /team
                                pushtoRoute(Id, teamName, index, "team", Teams[index].teamName);
                                setTeamName(teamName);
                                // get team locker data
                                getTeamLocker(Id);
                                // get upcoming games helper function
                                getUpcomingGames(Id);
                                // changing active tile on click
                                setActiveAtIndex(index);
                                //updating force key for re-rendering the TeamSlider
                                // setForceKey(forceKey + 1);
                            } else if (SliderType === "edition") {

                                //page redirection to /edition
                                pushtoRoute(Id, teamName, index, "edition", Editions[index].editioN_NAME);
                                setEditionName(teamName);
                                // get edition locker data
                                getEditionLocker(Id);
                                // changing active tile on click
                                setActiveAtIndex(index);
                                getDescriptionAtIndex(index);
                            }
                        }}
                        Teams={Teams}
                        Editions={Editions}
                        ActiveState={TilesState}
                        type={SliderType}
                        Initial={(SliderType === "team") ? sessionStorage.getItem("activeTileTeam") : sessionStorage.getItem("activeTileEdition")}
                    //forceKey={forceKey}
                    /></>}

                {!showNoDataMessage && SliderType === "edition" && <DescriptionText text={Description} />}
                {/*<HeaderText showAd text={"Uniform Lineup"} onClick={(e) => { e.preventDefault(); }} />*/}
                {!showNoDataMessage && !Loading && <HeaderText text={"Uniform Lineup"} onClick={(e) => { e.preventDefault(); }} />}
                {!showNoDataMessage && <GameContainer selectedTeamId={selectedTeam} selectedEditionId={selectedEdition} type={SliderType} games={SliderType === "team" ? ((TeamGames !== undefined && TeamGames.length) > 0 ? TeamGames : []) : ((EditionGames !== undefined && EditionGames.length) > 0 ? EditionGames : [])} />}
                {!showNoDataMessage && !Loading && SliderType === "team" && <UpcomingGames games={(games !== undefined && games.length) > 0 ? games : []} />}
                {showNoDataMessage && <>
                    <Container className={classes.mainContainer} maxWidth="false">
                        <NoGameInSessionContainer />
                    </Container>
                </>}
            </>
        </div>
    )
}
export default withRouter(LockerByTeam);