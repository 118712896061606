import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";

export async function GetAppDetails() {
    try {
        return await APIHelper.get(APIURLConstants.GET_APP_DETAILS);
    }
    catch (e) {
        throw (e);
    }
}