import React from "react";
import { Helmet } from "react-helmet";
import Helper from '../../common/Helper';

export default function HelmetMetaData(props) {    
    let currentUrl = encodeURIComponent(Helper.ValidateAndFetchURL(props.url));
    let quote = props.quote !== undefined ? props.quote : "Every Locker, Every Player, Every Game.";
    let title = props.title !== undefined ? props.title : "Every Locker, Every Player, Every Game.";
    let image = props.image !== undefined ? props.image : "";
    let description = props.description !== undefined ? props.description : "NBA - Every Locker, Every Player, Every Game.";
    let hashtag = props.hashtag !== undefined ? props.hashtag : "#nba";
    let relCanonicalURL = props.relCanonicalURL !== undefined ? props.relCanonicalURL : currentUrl;
    let keywords = props.keywords;
    return (
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={relCanonicalURL}></link>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="csrf_token" content="" />
            <meta property="type" content="website" />
            <meta property="url" content={currentUrl} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content={image} />
            <meta name="theme-color" content="#ffffff" />
            <meta name="_token" content="" />
            <meta property="title" content={title} />
            <meta property="quote" content={quote} />
            <meta name="description" content={description} />
            <meta property="keywords" content={keywords} />
            <meta property="image" content={image} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:quote" content={quote} />
            <meta property="og:hashtag" content={hashtag} />
            <meta property="og:image" content={image} />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:site_name" content="NBA - LockerVision" />
            <meta property="og:description" content={description} />
            <meta name="twitter:card" content={image} />
            <meta name="twitter:site" content={currentUrl} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:creator" content={hashtag} />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:domain" content="https://nba.lockervision.com" />
        </Helmet>
    );
}