import React from 'react'
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import cardBgImage from '../../images/basketball.png';
import Helper from '../../common/Helper';


const useStyles = makeStyles((theme) => ({
    rootPaper: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: theme.spacing(14),
        width: theme.spacing(12),
        borderRadius: theme.spacing(1),
        background: 'rgb(87, 87, 87, 50%) !important',
        border: '1px solid rgba(255, 255, 255, .3)',
        color: '#ffffff !important',
        cursor: 'pointer',
        [theme.breakpoints.only('xs')]: {
            height: theme.spacing(14),
            width: theme.spacing(12),
            marginLeft: theme.spacing(.5),
            marginRight: theme.spacing(.5),
        },
    },
    rootPaperActive: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: theme.spacing(13),
        width: theme.spacing(12),
        borderRadius: theme.spacing(1),
        border: '2px solid #ffffff !important',
        background: 'rgb(87, 87, 87, 50%) !important',
        color: '#ffffff !important',        
        transition: 'all .2s ease-in-out',
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.only('xs')]: {
            marginTop: theme.spacing(3),
            height: theme.spacing(14),
            width: theme.spacing(12),
            marginLeft: theme.spacing(.5),
            marginRight: theme.spacing(.5),
        },
        '& img': {
            // transform: 'scale(1, .8)',
        }
    },
    center: {
        textAlign: "center",
        width: theme.spacing(12),
        lineHeight: '1.4',
        paddingBottom: theme.spacing(0.5),
    },
    centerForDash: {
        textAlign: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontFamily: '"Action NBA  Web" !important',
        fontWeight: '500 !important',
        color: '#fff !important',
        [theme.breakpoints.only('md')]: {
            fontWeight: '300 !important',
            lineHeight: '1',
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontWeight: '100 !important',
            lineHeight: '1',
            fontSize: '1.35rem',
        },
    },
    month: {
        fontWeight: '300',
        lineHeight: '2',
        fontSize: '.75rem',
        [theme.breakpoints.only('xs')]: {
            fontSize: '.6rem',
        },
    },
    editionName: {
        lineHeight: '1.5',
    },
    day: {
        fontWeight: '300',
        lineHeight: '2',
        fontSize: '.75rem',
        [theme.breakpoints.only('xs')]: {
            fontSize: '.6rem',
        },
    },
    teamIcons: {
        maxWidth: '70px',
        cursor: 'pointer',
        [theme.breakpoints.only('md')]: {
            maxWidth: '60px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '60px',
        },
    },
    teamIconsForDash: {
        maxWidth: '120px',
        cursor: 'pointer',
        [theme.breakpoints.only('md')]: {
            maxWidth: '80px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '60px',
        },
    },
    cardRoot: {        
        background: 'rgb(87, 87, 87, 50%) !important',
        border: '1px solid rgba(255, 255, 255, .3)',
        boxShadow: 'none',
        height: theme.spacing(28),
        width: theme.spacing(24),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: theme.spacing(1.25),
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: '50% 100% !important',
        [theme.breakpoints.down('md')]: {
            height: theme.spacing(24),
            width: theme.spacing(20),
        },
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(18),
            width: theme.spacing(14),
        },
        '& .MuiCardHeader-root': {
            padding: theme.spacing(0, 2, 1, 2),
        },
        '& .MuiCardContent-root': {
            padding: theme.spacing(1, 2),
        },
        '& .MuiCardHeader-avatar': {
            display: 'flex',
            width: '100%',
            marginRight: '0',
        },
    },
}));

const NameBreaker = (text) => {
    let arrText = text.split(" ");
    if (arrText.length >= 3) {
        let team = arrText[arrText.length - 1];
        text.trim(team);
        let city = text.substr(0, text.length - team.length);
        arrText = [city, team];
    }
    return arrText;
};
const TeamTile = (props) => {
    const classes = useStyles();
    const data = props.data;
    if (props.type === "team") {
        let arrName = NameBreaker(data.teamName);
        if (props.dashboard) {
            return (
                <Card key={data.teamId} className={`${classes.cardRoot}`} 
                    // data-gaeventcategory="Team"
                    // data-gaeventlabel={`${data.teamName} Team`}
                    // data-gaeventaction={`${Helper.QA_URL+"/team/"+data.teamName}`}
                    onClick={() => { props.onClick(); }}>
                      
                    <CardContent>
                        <Box aria-label={`lockervision ${data.teamName}`} component="div" justifyContent="space-between" display="flex" flexDirection="column" height="100%" alignItems="center">
                            <img src={data.logoURL}
                                className={`${classes.teamIconsForDash} ${classes.centerForDash}`}
                                alt={`NBA LockerVision ${data.teamName}`} title={`NBA LockerVision ${data.teamName}`}/>
                            <>
                                {
                                    arrName.map((item, i) => {
                                        return (
                                            <Typography variant="h4" className={`${classes.centerForDash}`}>
                                                {item}<br />
                                            </Typography>
                                        )
                                    })
                                }
                            </>
                        </Box>
                    </CardContent>
                </Card>

            )
        } else
            return (
                <Paper key={props.key} elevation={4} className={props.isActive ? `${classes.rootPaperActive} paperActive` : `${classes.rootPaper} paperNormal`} 
                    // data-gaeventcategory="Edition"
                    // data-gaeventlabel={`${data.teamName}`}
                    // data-gaeventaction={`${Helper.QA_URL+"/edition/"+data.teamName}`}
                    onClick={(e) => {
                        e.preventDefault();
                        props.onClick(data.teamId);
                    }}>
                    <Box aria-label={`lockervision ${data.teamName}`} component="div" justifyContent="space-between" display="flex" flexDirection="column" height="100%" alignItems="center">
                        <img src={data.logoURL}
                            className={`${classes.teamIcons} ${classes.center}`}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            alt={`NBA LockerVision ${data.teamName}`} title={`NBA LockerVision ${data.teamName}`}/>
                        <Typography variant="caption" component="p" className={`${classes.center} day`}>
                            {
                                arrName.map((item, i) => {
                                    return (
                                        <>
                                            {item}<br />
                                        </>
                                    )
                                })
                            }
                        </Typography>

                    </Box>
                </Paper >
            )
    }
    else if (props.type === "edition") {
        let arrName = NameBreaker(data.editioN_NAME);
        if (props.dashboard) {
            return (
                
                    <Card key={data.teamId} className={`${classes.cardRoot}`} onClick={() => {
                        props.onClick();
                    }}>
                        <CardContent>
                            <Box aria-label={`${data.editioN_NAME}`} title={`NBA LockerVision ${data.editioN_NAME}`} component="div" justifyContent="space-between" display="flex" flexDirection="column" height="100%" alignItems="center">
                                <>
                                    {
                                        arrName.map((item, i) => {
                                            return (
                                                <Typography variant="h4" className={`${classes.centerForDash}`}>
                                                    {item}<br />
                                                </Typography>
                                            )
                                        })
                                    }
                                </>
                            </Box>
                        </CardContent>
                    </Card>
               
            )
        } else
            return (
                <Paper key={props.key} elevation={4} className={props.isActive ? `${classes.rootPaperActive} paperActive` : `${classes.rootPaper} paperNormal`} onClick={(e) => {
                    e.preventDefault();
                    props.onClick(data.editioN_ID);
                }}>
                    <Box aria-label={`${data.editioN_NAME}`} title={`NBA LockerVision ${data.editioN_NAME}`} component="div" justifyContent="center" display="flex" flexDirection="column" height="100%" alignItems="center">
                        {arrName.map((item, i) => {
                            return (<Typography
                                variant="body2" component="h6"
                                className={`${classes.editionName} ${classes.center}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >{item}</Typography>)
                        })}
                    </Box>
                </Paper >
            )
    }

    else
        return <></>
}

export default TeamTile;
