import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Chip from '@material-ui/core/Chip';
import TeamShopIcon from '../../sharedComponents/TeamShopIcon/TeamShopIcon';
import UniformMatchup from '../../sharedComponents/UniformMatchup/UniformMatchup';
import AppDataContext from '../../common/AppContext';
import RouteConstants from '../../common/RouteConstants';
import Helper from '../../common/Helper';
import AppConstants from '../../common/AppConstants';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(5),
    },

    cardRoot: {
        background: 'rgba(87, 87, 87, .5) !important',
        border: '1px solid rgba(255, 255, 255, .3)',
        boxShadow: 'none',
        height: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: theme.spacing(1.25),
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: '50% 100% !important',
        width: '100%',
        margin: theme.spacing(0, 1.5),
        '& .MuiCardHeader-root': {
            padding: theme.spacing(0, 2, 1, 2),
        },
        '& .MuiCardContent-root': {
            padding: theme.spacing(1, 2),
        },
        '& .MuiCardHeader-avatar': {
            display: 'flex',
            width: '100%',
            marginRight: '0',
        },
    },

    matchCenter: {
        '& .MuiTypography-h6': {
            color: '#fff',
            fontFamily: '"Action NBA  Web" !important',
            fontSize: '1.1rem',
            fontWeight: '300',
            lineHeight: '1',
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.2rem',
            },
        },
        '& .MuiSvgIcon-root': {
            color: '#888888',
            width: '20px',
            height: '20px',
            marginRight: '5px',
        },
    },

    matchLocation: {
        borderLeft: '2px solid #888 !important',
        color: '#888888 !important',
        paddingLeft: theme.spacing(1),
        marginLeft: theme.spacing(1.5),
        fontSize: '.625rem !important',
        textTransform: 'uppercase',
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1.5),
            marginRight: theme.spacing(1),
        },
    },

    MatchTime: {
        color: '#fff !important',
        fontSize: '0.9rem',
        // position: 'relative',
        lineHeight: '1',
        // padding: theme.spacing(0, 5.5, 1, 0),
        padding: theme.spacing(1, 0, 0, 2),
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.0rem',
        },
        '& span': {
            color: '#888888',
            fontSize: '.6rem',
            textTransform: 'uppercase',
            // position: 'absolute',
            right: '-8px',
            top: '0',
        },
    },

    MatchSeason: {
        paddingTop: theme.spacing(.5),
        fontSize: '.75rem',
        color: '#888888',
        [theme.breakpoints.only('xs')]: {
            whiteSpace: 'nowrap',
        },
    },

    teamIcons: {
        maxWidth: '120px',
        border: '1px solid #888888',
        cursor: 'pointer',
        [theme.breakpoints.only('md')]: {
            maxWidth: '100px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100px',
        },
    },

    versus: {
        fontFamily: '"Action NBA  Web" !important',
        fontWeight: '500 !important',
        color: '#fff !important',
    },

    teamName: {
        fontFamily: '"Action NBA  Web" !important',
        fontWeight: '300',
        color: '#fff !important',
        lineHeight: '1 !important',
    },

    textCenter: {
        textAlign: 'center',
        color: '#888888',
    },

    customEditionChip: {
        position: 'absolute',
        zIndex: '1',
        top: '0',
        width: '100%',
        left: '0',
        right: '0',
        '& .MuiChip-root': {
            height: 'auto',
            fontSize: '.70rem',
            fontWeight: '500',
            border: '1px solid #b5b5b5',
            '& .MuiChip-label': {
                paddingLeft: theme.spacing(.75),
                paddingRight: theme.spacing(.75),
            },
        },
    },

    avatar: {
        backgroundColor: red[500],
    },

    teamOutfitIcons: {
        maxWidth: '80px',
        margin: '0 auto',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '70px',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '70px',
        },
    },

    chipPosition: {
        position: 'relative',
    },

    chip: {
        fontSize: '.63rem',
        letterSpacing: '0.0500em',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.5, 0, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        [theme.breakpoints.down('md')]: {
            fontSize: '.60rem',
        },
        '& .MuiChip-label': {
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, .5, 0, .5)
            },
        },
    },
    teamLogo: {
        maxWidth: '35px',
        left: theme.spacing(.5),
        top: theme.spacing(.75),
    },
    seasonSpecialLogo: {
        maxWidth: '60px',
        paddingTop: '20px',
        left: theme.spacing(.5),
        top: theme.spacing(.75),
    },

    teamIconsForXScroll: {
        maxWidth: '80px',
        border: '1px solid #888888',
        cursor: 'pointer',
        [theme.breakpoints.only('md')]: {
            maxWidth: '70px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '70px',
        },
    },

    gamesMainContainerForXScroll: {
        background: 'rgba(0, 0, 0, .65) !important',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(5),
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "scroll",
        '& > div': {
            minWidth: "300px",
            maxWidth: "300px",
        },
        '&::-webkit-scrollbar': {
            height: '4px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#585858',
            outline: '1px solid #585858',
        },

    },

    chipForXScroll: {
        height: "24px",
        fontSize: '.63rem',
        letterSpacing: '0.0400em',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.25, 0, .25, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .25, 0, .25),
        [theme.breakpoints.down('md')]: {
            fontSize: '.60rem',
        },
        '& .MuiChip-label': {
            padding: theme.spacing(0, .5, 0, .5),
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, .25, 0, .25),
            },
        },
    },

    link: {
        color: "rgba(11, 11, 11, .5) !important",
        cursor: "pointer",
        textDecoration: "none",
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
    }
}));


const GameTile = (props) => {
    const classes = useStyles();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const { allTeams, AssetFactory } = useContext(AppDataContext);

    let item = props.item;
    const pushToGameOutfit = (gameId, teamId, teamName) => {
        let teams = allTeams.filter(function (el) {
            return el.teamName == teamName;
        });
        let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";

        props.history.push({
            pathname: `${RouteConstants.GAME_OUTFIT}/${teamSEOName}?gameid=${gameId}`,
            state: { gameId: gameId, teamId: teamId }
        })
    }

    const uniformMatchUp = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    const pushToOutfitDetail = (gameId, teamId, teamName) => {
        let teams = allTeams.filter(function (el) {
            return el.teamName == teamName;
        });
        let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";
        props.history.push({
            pathname: `${RouteConstants.GAME_OUTFIT}/${teamSEOName}?gameid=${gameId}`,
            state: { outfitGameId: gameId, outfitTeamId: teamId }
        })
    }

    return (
        <>
            <Card className={`${classes.cardRoot} cardRoot`}>
                <Link className={classes.link} onClick={(e) => {
                    e.preventDefault();
                    uniformMatchUp();
                }}>
                    <CardContent>
                        <Grid container spacing={2} alignItems="center" justify="center" className={classes.textCenter}>
                            <Grid item xs={5}>
                                <Box mb={.75} display="flex" alignItems="center" justifyContent="center" position="relative" left="-4px">
                                    <img src={item.awayTeamLogoURL} className={`${classes.teamLogo} teamLogo`} alt={`NBA LockerVision ${item.awayTeam}`} title={`NBA LockerVision ${item.awayTeam}`} />
                                    <Typography variant="h5" className={classes.teamName}>
                                        {item.awayTeam}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={5}>
                                <Box mb={.75} display="flex" alignItems="center" justifyContent="center" position="relative" left="-4px">
                                    <img src={item.homeTeamLogoURL} className={`${classes.teamLogo} teamLogo`} alt={`NBA LockerVision ${item.homeTeam}`} title={`NBA LockerVision ${item.homeTeam}`} />
                                    <Typography variant="h5" className={classes.teamName}>
                                        {item.homeTeam}
                                    </Typography>
                                </Box>
                            </Grid>    
                        </Grid>


                        <Grid container spacing={2} alignItems="center" justify="center" className={classes.textCenter}>
                            <Grid item xs={5} className={classes.chipPosition}>
                                <Box display="flex" justifyContent="center" className={`${classes.customEditionChip}`}>
                                    <Chip label={item.awayTeamEdition} style={Helper.checkForComingSoon(item.awayTeamUniformImg) ? { background: "black", color: "white" } : { background: `${item.awayTeamBGColor}`, color: `${item.awayTeamTextColor}` }} />
                                </Box>
                                <Box display="block" className={`${classes.teamOutfitIcons}`}>
                                    <img src={Helper.getAssetType(item.awayTeamUniformImg, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.THUMBNAIL_KEY))}
                                        className={`${classes.teamIconsForXScroll}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            pushToGameOutfit(item.gameId, item.awayTeamId, item.awayTeam);
                                        }} alt={`NBA LockerVision ${item.awayTeam} ${item.awayTeamEdition}`} title={`NBA LockerVision ${item.awayTeam} ${item.awayTeamEdition}`} />
                                </Box>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography variant="h5" className={classes.versus}>
                                    AT
                                </Typography>
                            </Grid>

                            <Grid item xs={5} className={classes.chipPosition}>
                                <Box display="flex" justifyContent="center" className={`${classes.customEditionChip}`}>
                                    <Chip label={item.homeTeamEdition} style={Helper.checkForComingSoon(item.homeTeamUniformImg) ? { background: "black", color: "white" } : { background: `${item.homeTeamBGColor}`, color: `${item.homeTeamTextColor}` }} />
                                </Box>
                                <Box display="block" className={`${classes.teamOutfitIcons}`}>
                                    <img src={Helper.getAssetType(item.homeTeamUniformImg, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.THUMBNAIL_KEY))}
                                        className={`${classes.teamIconsForXScroll}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            pushToGameOutfit(item.gameId, item.homeTeamId, item.homeTeam);
                                        }}
                                        alt={`NBA LockerVision ${item.homeTeam} ${item.homeTeamEdition}`} title={`NBA LockerVision ${item.homeTeam} ${item.homeTeamEdition}`} />
                                </Box>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2} alignItems="center" justify="center" className={classes.textCenter}>
                            <Grid item xs={5} alignItems="center" justify="center">
                                <Box alignItems="center" display="flex" justifyContent="center" flexDirection="column">
                                    <Chip
                                        label="Game Outfitting"
                                        clickable
                                        component="a"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            pushToGameOutfit(item.gameId, item.awayTeamId, item.awayTeam);
                                        }}
                                        onDelete={() => { return }}
                                        deleteIcon={<></>}
                                        variant="outlined"
                                        color="primary"
                                        className={`${classes.chipForXScroll} btnChip`}
                                    />
                                    <TeamShopIcon teamName={item.awayTeam} xScroll={props.xScroll} />
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                {item.seasonSpecial === true && <Box alignItems="center" display="flex" justifyContent="center" flexDirection="column">
                                    <img src={item.seasonSpecialLogoUrl}
                                        className={`${classes.seasonSpecialLogo} seasonSpecialLogo`}
                                        alt={`NBA LockerVision ${item.seasonSpecialName}`} title={`NBA LockerVision ${item.seasonSpecialName}`} />
                                </Box>
                                }
                            </Grid>
                            <Grid item xs={5} alignItems="center" justify="center">
                                <Box alignItems="center" display="flex" justifyContent="center" flexDirection="column">
                                    <Chip
                                        label="Game Outfitting"
                                        clickable
                                        component="a"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            pushToGameOutfit(item.gameId, item.homeTeamId, item.homeTeam);
                                        }}
                                        onDelete={() => { return }}
                                        deleteIcon={<></>}
                                        variant="outlined"
                                        color="primary"
                                        className={`${classes.chipForXScroll} btnChip`}
                                    />
                                    <TeamShopIcon teamName={item.homeTeam} xScroll={props.xScroll} />
                                </Box>
                            </Grid>

                        </Grid>

                    </CardContent>
                    <CardHeader
                        avatar={
                            <React.Fragment>
                                <Box component="div" justifyContent="space-between" alignItems="center" display="flex" flexGrow="1" flexDirection="column">
                                    <Box component="div" alignItems="center" display="flex">
                                        <Box component="div" display="flex" alignItems="flex-start" className={classes.matchCenter}>
                                            <LocationOnIcon />
                                            <Typography variant="h6"> {item.arena}</Typography>
                                        </Box>
                                        <Typography variant="caption" className={`${classes.matchLocation} matchLocation`}>{item.city}</Typography>
                                    </Box>
                                    <Box component="div" display="flex">
                                        <Typography variant="caption" component="p" className={`${classes.MatchSeason} MatchSeason`}>{item.gameDate}</Typography>
                                        <Typography variant="caption" component="p" className={`${classes.MatchTime} MatchTime`}>{item.gameTime.slice(0, 6)} <span>{item.gameTime.slice(6, 13)}</span></Typography>
                                    </Box>
                                </Box>
                            </React.Fragment>
                        }
                    />
                </Link>
            </Card>
            <UniformMatchup
                isDialogOpen={isDialogOpen}
                gameId={item.gameId}
                homeTeamNameSEO={item.homeTeamNameSEO}
                awayTeamNameSEO={item.awayTeamNameSEO}
                homeTeamName={item.homeTeam}
                awayTeamName={item.awayTeam}
                homeTeamId={item.homeTeamId}
                awayTeamId={item.awayTeamId}
                homeTeamUniformImg={item.homeTeamUniformImg}
                awayTeamUniformImg={item.awayTeamUniformImg}
                homeTeamEdition={item.homeTeamEdition}
                awayTeamEdition={item.awayTeamEdition}
                uniformType={"NBAUniform"}
                handleDialogClose={(data, index) => {
                    handleDialogClose(false);
                }}
            />
        </>
    );
}

export default withRouter(GameTile);