import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Slider from "react-slick";
import ReactPlayer from 'react-player';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from '@material-ui/core/styles';
import { CardActionArea, CardActions, Box, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'

import Chip from '@material-ui/core/Chip';
import Helper from '../../common/Helper';
import RouteConstants from '../../common/RouteConstants';
import SocialMediaContainer from '../../sharedComponents/SocialMedia/SocialMediaContainer';
import { GetUniformDetails } from "../../services/uniform.service";
import LoadingAppUI from '../../sharedComponents/emptyStateUIContainers/LoadingAppUI';
import CircularLoader from '../../sharedComponents/emptyStateUIContainers/CircularLoader';
import SponsorAd from '../../sharedComponents/SponsorContainer/SponsorAd';
import PreviewShopIcon from '../../sharedComponents/TeamShopIcon/PreviewShopIcon';
import OutfitIcon from '../../sharedComponents/CustomIcons/OutfitIcon';
import { GetUniformDetailsByTeamEdition, GetTeamList, GetEditionLocker, GetTeamLocker, GetEditionList } from "../../services/home.service";
import AppDataContext from '../../common/AppContext';
import AppConstants from '../../common/AppConstants';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import HelmetMetaData from '../../sharedComponents/SocialMedia/HelmetMetaDeta';
import Hidden from '@material-ui/core/Hidden';
import ImageSlider from '../../sharedComponents/ImageSlider/ImageSlider';
import { MergeType } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(5),
    },

    mainContainer: {
        background: 'rgba(0, 0, 0, .65) !important',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(0),
    },

    marginTop: {
        marginTop: theme.spacing(3),
    },

    cardRoot: {
        background: 'transparent !important',
        border: 'none',
        boxShadow: 'none',
        position: 'relative',
        paddingTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },

    teamIcons: {
        maxWidth: '55px',
    },

    outfitText: {
        color: '#b5b5b5',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
    },

    customChip: {
        position: 'absolute',
        zIndex: '1',
        top: '0',
        width: '100%',
        '& .MuiChip-root': {
            height: 'auto',
            padding: '2px 0',
            fontSize: '.78rem',
            fontWeight: '500',
            border: '1px solid #b5b5b5',
            '& .MuiChip-label': {
                paddingLeft: theme.spacing(.75),
                paddingRight: theme.spacing(.75),
                maxWidth: '175px',
                whiteSpace: 'normal',
                textAlign: 'center',
            },
        },
    },

    title: {
        flexGrow: 1,
        '& .MuiChip-clickable': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        '& > div:nth-child(2)': {
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
            },
        },
        '& h5': {
            fontFamily: '"Action NBA  Web"',
            color: '#b5b5b5',
        },
        '& p': {
            color: '#b5b5b5',
        },
    },

    teamName: {
        fontFamily: '"Action NBA  Web"',
        fontWeight: '500',
        color: '#fff',
    },

    backchip: {
        // width: '50px',
        fontSize: '.63rem',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.5, .5, .5, 0),
        textTransform: 'uppercase',
        // padding: theme.spacing(0, .5, 0, .5),
        '& .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, .7, 0, .7)
            },
        },
    },
    leftrightgrid: {
        padding: '0px 40px 0px 25px !important',
        display: 'inline-flex',
        [theme.breakpoints.down('xs')]: {
            padding: '0px 20px 0px 25px !important'
        },
    },
    leftrightchip: {
        fontSize: '.63rem',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.5, .5, .5, 0),
        textTransform: 'uppercase',
        borderRadius: '25px',
        '& .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1, 0, 1)
            },
        },
        '& .MuiButton-startIcon': {
            marginRight: '-4px !important'
        },
        '& .MuiButton-endIcon': {
            [theme.breakpoints.up('xs')]: {
                marginLeft: '-10px !important'
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: '-4px !important'
            }
        }
    },
    leftpaddingchip: {
        padding: '0px 2px 0px 8px',
    },
    rightpaddingchip: {
        padding: '0px 8px 0px 2px',
    },
    spantext: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    rightchip: {
        float: 'right'
    },
    leftrightarrow: {
        margin: 0,
        padding: 0
    },
    headerSection: {
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            height: '1px',
            background: '#888888 !important',
            width: '40%',
            left: '0',
            bottom: '0',
        },
    },

    topHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#888888 !important',
        padding: theme.spacing(1.5, 0),
        '& h6': {
            position: 'relative',
        },
        '& .MuiButton-text': {
            color: '#888888',
            fontSize: '.75rem',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },

    cardAction: {
        position: 'absolute',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'space-between',
        left: '0',
        right: '0',
        top: theme.spacing(2),
        '& a, & a:hover, & a:focus': {
            color: '#e5dfcd',
            marginRight: theme.spacing(2),
        },
        '& a:last-child': {
            marginRight: theme.spacing(0),
        },
    },

    cardParentMedia: {
        display: 'flex',
        background: '#000000 !important',
        border: '1px solid #888888 !important',
        borderRadius: theme.spacing(.5),
        '& img': {
            height: '100%',
            width: '100%'
        },
    },

    lgParentMedia: {
        display: 'flex',
        background: '#000000 !important',
        border: '1px solid #888888 !important',
        borderRadius: theme.spacing(.5),
        maxHeight: '450px',
        '& img': {
            height: '100%',
            width: '100%',
        },
    },

    textColor: {
        color: '#888888 !important',
    },

    cardMedia: {
        display: 'flex',
        background: '#000000 !important',
        border: '1px solid #888888',
        cursor: 'pointer',
        borderRadius: theme.spacing(.5),
        height: '100%',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain',
        },
    },

    chip: {
        fontSize: '.7rem',
        letterSpacing: '0.0500em',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.5, .5, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        '& .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1, 0, 1)
            },
        },
    },

    DialogRoot: {
        '& .MuiDialog-paper': {
            overflow: 'visible',
            minHeight: "80vh",
            background: '#000 !important',
            boxShadow: '0px 0px 5px rgb(255 255 255 / 30%)',
            '& .MuiDialogContent-root': {
                overflowX: 'hidden',
            },
            '& .MuiDialogTitle-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: 'rgba(255, 255, 255, .5)',
                padding: theme.spacing(0, 1.5),
                '& .MuiIconButton-root': {
                    color: 'rgba(255, 255, 255, .5)',
                },
            },
            [theme.breakpoints.only('xs')]: {
                maxWidth: '302px',
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '444px',
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '444px',
            },
            [theme.breakpoints.up('xl')]: {
                maxWidth: '960px',
            },
        },

    },
    imgWidth: {
        width: '100%',
        cursor: "pointer",
        boxShadow: '0px 0px 5px rgb(255 255 255 / 30%)',
    },
    chip: {
        fontSize: '.63rem',
        letterSpacing: '0.0500em',
        color: '#8f8f8f !important',
        borderColor: '#8f8f8f !important',
        margin: theme.spacing(.5, .5, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        '& .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1, 0, 1)
            },
        },
    },
    DialogHeader: {
        '& br': {
            display: 'none',
            [theme.breakpoints.down('lg')]: {
                display: 'block',
            },
        },
    },
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25%',
        cursor: 'pointer',
    },
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    playThumb: {
        textAlign: 'center',
        height: '100px',
        width: '160px',
        background: 'black',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: theme.spacing(.5),
    },
    playThumbWrap: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    customChipBottom: {
        position: 'absolute',
        zIndex: '1',
        bottom: '0',
        width: '100%',
        '& .MuiChip-root': {
            height: 'auto',
            padding: '2px 0',
            fontSize: '.78rem',
            fontWeight: '500',
            border: '1px solid #b5b5b5',
            '& .MuiChip-label': {
                paddingLeft: theme.spacing(.75),
                paddingRight: theme.spacing(.75),
            },
        },
    },
    paddingBottom: {
        paddingBottom: theme.spacing(1.5),
    },

}));

function OutfitPreviewFav(props) {
    const classes = useStyles();
    const history = useHistory();
    let temp = decodeURI(window.location.pathname);

    let QueryData = temp.split("/");
    const { sponsorAds, allTeams, allEditions, AssetFactory, sponsorAdsFetched, GetSponsorAds } = useContext(AppDataContext);    
    const [outfitAdDetails, setOutfitAdDetails] = useState(!sponsorAds || sponsorAds.length === 0 ?
        Helper.GetPageAds("outfit") : sponsorAds.find(ad => ad.page === "outfit"));
    const editionNameSEO = (QueryData[3] !== undefined) ? QueryData[3] : "";
    const teamNameSEO = (QueryData[2] !== undefined) ? QueryData[2] : "";


    const [loading, setLoading] = useState(true);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [storyData, setStoryData] = useState({});


    const [gameDate, setGameDate] = useState('');
    const [TeamId, setTeamId] = useState('');
    const [TeamName, setTeamName] = useState('');
    const [TeamList, setTeamList] = useState([]);
    const [prevTeam, setPrevTeam] = useState({});
    const [nextTeam, setNextTeam] = useState({});
    const [TeamLogoURL, setTeamLogoURL] = useState('');
    const [BGColor, setBGColor] = useState('');
    const [TextColor, setTextColor] = useState('');
    const [ShopImgUrl, setShopImgUrl] = useState('');
    const [CourtName, setCourtName] = useState('');
    const [CourtImg, setCourtImg] = useState('');
    const [EditionName, setEditionName] = useState('');
    const [EditionList, setEditionList] = useState([]);
    const [prevEdition, setPrevEdition] = useState({});
    const [nextEdition, setNextEdition] = useState({});
    const [EditionImg, setEditionImg] = useState('');
    const [EditionCardImg, setEditionCardImg] = useState('');
    const [EditionStory, setEditionStory] = useState('');
    const [title, setTitle] = useState('');

    const [JacketPantName, setJacketPantName] = useState('');
    const [JacketPantImg, setJacketPantImg] = useState('');
    const [LongSleeveName, setLongSleeveName] = useState('');
    const [LongSleeveImg, setLongSleeveImg] = useState('');
    const [ShortSleeveName, setShortSleeveName] = useState('');
    const [ShortSleeveImg, setShortSleeveImg] = useState('');
    const [HatName, setHatName] = useState('');
    const [HatImg, setHatImg] = useState('');
    const [TowelName, setTowelName] = useState('');
    const [TowelImg, setTowelImg] = useState('');
    const [sponsorsData, setSponsorsData] = useState([]);
    const [LargeImages, setLargeImages] = useState([]);
    const [keywords, setKeywords] = useState('');
    const [videoLink, setVideoLink] = useState(null);
    const [play, setPlay] = useState(true);
    const [type, setType] = useState('edition');
    const [teamSeoId, setTeamSeoId] = useState(0);
    const [editionId, setEditionId] = useState(0);

    const description = process.env.REACT_APP_SEO_DESCRIPTION_FOR_OUTFIT_PAGE;
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        //getOutfitPreview();
        //AppConstants.DISPLAY_SPONSORS == true && getSponsorsData();
        if (!sponsorAdsFetched) {            
            GetSponsorAds();
        }
        else {
            setOutfitAdDetails(Helper.GetPageAds("outfit"));
        }
    }, [sponsorAdsFetched])
    useEffect(() => {
        SetOutfitPreview();
    }, [storyData])
    useEffect(() => {
        if (TowelImg !== '')
            setLoading(false);
    }, [TowelImg])
    useEffect(() => {
        setKeywords(Helper.InserTeamNameInPlace(process.env.REACT_APP_SEO_KEYWORDS_FOR_OUTFIT_PAGE, TeamName, EditionName));
    }, [TeamName, EditionName])
    useEffect(() => {
        if ((allTeams.length > 0) && props.location.state && (props.location.state.type == 'edition')) {
            setType(props.location.state.type);
            setEditionId(props.location.state.editionid);
            getOutfitPreview();
        }
        if ((allTeams.length > 0) && !props.location.state) {
            setType('team');
        }
    }, [teamNameSEO])
    useEffect(() => {
        if ((type == 'edition') && (editionId != 0)) {
            getOutfitPreview();
        }
    }, [type, editionId]);
    useEffect(() => {
        if ((type == 'team') && (teamSeoId != 0)) {
            getOutfitPreview();
        }
    }, [type, teamSeoId]);
    useEffect(() => {
        if ((allTeams.length > 0) && props.location.state && (props.location.state.type == 'edition')) {
            setType(props.location.state.type);
            setEditionId(props.location.state.editionid);
            //getOutfitPreview();
        }
        if ((allTeams.length > 0) && !props.location.state) {
            let obj = allTeams.find(o => o.teamNameSEO === teamNameSEO);
            setTeamSeoId(obj.teamId);
        }
    }, [allTeams])
    useEffect(() => {
        if ((allEditions.length > 0) && props.location.state && (props.location.state.type == 'team')) {
            setType(props.location.state.type);
            setTeamSeoId(props.location.state.teamid);
            getOutfitPreview();
        }
    }, [editionNameSEO])
    useEffect(() => {
        if ((allEditions.length > 0) && props.location.state && (props.location.state.type == 'team')) {
            setType(props.location.state.type);
            setTeamSeoId(props.location.state.teamid);
            //getOutfitPreview();
        }
        if ((allEditions.length > 0) && !props.location.state) {
            let obj = allEditions.find(o => o.editioN_NAME_SEO === editionNameSEO);
            setEditionId(obj.editioN_ID);
        }
    }, [allEditions])
    useEffect(() => {
        if ((videoLink == null) && !play) {
            setPlay(true);
        }
    }, [videoLink])   

    const SetOutfitPreview = () => {
        let data = { ...storyData };
        if (data !== undefined) {

            let titleTeamName = data.teamName !== undefined ? data.teamName : "";
            let titleEditionName = data.edition_Name !== undefined ? data.edition_Name : "";
            setTitle(`NBA LockerVision - ${titleTeamName} - ${titleEditionName} - Story Guide`);

            setTeamId((data.teamId !== undefined) ? data.teamId : "");
            setTeamName((data.teamName !== undefined) ? data.teamName : "");
            setEditionStory((data.edition_Story !== undefined) ? Helper.trimVideoFromStory(data.edition_Story).storyData : "");
            setVideoLink((data.edition_Story !== undefined) ? Helper.trimVideoFromStory(data.edition_Story).videoLink : null);
            setTeamLogoURL((data.teamLogoURL !== undefined) ? data.teamLogoURL : "");
            setBGColor((data.bG_Color !== undefined) ? data.bG_Color : "");
            setTextColor((data.text_Color !== undefined) ? data.text_Color : "");
            setShopImgUrl((data.shop_Img_Url !== undefined) ? data.shop_Img_Url : "");
            setCourtName((data.court_Name !== undefined) ? data.court_Name : "");
            setCourtImg((data.court_Img_Url !== undefined) ? Helper.getAssetType(data.court_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY)) : "");
            setEditionName((data.edition_Name !== undefined) ? data.edition_Name : "");
            setEditionImg((data.edition_Img_Url !== undefined) ? data.edition_Img_Url : "");
            setEditionCardImg((data.edition_Img_Url !== undefined) ? Helper.getAssetType(data.edition_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY)) : "");
            setJacketPantName((data.jacketPant_Name !== undefined) ? data.jacketPant_Name : "");
            setJacketPantImg((data.jacketPant_Img_Url) !== undefined ? Helper.getAssetType(data.jacketPant_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY)) : "");
            setLongSleeveName((data.lS_Name !== undefined) ? data.lS_Name : "");
            setLongSleeveImg((data.lS_Img_Url !== undefined) ? Helper.getAssetType(data.lS_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY)) : "");
            setShortSleeveName((data.sS_Name !== undefined) ? data.sS_Name : "");
            setShortSleeveImg((data.sS_Img_Url !== undefined) ? Helper.getAssetType(data.sS_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY)) : "");
            setHatName((data.hat_Name !== undefined) ? data.hat_Name : "");
            setHatImg((data.hat_Img_Url !== undefined) ? Helper.getAssetType(data.hat_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.CARD_KEY)) : "");
            setTowelName((data.towel_Name !== undefined) ? data.towel_Name : "");
            setTowelImg((data.towel_Img_Url !== undefined) ? Helper.getAssetType(data.towel_Img_Url, Helper.getElemValue(AssetFactory, AppConstants.DEFAULT_KEY), Helper.getElemValue(AssetFactory, AppConstants.TOWEL_KEY)) : "");

        } else {
            console.log("Data is not valid");
            //props.history.push("/");
        }
    }

    const getUniformDetailsByTeamEdition = (team, edition) => {
        GetUniformDetailsByTeamEdition(team, edition).then(data => {
            if (data !== undefined) {                
                setStoryData(data.homeTeamUniform);
            }
        });
    }

    const getOutfitPreview = async () => {
        //debugger;
        if (teamNameSEO !== "" && editionNameSEO !== "") {
            getUniformDetailsByTeamEdition(teamNameSEO, editionNameSEO);
            if (type == 'team') {
                getTeamList(teamSeoId, editionNameSEO);
            }
            if (type == 'edition') {
                getEditionList(editionId, teamNameSEO);
            }
        }
        else {
            console.log("Data is not valid");
            //props.history.push("/");
        }
    }
    const getTeamList = (teamid, editionNameSEO) => {
        //setLoading(true);
        //console.log(editionNameSEO);
        GetTeamLocker(teamid)
            .then(data => {
                if (data && data.length > 0) {
                    setEditionList(data);
                    //console.log(allEditions);
                    let index = allEditions.findIndex(obj => obj.editioN_NAME_SEO === editionNameSEO);
                    //console.log(allEditions[index]);
                    let editionIdFound = allEditions[index].editioN_ID;
                    index = data.findIndex(obj => obj.editionId === editionIdFound);
                    let indexnew = index;
                    if ((index + 1) == data.length) {
                        index = 0;
                    } else {
                        index = index + 1;
                    }
                    index = allEditions.findIndex(obj => obj.editioN_ID === data[index].editionId);
                    setNextEdition(allEditions[index]);
                    if ((indexnew - 1) == -1) {
                        indexnew = data.length - 1;
                    } else {
                        indexnew = indexnew - 1;
                    }
                    indexnew = allEditions.findIndex(obj => obj.editioN_ID === data[indexnew].editionId);
                    setPrevEdition(allEditions[indexnew]);
                }
            })
    }
    const getEditionList = (editionid, teamnameseo) => {
        //setLoading(true);
        GetEditionLocker(editionid)
            .then(data => {
                if (data && data.length > 0) {
                    setTeamList(data);
                    let index = allTeams.findIndex(obj => obj.teamNameSEO === teamnameseo);
                    // console.log('index123',index);
                    let teamIdFound = allTeams[index].teamId;
                    index = data.findIndex(obj => obj.teamId === teamIdFound);
                    let indexnew = index;
                    if ((index + 1) == data.length) {
                        index = 0;
                    } else {
                        index = index + 1;
                    }
                    index = allTeams.findIndex(obj => obj.teamId === data[index].teamId);
                    setNextTeam(allTeams[index]);
                    if ((indexnew - 1) == -1) {
                        indexnew = data.length - 1;
                    } else {
                        indexnew = indexnew - 1;
                    }
                    indexnew = allTeams.findIndex(obj => obj.teamId === data[indexnew].teamId);
                    setPrevTeam(allTeams[indexnew]);
                }
            })
    }
    const backToDashboard = () => {
        //history.goBack();
        let param = type;
        let byTeam = param === "team";
        let byEdition = param === "edition";
        let index = byTeam ? parseInt(sessionStorage.getItem("activeTileTeam")) : parseInt(sessionStorage.getItem("activeTileEdition"));
        if (byTeam) {
            let teams = [];
            let FavTeamId = "";
            if (localStorage.getItem("UserDetail") !== null && localStorage.getItem("UserDetail") !== undefined) {
                let userData = JSON.parse(localStorage.getItem("UserDetail"));
                FavTeamId = ((localStorage.getItem("UserDetail") !== null && localStorage.getItem("UserDetail") !== undefined && userData.favoriteTeams && userData.favoriteTeams.length > 0) ? userData.favoriteTeams[0] : "");
                teams = allTeams.filter(function (el, k) {
                    return parseInt(el.teamId) == parseInt(FavTeamId);
                });
                sessionStorage.removeItem("activeTileTeam");
            } else {
                if (index != undefined && index != null && !isNaN(index)) {
                    sessionStorage.setItem("activeTileTeam", index);
                } else {
                    index = 0;
                    sessionStorage.setItem("activeTileTeam", 0);
                }
                teams = allTeams.filter(function (el, k) {
                    return k == index;
                });
            }
            let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";
            if (teamSEOName !== "" && teamSEOName !== null) {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_TEAM + "/" + teamSEOName,
                    state: { team: byTeam, edition: byEdition, teamName: teamSEOName }
                });
            } else {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_TEAM + "/atlanta-hawks",
                    state: { team: byTeam, edition: byEdition, teamName: "atlanta-hawks" }
                });
            }
        } else {
            let editions = [];

            if (index != undefined && index != null && !isNaN(index)) {
                sessionStorage.setItem("activeTileEdition", index);
            } else {
                index = 0;
                sessionStorage.setItem("activeTileEdition", 0);
            }
            editions = allEditions.filter(function (el, k) {
                return k == index;
            });
            let editionSEOName = (editions !== undefined && editions.length > 0) ? editions[0].editioN_NAME_SEO : "";

            if (editionSEOName !== "" && editionSEOName !== null) {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_EDITION + "/" + editionSEOName,
                    state: { team: byTeam, edition: byEdition, editionName: editionSEOName }
                });
            } else {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_EDITION + "/association-edition",
                    state: { team: byTeam, edition: byEdition, editionName: "association-edition" }
                });
            }
        }
    }

    const moveToNext = () => {
        var nextteamseo = '';
        if (type == 'edition') {
            nextteamseo = nextTeam.teamNameSEO;
        } else {
            nextteamseo = teamNameSEO;
        }
        var nexteditionseo = '';
        if (type == 'team') {
            nexteditionseo = nextEdition.editioN_NAME_SEO;
        } else {
            nexteditionseo = editionNameSEO;
        }
        //console.log(props.location.state);
        history.push({
            pathname: `${RouteConstants.OUTFIT}/${nextteamseo}/${nexteditionseo}`,
            state: { teamName: nextteamseo, editionName: nexteditionseo, type: type, editionid: editionId, teamid: teamSeoId }
        })
    }
    const moveToPrev = () => {
        var prevteamseo = '';
        if (type == 'edition') {
            prevteamseo = prevTeam.teamNameSEO;
        } else {
            prevteamseo = teamNameSEO;
        }
        var preveditionseo = '';
        if (type == 'team') {
            preveditionseo = prevEdition.editioN_NAME_SEO;
        } else {
            preveditionseo = editionNameSEO;
        }
        //console.log(props.location.state);
        history.push({
            pathname: `${RouteConstants.OUTFIT}/${prevteamseo}/${preveditionseo}`,
            state: { teamName: prevteamseo, editionName: preveditionseo, type: type, editionid: editionId, teamid: teamSeoId }
        })
    }
    // function to set current preview image for Large Popup Slider
    const LargeImageSlider = (tab) => {
        let largeImagesArr = [];
        if (CourtImg !== "") {
            if (tab === "edition")
                largeImagesArr = [EditionCardImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, TowelImg, HatImg, CourtImg];
            else if (tab === "jacket")
                largeImagesArr = [JacketPantImg, LongSleeveImg, ShortSleeveImg, TowelImg, HatImg, CourtImg, EditionImg];
            else if (tab === "longsleeve")
                largeImagesArr = [LongSleeveImg, ShortSleeveImg, TowelImg, HatImg, CourtImg, JacketPantImg, EditionImg];
            else if (tab === "shortsleeve")
                largeImagesArr = [ShortSleeveImg, TowelImg, HatImg, CourtImg, JacketPantImg, LongSleeveImg, EditionImg];
            else if (tab === "towel")
                largeImagesArr = [TowelImg, HatImg, CourtImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, EditionImg];
            else if (tab === "hat")
                largeImagesArr = [HatImg, CourtImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, TowelImg, EditionImg];
            else if (tab === "court")
                largeImagesArr = [CourtImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, TowelImg, HatImg, EditionImg];
            else
                largeImagesArr = [EditionCardImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, TowelImg, HatImg, CourtImg];

        } else {
            if (tab === "edition")
                largeImagesArr = [EditionCardImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, TowelImg, HatImg];
            else if (tab === "jacket")
                largeImagesArr = [JacketPantImg, LongSleeveImg, ShortSleeveImg, TowelImg, HatImg, EditionImg];
            else if (tab === "longsleeve")
                largeImagesArr = [LongSleeveImg, ShortSleeveImg, TowelImg, HatImg, JacketPantImg, EditionImg];
            else if (tab === "shortsleeve")
                largeImagesArr = [ShortSleeveImg, TowelImg, HatImg, JacketPantImg, LongSleeveImg, EditionImg];
            else if (tab === "towel")
                largeImagesArr = [TowelImg, HatImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, EditionImg];
            else if (tab === "hat")
                largeImagesArr = [HatImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, TowelImg, EditionImg];
            else if (tab === "court")
                largeImagesArr = [JacketPantImg, LongSleeveImg, ShortSleeveImg, TowelImg, HatImg, EditionImg];
            else
                largeImagesArr = [EditionCardImg, JacketPantImg, LongSleeveImg, ShortSleeveImg, TowelImg, HatImg];
        }

        setLargeImages(largeImagesArr);
        setDialogOpen(true);
    }

    const handleDialogClose = (tab) => {
        setDialogOpen(false);
    }

    return (
        <Container maxWidth="false">
            {title !== '' && <HelmetMetaData
                title={title}
                quote={"Every Locker, Every Player, Every Game."}
                url={window.location.href}
                hashtag={"#nba"}
                description={description}
                keywords={keywords}
            ></HelmetMetaData>}
            {loading && <CircularLoader />}
            {!loading && <>
                <Grid container spacing={2} alignItems="center">
                    <Grid item sm={3} xs={12}>
                        <Box component="div" display="flex" alignItems="center" mt={3} mb={3} justifyContent="space-between" className={classes.title}>
                            <Box display="flex" alignItems="center" flex="1">
                                <img src={TeamLogoURL} className={classes.teamIcons} alt={`NBA LockerVision ${TeamName}`} title={`NBA LockerVision ${TeamName}`} />
                                <Box ml={1} display="flex" flexDirection="column">
                                    <Typography variant="h5" component="h5">
                                        {TeamName}
                                    </Typography>
                                    <Typography variant="caption" component="p">
                                        {Helper.FormatDayWithDate(gameDate)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                        {AppConstants.DISPLAY_SPONSORS && outfitAdDetails && <>
                            <Hidden smDown>
                                <SponsorAd
                                    adDisplay={AppConstants.AD_DISPLAY_DESKTOP}
                                    adDetails={outfitAdDetails}
                                />
                            </Hidden>
                            <Hidden mdUp>
                                <Box display="flex" alignItems="center" justifyContent="center" flex="1" marginTop="0">
                                    <SponsorAd
                                        adDisplay={AppConstants.AD_DISPLAY_MOBILE}
                                        adDetails={outfitAdDetails}
                                    />
                                </Box>
                            </Hidden>
                        </>
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={5} className={classes.mainContainer}>
                    <Grid item spacing={5} xs={12} sm={12} className={classes.leftrightgrid}>
                        <Grid item spacing={5} xs={3} sm={3}>
                            <Box>
                                <Chip variant="outlined"
                                    label={type == 'team' ? 'Teams' : 'Editions'}
                                    icon={<ArrowBackIcon style={{ fontSize: 17, color: '#8f8f8f', marginLeft: '8px' }} />}
                                    className={[classes.backchip, classes.backchipspan]}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        backToDashboard();
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item spacing={5} xs={9} sm={9}>
                            <Box className={[classes.leftrightarrow, classes.rightchip]}>
                                <Button
                                    className={[classes.leftrightchip, classes.rightpaddingchip]}
                                    variant="outlined"
                                    startIcon={<ArrowLeftIcon style={{ fontSize: 30, color: '#8f8f8f' }} />}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        moveToPrev();
                                    }}>
                                    Prev &nbsp;<span className={classes.spantext}>{type == 'team' ? 'Edition' : 'Team'}</span>
                                </Button>
                                <Button
                                    className={[classes.leftrightchip, classes.leftpaddingchip]}
                                    variant="outlined"
                                    endIcon={<ArrowRightIcon style={{ fontSize: 30, color: '#8f8f8f' }} />}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        moveToNext();
                                    }}>
                                    Next  &nbsp;<span className={classes.spantext}>{type == 'edition' ? 'Team' : 'Edition'}</span>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card className={classes.cardRoot}>
                            <Box display="flex" >
                                <CardActions className={classes.cardAction}>
                                    <Box paddingBottom={1} marginBottom={1} display="flex">
                                        {/* <Link> */}
                                        <SocialMediaContainer pageName="Outfit" name="Outfit" teamName={TeamName}
                                            date={Helper.FormatDayWithDate(gameDate)} img={EditionImg}
                                            editionname={EditionName}
                                            teamNameSEO={teamNameSEO} editionNameSEO={editionNameSEO} />
                                        {/* </Link> */}
                                        {/* <Link>
                                        <FavoriteBorderIcon />
                                    </Link> */}
                                    </Box>
                                </CardActions>
                            </Box>
                            <CardMedia className={classes.cardParentMedia}>
                                <img src={EditionImg}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        LargeImageSlider("edition");
                                    }} alt={`NBA LockerVision ${TeamName} ${EditionName}`} title={`NBA LockerVision ${TeamName} ${EditionName}`} />
                            </CardMedia>
                        </Card>
                        {CourtName !== '' ?
                            <Card className={`${classes.cardRoot} ${classes.marginTop} ${classes.paddingBottom}`}>
                                <CardMedia className={classes.cardMedia}>
                                    <img src={CourtImg} onClick={(e) => {
                                        e.preventDefault();
                                        LargeImageSlider("court");
                                    }} alt={`NBA LockerVision ${TeamName} Court ${CourtName}`} title={`NBA LockerVision ${TeamName} Court ${CourtName}`} />
                                </CardMedia>
                                <Box display="flex" justifyContent="center" className={classes.customChipBottom} >
                                    <Chip label={CourtName}
                                        style={{ background: BGColor, color: TextColor }} />
                                </Box>
                            </Card>
                            :
                            null
                        }
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} sm={12}>

                                    <Box paddingBottom={1} mb={1} className={classes.headerSection}>
                                        <Box className={classes.topHeading}>
                                            <Box flexDirection="row">
                                                <Typography component="h6" variant="h6">UNIFORM DETAILS</Typography>
                                                <Typography component="h4" variant="subtitle">{Helper.GetEditionName(EditionName)}</Typography>
                                                {videoLink !== null && videoLink !== '' &&
                                                    play ? <Box flexDirection="row" className={classes.playThumbWrap}>
                                                        <Box title={`Play: ${AppConstants.SEASON_YEAR} Nike NBA ${EditionName} Uniform Unveil Video`} className={classes.playThumb} onClick={() => {
                                                            setPlay(false);
                                                        }} >
                                                            <PlayCircleOutlineIcon style={{ fontSize: 72, color: '#8f8f8f', 'margin-top': '14px' }} />
                                                        </Box>
                                                        <Typography component="h4" variant="subtitle" style={{ 'margin-left': '12px' }}>{`Play: ${AppConstants.SEASON_YEAR} Nike NBA`}< br />{`${EditionName} Uniform Unveil Video`}</Typography>
                                                    </Box> : <>
                                                    </>}
                                            </Box>
                                            <Box>
                                                {/* <Chip variant="outlined"
                                                    label=""
                                                    icon={<ArrowBackIcon style={{ fontSize: 17, color: '#8f8f8f', 'margin-left': '12px' }} />}
                                                    className={classes.backchip}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        backToDashboard();                                                
                                                    }}
                                                /> */}
                                                {/* <Chip variant="outlined"
                                                    label=""
                                                    icon={<ArrowForwardIcon style={{ fontSize: 17, color: '#8f8f8f', 'margin-left': '12px' }} />}
                                                    className={classes.backchip}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        moveToNext();
                                                    }} 
                                                />                                   */}
                                                <PreviewShopIcon teamName={TeamName} />
                                            </Box>
                                        </Box>
                                        {!play &&
                                            <>
                                                <Box display="flex" justifyContent="space-between" flex="1" p={1}>
                                                    <Typography variant="subtitle2" className={classes.textColor} title={`${AppConstants.SEASON_YEAR} Nike NBA ${EditionName} Uniform Unveil Video`} >{AppConstants.SEASON_YEAR} Nike NBA {TeamName} ({EditionName}) Uniform Unveil Video</Typography>
                                                    <IconButton aria-label="close" size="small" className={classes.textColor}
                                                        title={`Click to hide the Unveil Video`}
                                                        onClick={() => {
                                                            setPlay(true);
                                                        }} >
                                                        <HighlightOffIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box className={classes.playerWrapper}>
                                                    <ReactPlayer
                                                        className={classes.reactPlayer}
                                                        playing={true}
                                                        controls={true}
                                                        // light={true}
                                                        url={videoLink}
                                                        width="100%"
                                                        height="100%"
                                                        title={`${AppConstants.SEASON_YEAR} Nike NBA ${EditionName} Uniform Unveil Video`}
                                                    />
                                                </Box></>}
                                        <Box className={classes.textColor}>
                                            <Typography variant="body1" component="p">
                                                <div dangerouslySetInnerHTML={{ __html: EditionStory }} />
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.topHeading}>
                                        <Typography component="h5">SEE OTHER GAME OUTFITTING</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} justifyContent="center">
                                <Grid item sm={4} xs={12}>
                                    <Card className={classes.cardRoot}>
                                        <Box display="flex" justifyContent="center" className={classes.customChip} >
                                            <Chip label={JacketPantName}
                                                style={Helper.checkForComingSoon(JacketPantImg) ? { background: "black", color: "white" } : { background: BGColor, color: TextColor }} />
                                        </Box>
                                        <Box>
                                            <CardActions className={classes.cardAction}>
                                                <Link>
                                                    <SocialMediaContainer pageName="Outfit" name="Jacket/Pant" teamName={TeamName}
                                                        date={Helper.FormatDayWithDate(gameDate)} img={JacketPantImg}
                                                        editionname={JacketPantName}
                                                        teamNameSEO={teamNameSEO} editionNameSEO={editionNameSEO} />
                                                </Link>
                                                {/* <Link>
                                                <FavoriteBorderIcon />
                                            </Link> */}
                                            </CardActions>
                                        </Box>
                                        <CardMedia className={classes.cardMedia}>
                                            <img src={JacketPantImg}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    LargeImageSlider("jacket");
                                                }} alt={`NBA LockerVision ${TeamName} Jacket/Pant ${JacketPantName}`} title={`NBA LockerVision ${TeamName} Jacket/Pant ${JacketPantName}`} />

                                        </CardMedia>
                                        <CardContent>
                                            <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                JACKET/PANT
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <Card className={classes.cardRoot}>
                                        <Box display="flex" justifyContent="center" className={classes.customChip} >
                                            <Chip label={LongSleeveName} style={Helper.checkForComingSoon(LongSleeveImg) ? { background: "black", color: "white" } : { background: BGColor, color: TextColor }} />
                                        </Box>
                                        <Box>
                                            <CardActions className={classes.cardAction}>
                                                <Link>
                                                    <SocialMediaContainer pageName="Outfit" name="Long Sleeve" teamName={TeamName}
                                                        date={Helper.FormatDayWithDate(gameDate)} img={LongSleeveImg}
                                                        editionname={LongSleeveName}
                                                        teamNameSEO={teamNameSEO} editionNameSEO={editionNameSEO} />
                                                </Link>
                                                {/* <Link>
                                                <FavoriteBorderIcon />
                                            </Link> */}
                                            </CardActions>
                                        </Box>
                                        <CardMedia className={classes.cardMedia}>
                                            <img src={LongSleeveImg} onClick={(e) => {
                                                e.preventDefault();
                                                LargeImageSlider("longsleeve");
                                            }} alt={`NBA LockerVision ${TeamName} Long Sleeve ${LongSleeveName}`} title={`NBA LockerVision ${TeamName} Long Sleeve ${LongSleeveName}`} />
                                        </CardMedia>

                                        <CardContent>
                                            <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                LONG SLEEVE
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <Card className={classes.cardRoot}>
                                        <Box display="flex" justifyContent="center" className={classes.customChip} >
                                            <Chip label={ShortSleeveName}
                                                style={Helper.checkForComingSoon(ShortSleeveImg) ? { background: "black", color: "white" } : { background: BGColor, color: TextColor }} />
                                        </Box>
                                        <Box>
                                            <CardActions className={classes.cardAction}>
                                                <Link>
                                                    <SocialMediaContainer pageName="Outfit" name="Short Sleeve" teamName={TeamName}
                                                        date={Helper.FormatDayWithDate(gameDate)} img={ShortSleeveImg}
                                                        editionname={ShortSleeveName}
                                                        teamNameSEO={teamNameSEO} editionNameSEO={editionNameSEO} />
                                                </Link>
                                                {/* <Link>
                                                <FavoriteBorderIcon />
                                            </Link> */}
                                            </CardActions>
                                        </Box>
                                        <CardMedia className={classes.cardMedia}>
                                            <img src={ShortSleeveImg} onClick={(e) => {
                                                e.preventDefault();
                                                LargeImageSlider("shortsleeve");
                                            }} alt={`NBA LockerVision ${TeamName} Short Sleeve ${ShortSleeveName}`} title={`NBA LockerVision ${TeamName} Short Sleeve ${ShortSleeveName}`} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                SHORT SLEEVE
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <Card className={classes.cardRoot}>
                                        <Box display="flex" justifyContent="center" className={classes.customChip} >
                                            <Chip label={TowelName}
                                                style={Helper.checkForComingSoon(TowelImg) ? { background: "black", color: "white" } : { background: BGColor, color: TextColor }} />
                                        </Box>
                                        <Box>
                                            <CardActions className={classes.cardAction}>
                                                <Link>
                                                    <SocialMediaContainer pageName="Outfit" name="Towel" teamName={TeamName}
                                                        date={Helper.FormatDayWithDate(gameDate)} img={TowelImg}
                                                        editionname={TowelName}
                                                        teamNameSEO={teamNameSEO} editionNameSEO={editionNameSEO} />
                                                </Link>
                                                {/* <Link>
                                                <FavoriteBorderIcon />
                                            </Link> */}
                                            </CardActions>
                                        </Box>
                                        <CardMedia className={classes.cardMedia}>
                                            <img src={TowelImg} onClick={(e) => {
                                                e.preventDefault();
                                                LargeImageSlider("towel");
                                            }} alt={`NBA LockerVision ${TeamName} Towel ${TowelName}`} title={`NBA LockerVision ${TeamName} Towel ${TowelName}`} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                TOWEL
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <Card className={classes.cardRoot}>
                                        <Box display="flex" justifyContent="center" className={classes.customChip} >
                                            <Chip label={HatName}
                                                style={Helper.checkForComingSoon(HatImg) ? { background: "black", color: "white" } : { background: BGColor, color: TextColor }} />
                                        </Box>
                                        <Box>
                                            <CardActions className={classes.cardAction}>
                                                <Link>
                                                    <SocialMediaContainer pageName="Outfit" name="Hat" teamName={TeamName}
                                                        date={Helper.FormatDayWithDate(gameDate)} img={HatImg}
                                                        editionname={HatName}
                                                        teamNameSEO={teamNameSEO} editionNameSEO={editionNameSEO} />
                                                </Link>
                                                {/* <Link>
                                                <FavoriteBorderIcon />
                                            </Link> */}
                                            </CardActions>
                                        </Box>
                                        <CardMedia className={classes.cardMedia}>
                                            <img src={HatImg} onClick={(e) => {
                                                e.preventDefault();
                                                LargeImageSlider("hat");
                                            }} alt={`NBA LockerVision ${TeamName} Hat ${HatName}`} title={`NBA LockerVision ${TeamName} Hat ${HatName}`} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography variant="caption" component="p" align="center" className={classes.outfitText}>
                                                HAT
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </>}
            <Dialog onClose={handleDialogClose} className={classes.DialogRoot} aria-labelledby="simple-dialog-title" open={isDialogOpen}>
                <MuiDialogTitle disableTypography>
                    <Box display="flex" justifyContent="space-between" flex="1" p={1}>
                        <Hidden>
                            <Typography variant="subtitle2" className={classes.DialogHeader}>{TeamName} <br />({EditionName})</Typography>
                        </Hidden>
                        <IconButton aria-label="close" size="small" className={classes.closeButton}
                            onClick={(data) => {
                                handleDialogClose();
                            }} >
                            <HighlightOffIcon />
                        </IconButton>
                    </Box>

                </MuiDialogTitle>

                <ImageSlider images={LargeImages} />
            </Dialog>

        </Container>
    );
}

export default withRouter(OutfitPreviewFav);