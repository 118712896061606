const AppConstants = {
    QA_URL: window.location.origin,
    APP_NAME: 'LockerVision',
    WIDGET_API_KEY: 'ZfVlypp6UydS1Jmjb3Ih9dGjxp4ZnKGn',
    WIDGET_API_ID: 'LOCKERVISION_EN_US',
    WIDGET_ENV: 'prod',
    WIDGET_MODEL: true,
    MODEL: false,
    DISPLAY_SPONSORS: true,
    START_DATE_FILTER: 'StartDateFilter',
    END_DATE_FILTER: 'EndDateFilter',
    TEAM_FILTER: 'TeamFilter',
    GAME_TYPE_FILTER: 'GameTypeFilter',
    ACTIVE_TILE: 'activeTile',
    TILES_POSITION: 'tilesPosition',
    ALL_TEAMS: 'All Teams',
    ALL_GAMES: 'All Games',
    THUMBNAIL_KEY: 'Transformation_Thumbnail',
    CARD_KEY: 'Transformation_Card',
    HAT_KEY: 'Transformation_Hat',
    COURT_KEY: 'Transformation_Court',
    TOWEL_KEY: 'Transformation_Towel',
    BASKETBALL_KEY: 'Transformation_Basketball',
    DEFAULT_KEY:'Transformation_Default',
    SEASON_YEAR: '2021-22',
    REF_HEADER: '71a3fa62-64c0-4edf-ad80-60e0772a7474',
    AD_DISPLAY_DESKTOP: 'DESKTOP',
    AD_DISPLAY_MOBILE: 'MOBILE',
}

export default AppConstants;