import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, } from '@material-ui/core';
import { GetGame } from "../../services/home.service";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "nowrap",
    },
    calenderSlider: {
        height: theme.spacing(20.5),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 3.5)
        },
        '& .slick-slider': {
            height: "100%"
        },
    }
}));

const Featured = () => {
    const classes = useStyles();
    const [Initial, setInitial] = useState(0);
    const [Infinite,setInfinite] = useState(false);
    const settings = {
        infinite: Infinite,
        speed: 500,
        slidesToShow: 11,
        slidesToScroll: 1,
        initialSlide: Initial,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 1,
                    infinite: Infinite,
                    initialSlide: Initial,
                    centerMode: false,
                }
            },
            {
                breakpoint: 823,
                settings: {
                    infinite: Infinite,
                    slidesToShow: 7,
                    slidesToScroll: 1,
                    initialSlide: Initial,
                    centerMode: false,

                }
            },
            {
                breakpoint: 812,
                settings: {
                    infinite: Infinite,
                    slidesToShow: 7,
                    slidesToScroll: 1,
                    initialSlide: Initial,
                    centerMode: false,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    infinite: Infinite,
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    initialSlide: Initial,
                    centerMode: false,
                }
            },
            {
                breakpoint: 720,
                settings: {
                    infinite: Infinite,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    initialSlide: Initial,
                    centerMode: false,
                }
            },
            {
                breakpoint: 667,
                settings: {
                    infinite: Infinite,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    initialSlide: Initial,
                    centerMode: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    infinite: Infinite,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: Initial,
                    centerMode: false,
                }
            },
            {
                breakpoint: 568,
                settings: {
                    infinite: Infinite,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    initialSlide: Initial,
                    centerMode: false,

                }
            },
            {
                breakpoint: 540,
                settings: {
                    infinite: Infinite,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    initialSlide: Initial,
                    centerMode: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: Infinite,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: Initial,
                    centerMode: false,

                }
            }
        ]
    }
    return (
        <Container className={classes.calenderSlider}>
            <Slider {...settings}>
                
            </Slider>
        </Container>
    )
}
export default Featured;