import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Helper from './../common/Helper';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 235,
        paddingBottom: '8px',
        "& .MuiInput-root .MuiInputBase-input": {
            color: '#8f8f8f',
            colorScheme: 'dark',
        },
        "& .MuiFormLabel-root": {
            color: '#8f8f8f',
        },
        '& .MuiInput-underline': {
            "&:before": {
                borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
            },
            "&:after": {
                borderBottom: '2px solid #fff',
            },
        },
    },
}));

export default function MUIDatePicker(props) {
    const classes = useStyles();

    const onDateChange = (date) => {
        props.onDateChange(date);
    }

    return (
        <form className={classes.container} noValidate>
            <TextField
                id={props.id}
                label={props.label}
                type="date"
                onChange={onDateChange}                
                defaultValue={Helper.FormatDateDashYYYYMMDD(props.date)}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </form>
    );
}
