import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SocialMediaShareUI from '../SocialMedia/SocialMediaShareUI';
import Helper from '../../common/Helper';
import RouteConstants from '../../common/RouteConstants';
import AppDataContext from '../../common/AppContext';
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialog-paper': {
            background: 'rgba(255, 255, 255, .5)',
            padding: theme.spacing(1, 1.5),
            borderRadius: theme.spacing(2),
            '& .MuiDialogTitle-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: 'rgba(255, 255, 255, .5)',
                padding: theme.spacing(0),
                '& .MuiIconButton-root': {
                    color: 'rgba(255, 255, 255, .5)',
                },
            },
        },
    },
    shareicon: {
        color: '#FFF',
    },
    shareiconHide: {
        display: 'none',
    },
    shareIcons: {
        padding: theme.spacing(2, 1),
        '& button svg': {
            borderRadius: theme.spacing(1.25),
        },
        '& button + button': {
            marginLeft: theme.spacing(2),
        },
    },

}));

function SocialMediaContainer(props) {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const name = props.name;
    const [editionName, setEditionName] = useState(props.editionname);
    const [awayTeamName, setAwayTeamName] = useState(props.awayTeamName);
    const [teamName, setTeamName] = useState(props.teamName);
    const [teamNameSEO, setTeamNameSEO] = useState(props.teamNameSEO);
    const [editionNameSEO, setEditionNameSEO] = useState(props.editionNameSEO);
    const [img, setImg] = useState(props.img);
    const [date, setDate] = useState(props.date);

    const [GameIDForShare, setGameIDForShare] = useState(props.GameIDForShare);
    const [content, setContent] = useState("");
    const [hide, setHide] = useState(false);
    const TeamIDForShare = props.TeamIDForShare;
    const { allTeams, allEditions } = useContext(AppDataContext);

    const [shareURL, setShareURL] = useState(null);
    let QueryData = props.location.pathname.split("/");
    const checkForHide = (imgString) => {
        return Helper.checkForComingSoon(imgString);
    }
    useEffect(() => {
        if (props.GameIDForShare)
            setGameIDForShare(props.GameIDForShare);
        if (props.awayTeamName)
            setAwayTeamName(props.awayTeamName);
        if (!props.teamNameSEO && !props.editionNameSEO) {
            // getSEONames();
        }
        else {
            setTeamNameSEO(props.teamNameSEO);
            setEditionNameSEO(props.editionNameSEO);
        }
        setEditionName(props.editionname);
        setDate(props.date);
        setTeamName(props.teamName);
    },
        [props.GameIDForShare, props.editionName, props.date, props.awayTeamName, props.editionNameSEO, props.teamNameSEO, props.teamName])

    useEffect(() => {
        getContent(props.name, props.pageName);
        getSEONames();
    },
        [teamName])
    useEffect(() => {
        setImg(props.img);
        if (checkForHide(props.img))
            setHide(true);
        else setHide(false);
    }, [props.img])

    useEffect(() => {
        setupShareURL();
    }, [teamNameSEO, editionNameSEO, GameIDForShare])

    const setupShareURL = () => {
        // console.log("props.location", props.location)
        if (props.location.pathname.includes(`${RouteConstants.GAME_OUTFIT}`)) {
            if (QueryData.length >= 2)
                setShareURL(window.location.origin + `${RouteConstants.GAME_OUTFIT}/` + encodeURI(teamNameSEO + `?gameid=${GameIDForShare}`));
            else
                setShareURL(window.location.href + "/" + encodeURI(teamNameSEO + `?gameid=${GameIDForShare}`));
        }
        else if (props.location.pathname.includes(`${RouteConstants.OUTFIT}`))
        {
            setShareURL(window.location.origin + `${RouteConstants.OUTFIT}/` + encodeURI(teamNameSEO + "/" + editionNameSEO));
        }
        else if (props.location.pathname.includes(`${RouteConstants.ALL_STAR_GAME_OUTFIT}`))
        {
            if (QueryData.length >= 2) {
                setShareURL(window.location.origin + `${RouteConstants.ALL_STAR_GAME_OUTFIT}/` + encodeURI(teamNameSEO + `?gameid=${GameIDForShare}`));
            }
            else {
                setShareURL(window.location.href + "/" + encodeURI(teamNameSEO + `?gameid=${GameIDForShare}`));
            }                
        }
        else if (props.location.pathname.includes(`${RouteConstants.RISING_STAR_GAME_OUTFIT}`))
        {
            console.log(window.location.origin + `${RouteConstants.RISING_STAR_GAME_OUTFIT}`);
            setShareURL(window.location.origin + `${RouteConstants.RISING_STAR_GAME_OUTFIT}`);
        }
        else if (props.location.pathname.includes('team'))
        {
            setShareURL(window.location.origin + `${RouteConstants.OUTFIT}/` + encodeURI(teamNameSEO + "/" + editionNameSEO));

        } else if (props.location.pathname.includes('edition')) {
            setShareURL(window.location.origin + `${RouteConstants.OUTFIT}/` + encodeURI(teamNameSEO + "/" + editionNameSEO));
        }
    }

    const getSEONames = () => {
        let teams = allTeams.filter(function (el) {
            return el.teamName == teamName;
        });
        let editions = allEditions.filter(function (el) {
            return el.editioN_NAME == editionName;
        });

        let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";
        let editionSEOName = (editions !== undefined && editions.length > 0) ? editions[0].editioN_NAME_SEO : "";
        if (teamSEOName)
            setTeamNameSEO(teamSEOName)
        if (editionSEOName)
            setEditionNameSEO(editionSEOName)
    }
    const getContent = (param, pageName) => {
        if (pageName !== undefined) {
            if (pageName === "Outfit") {
                setContent(`Check out the ${teamName} ${param} ${editionName} uniform on NBA LockerVision\n`)
            }
            else if (pageName === "gameOutfit" || pageName === "allStarOutfitPreview") {
                setContent(`Check out NBA LockerVision to see what the ${teamName} are wearing against the ${awayTeamName} ${Helper.GetPresentDate() == date ? "today" : `On ${date}`}\n`)
            }
            else if (pageName === "risingStarOutfitPreview") {
                setContent(`Check out the ${editionName} uniform on NBA LockerVision\n`)
            }
        } else {
            if (param === "Uniform Edition") {
                setContent(`Check out the ${teamName} ${editionName} uniform on NBA LockerVision\n`)
            } else {
                setContent(`Check out the ${teamName} ${param} ${editionName} uniform on NBA LockerVision\n`)
            }
        }
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        //history.goBack();
        popupClose();
    };

    const popupClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton
                className={hide ? classes.shareiconHide : classes.shareicon}
                onClick={(e) => {
                    e.preventDefault();
                    handleOpen();
                }}
                data-gaeventname="product_share"
                data-gaeventcategory="Share"
                data-gaeventlabel={`${content}`}
                data-gaeventaction="Open Social Media Share Dialog"
            >
                {props.iconColor && <ShareIcon style={{ 'color': `${props.iconColor}` }} />}
                {!props.iconColor && <ShareIcon />}
            </IconButton>


            <Dialog onClose={(e) => {
                e.preventDefault();
                handleClose();
            }} className={classes.root} aria-labelledby="simple-dialog-title" open={open}>
                <MuiDialogTitle disableTypography>
                    <Typography variant="caption">SHARE</Typography>
                    <IconButton aria-label="close" size="small" className={classes.closeButton} onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                    }}>
                        <HighlightOffIcon />
                    </IconButton>
                </MuiDialogTitle>

                <div className={classes.shareIcons}>
                    <SocialMediaShareUI mediaFile={img} content={content}
                        page={window.location.pathname} shareURL={shareURL} />
                </div>

            </Dialog>

        </div>
    );
}
export default withRouter(SocialMediaContainer);