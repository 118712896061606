import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Container, } from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from 'react-router-dom';
import GameTile from './GameTile';
import RouteConstants from '../../common/RouteConstants';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "nowrap",
    },
    gameSlider: {
        color: '#ffffff !important',
        height: theme.spacing(40.5),
        // height: theme.spacing(37),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 2.25)
        },
        '& .slick-slider': {
            height: "100%"
        },
    },
    viewAll: {
        textAlign: "center",
        justifyContent: "center !important",
        cursor: "pointer",
    },
    cardRoot: {
        background: 'rgba(87, 87, 87, .5) !important',
        border: '1px solid rgba(255, 255, 255, .3)',
        boxShadow: 'none',
        height: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: theme.spacing(1.25),
        width: '100%',
        margin: theme.spacing(0, 1.5),
        '& .MuiCardHeader-root': {
            padding: theme.spacing(0, 2, 1, 2),
        },
        '& .MuiCardContent-root': {
            padding: theme.spacing(1, 2),
        },
        '& .MuiCardHeader-avatar': {
            display: 'flex',
            width: '100%',
            marginRight: '0',
        },
    },
    versus: {
        fontFamily: '"Action NBA  Web" !important',
        fontWeight: '500 !important',
        color: '#fff !important',
    },

}));

const GameSlider = (props) => {
    const classes = useStyles();
    const [Games, setGames] = useState([]);
    const settings = {
        infinite: false,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 1000,

                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 1000,
                }
            },
            {
                breakpoint: 823,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 812,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 740,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 720,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 667,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 568,
                settings: {
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,

                }
            },
            {
                breakpoint: 540,
                settings: {
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    speed: 500,

                }
            }
        ]
    }

    useEffect(() => {
        setGames(props.Games);
    }, [props.Games]);

    const pushToScheduleRoute = (param) => {
        props.history.push({
            pathname: RouteConstants.SCHEDULE
        });
    }

    return (
        <Container maxWidth={false} className={`${classes.gameSlider} gameSlider`}>
            {Games !== undefined && Games.length > 0 ?
                <Slider {...settings}>
                    {Games.map((item, index) => {
                        return <>
                                <GameTile item={item} xScroll={props.xScroll} />
                            </>
                    })}
                    <Card className={`${classes.cardRoot} ${classes.viewAll}`}>
                        <CardContent>
                            <span onClick={() => {
                                pushToScheduleRoute();
                                //calling props function to change highlight in header
                                props.highlightInHeader();
                            }}>
                                <CardContent>
                                    <Typography variant="h4" className={classes.versus}>
                                        VIEW ALL
                                    </Typography>
                                </CardContent>
                            </span>
                        </CardContent>
                    </Card>
                </Slider>
                : null
            }
        </Container>
    )
}

export default withRouter(GameSlider);