import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from 'react-router-dom';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { CardActionArea, CardActions, Box, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { GetUniformDetails } from "../../services/uniform.service";
import AppDataContext from '../../common/AppContext';

import AppConstants from '../../common/AppConstants';

import Helper from '../../common/Helper';
import SponsorAd from '../../sharedComponents/SponsorContainer/SponsorAd';
import LoadingAppUI from '../../sharedComponents/emptyStateUIContainers/LoadingAppUI';
import CircularLoader from '../../sharedComponents/emptyStateUIContainers/CircularLoader';
import TeamShopIcon from '../../sharedComponents/TeamShopIcon/TeamShopIcon';
import SocialMediaContainer from '../../sharedComponents/SocialMedia/SocialMediaContainer';
import OutfitIcon from '../../sharedComponents/CustomIcons/OutfitIcon';
import HelmetMetaData from '../../sharedComponents/SocialMedia/HelmetMetaDeta';
import RouteConstants from '../../common/RouteConstants';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(5),
    },

    mainContainer: {
        background: 'rgba(0, 0, 0, .65) !important',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(0),
    },

    cardRoot: {
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
        height: '100%',
        position: 'relative',
        paddingTop: theme.spacing(1),
    },
    cardRootHat: {
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
        height: '100%',
        position: 'relative',
        paddingTop: theme.spacing(1),
        '& .MuiCardActionArea-root': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
    },
    chip: {
        fontSize: '.63rem',
        letterSpacing: '0.0500em',
        color: '#8f8f8f !important',
        borderColor: '#8f8f8f !important',
        margin: theme.spacing(.5, .5, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        '& .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1, 0, 1)
            },
        },
    },
    backchip: {
        width: '50px',
        fontSize: '.63rem',
        color: '#8f8f8f',
        borderColor: '#8f8f8f !important',
        margin: theme.spacing(.5, .5, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        '& .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1, 0, 1)
            },
        },
    },
    cardAction: {
        position: 'absolute',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'space-between',
        left: '0',
        right: '0',
        top: '0',
        '& a, & a:hover, & a:focus': {
            color: '#e5dfcd',
        },
    },

    customChip: {
        position: 'absolute',
        zIndex: '1',
        top: '0',
        width: '100%',
        '& .MuiChip-root': {
            height: 'auto',
            padding: '2px 0',
            fontSize: '.78rem',
            fontWeight: '500',
            border: '1px solid #b5b5b5 !important',
            '& .MuiChip-label': {
                paddingLeft: theme.spacing(.75),
                paddingRight: theme.spacing(.75),
            },
        },
    },
    cardMedia: {
        display: 'flex',
        background: '#000000 !important',
        border: '1px solid #888888',
        padding: '0',
        borderRadius: theme.spacing(.5),
        '& img': {
            height: '100%',
            width: '100%',
            borderRadius: theme.spacing(.5),
        },
    },
    cardMediaHat: {
        display: 'flex',
        background: '#000000 !important',
        border: '1px solid #888888',
        padding: '0',
        borderRadius: theme.spacing(.5),
        height: '100%',
        '& img': {
            height: '100%',
            width: '100%',
            borderRadius: theme.spacing(.5),
            objectFit: 'contain',
        },
    },
    outfitText: {
        color: '#b5b5b5 !important',
        textTransform: 'uppercase',
    },
    teamIcons: {
        maxWidth: '85px',
    },
    title: {
        flexGrow: 1,
        '& .MuiChip-clickable': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        '& > div:nth-child(2)': {
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
            },
        },
        '& h5': {
            fontFamily: '"Action NBA  Web"',
            color: '#b5b5b5',
        },
        '& p': {
            color: '#b5b5b5 !important',
        },
    },
    backDashboard: {
        border: '1px solid #fff !important',
        color: '#fff',
        fontWeight: '300',
        fontSize: '.63rem',
        marginTop: '5px',
    },
}));

function GameOutfitContainer(props) {
    const classes = useStyles();
    let temp = decodeURI(window.location.pathname);
    let QueryData = temp.split("/");
    const { allTeams } = useContext(AppDataContext);
    const [gameId, setGameId] = useState((props.location !== undefined && props.location.state !== undefined) ? props.location.state.gameId : QueryData[2]);
    const [teamId, setTeamId] = useState((props.location !== undefined && props.location.state !== undefined) ? props.location.state.teamId : QueryData[3]);

    const [outfitHomeTeamData, setOutfitHomeData] = useState();
    const [outfitAwayTeamData, setOutfitAwayData] = useState();


    const [gameDate, setGameDate] = useState('');
    const [gameTime, setGameTime] = useState('');
    const [getLoadingloading, setLoadingStatus] = useState(true);
    const [opositeTeamType, setOpositeTeamType] = useState('');
    const [oppositeTeamId, setOppositeTeamId] = useState('');

    const [homeTeamId, setHomeTeamId] = useState('');
    const [homeTeamName, setHomeTeamName] = useState('');
    const [homeTeamLogoURL, setHomeTeamLogoURL] = useState('');
    const [homeBGColor, setHomeBGColor] = useState('');
    const [homeTextColor, setHomeTextColor] = useState('');
    const [homeShopImgUrl, setHomeShopImgUrl] = useState('');
    const [homeCourtName, setHomeCourtName] = useState('');
    const [homeCourtImg, setHomeCourtImg] = useState('');
    const [homeEditionName, setHomeEditionName] = useState('');
    const [homeEditionImg, setHomeEditionImg] = useState('');
    const [homeJacketPantName, setHomeJacketPantName] = useState('');
    const [homeJacketPantImg, setHomeJacketPantImg] = useState('');
    const [homeLongSleeveName, setHomeLongSleeveName] = useState('');
    const [homeLongSleeveImg, setHomeLongSleeveImg] = useState('');
    const [homeShortSleeveName, setHomeShortSleeveName] = useState('');
    const [homeShortSleeveImg, setHomeShortSleeveImg] = useState('');
    const [homeHatName, setHomeHatName] = useState('');
    const [homeHatImg, setHomeHatImg] = useState('');
    const [homeTowelName, setHomeTowelName] = useState('');
    const [homeTowelImg, setHomeTowelImg] = useState('');


    const [awayTeamId, setAwayTeamId] = useState('');
    const [awayTeamName, setAwayTeamName] = useState('');
    const [awayTeamLogoURL, setAwayTeamLogoURL] = useState('');
    const [awayBGColor, setAwayBGColor] = useState('');
    const [awayTextColor, setAwayTextColor] = useState('');
    const [awayShopImgUrl, setAwayShopImgUrl] = useState('');
    const [awayCourtName, setAwayCourtName] = useState('');
    const [awayCourtImg, setAwayCourtImg] = useState('');
    const [awayEditionName, setAwayEditionName] = useState('');
    const [awayEditionImg, setAwayEditionImg] = useState('');
    const [awayJacketPantName, setAwayJacketPantName] = useState('');
    const [awayJacketPantImg, setAwayJacketPantImg] = useState('');
    const [awayLongSleeveName, setAwayLongSleeveName] = useState('');
    const [awayLongSleeveImg, setAwayLongSleeveImg] = useState('');
    const [awayShortSleeveName, setAwayShortSleeveName] = useState('');
    const [awayShortSleeveImg, setAwayShortSleeveImg] = useState('');
    const [awayHatName, setAwayHatName] = useState('');
    const [awayHatImg, setAwayHatImg] = useState('');
    const [awayTowelName, setAwayTowelName] = useState('');
    const [awayTowelImg, setAwayTowelImg] = useState('');
    const [sponsorsData, setSponsorsData] = useState([]);


    useEffect(() => {
        getUniformDetail();
    }, [])

    const getUniformDetail = () => {
        GetUniformDetails(gameId).then(data => {
            setLoadingStatus(false);
            if (data !== undefined) {
                setGameDate(data.gameDate);
                setGameId(data.gameId);
                setGameTime(data.gameTime);
                if (data.awayTeamUniform !== undefined && data.awayTeamUniform.teamId === teamId) {
                    setOpositeTeamType("2");
                    setOppositeTeamId((data["homeTeamUniform"].teamId !== undefined) ? data["homeTeamUniform"].teamId : "");
                }
                if (data.homeTeamUniform !== undefined && data.homeTeamUniform.teamId === teamId) {
                    setOpositeTeamType("1");
                    setOppositeTeamId((data["awayTeamUniform"].teamId !== undefined) ? data["awayTeamUniform"].teamId : "");
                }
                let homeTeamPreviewData = {};
                homeTeamPreviewData.gameId = data.gameId;
                homeTeamPreviewData.teamId = teamId;
                homeTeamPreviewData.gamedate = data.gameDate;
                homeTeamPreviewData.uniformData = data.homeTeamUniform;
                setOutfitHomeData(homeTeamPreviewData);

                setHomeTeamId((data["homeTeamUniform"].teamId !== undefined) ? data["homeTeamUniform"].teamId : "");
                setHomeTeamName((data["homeTeamUniform"].teamName !== undefined) ? data["homeTeamUniform"].teamName : "");
                setHomeTeamLogoURL((data["homeTeamUniform"].teamLogoURL !== undefined) ? data["homeTeamUniform"].teamLogoURL : "");
                setHomeBGColor((data["homeTeamUniform"].bG_Color !== undefined) ? data["homeTeamUniform"].bG_Color : "");
                setHomeTextColor((data["homeTeamUniform"].text_Color !== undefined) ? data["homeTeamUniform"].text_Color : "");
                setHomeShopImgUrl((data["homeTeamUniform"].shop_Img_Url !== undefined) ? data["homeTeamUniform"].shop_Img_Url : "");
                setHomeCourtName((data["homeTeamUniform"].court_Name !== undefined) ? data["homeTeamUniform"].court_Name : "");
                setHomeCourtImg((data["homeTeamUniform"].court_Img_Url !== undefined) ? data["homeTeamUniform"].court_Img_Url : "");
                setHomeEditionName((data["homeTeamUniform"].edition_Name !== undefined) ? data["homeTeamUniform"].edition_Name : "");
                setHomeEditionImg((data["homeTeamUniform"].edition_Img_Url !== undefined) ? data["homeTeamUniform"].edition_Img_Url : "");
                setHomeJacketPantName((data["homeTeamUniform"].jacketPant_Name !== undefined) ? data["homeTeamUniform"].jacketPant_Name : "");
                setHomeJacketPantImg((data["homeTeamUniform"].jacketPant_Img_Url) !== undefined ? data["homeTeamUniform"].jacketPant_Img_Url : "");
                setHomeLongSleeveName((data["homeTeamUniform"].lS_Name !== undefined) ? data["homeTeamUniform"].lS_Name : "");
                setHomeLongSleeveImg((data["homeTeamUniform"].lS_Img_Url !== undefined) ? data["homeTeamUniform"].lS_Img_Url : "");
                setHomeShortSleeveName((data["homeTeamUniform"].sS_Name !== undefined) ? data["homeTeamUniform"].sS_Name : "");
                setHomeShortSleeveImg((data["homeTeamUniform"].sS_Img_Url !== undefined) ? data["homeTeamUniform"].sS_Img_Url : "");
                setHomeHatName((data["homeTeamUniform"].hat_Name !== undefined) ? data["homeTeamUniform"].hat_Name : "");
                setHomeHatImg((data["homeTeamUniform"].hat_Img_Url !== undefined) ? data["homeTeamUniform"].hat_Img_Url : "");
                setHomeTowelName((data["homeTeamUniform"].towel_Name !== undefined) ? data["homeTeamUniform"].towel_Name : "");
                setHomeTowelImg((data["homeTeamUniform"].towel_Img_Url !== undefined) ? data["homeTeamUniform"].towel_Img_Url : "");

                let awatTeamPreviewData = {};
                awatTeamPreviewData.gameId = data.gameId;
                awatTeamPreviewData.teamId = teamId;
                awatTeamPreviewData.gamedate = data.gameDate;
                awatTeamPreviewData.uniformData = data.awayTeamUniform;
                setOutfitAwayData(awatTeamPreviewData);

                setAwayTeamId((data["awayTeamUniform"].teamId !== undefined) ? data["awayTeamUniform"].teamId : "");
                setAwayTeamName((data["awayTeamUniform"].teamName !== undefined) ? data["awayTeamUniform"].teamName : "");
                setAwayTeamLogoURL((data["awayTeamUniform"].teamLogoURL !== undefined) ? data["awayTeamUniform"].teamLogoURL : "");
                setAwayBGColor((data["awayTeamUniform"].bG_Color !== undefined) ? data["awayTeamUniform"].bG_Color : "");
                setAwayTextColor((data["awayTeamUniform"].text_Color !== undefined) ? data["awayTeamUniform"].text_Color : "");
                setAwayShopImgUrl((data["awayTeamUniform"].shop_Img_Url !== undefined) ? data["awayTeamUniform"].shop_Img_Url : "");
                setAwayCourtName((data["awayTeamUniform"].court_Name !== undefined) ? data["awayTeamUniform"].court_Name : "");
                setAwayCourtImg((data["awayTeamUniform"].court_Img_Url !== undefined) ? data["awayTeamUniform"].court_Img_Url : "");
                setAwayEditionName((data["awayTeamUniform"].edition_Name !== undefined) ? data["awayTeamUniform"].edition_Name : "");
                setAwayEditionImg((data["awayTeamUniform"].edition_Img_Url !== undefined) ? data["awayTeamUniform"].edition_Img_Url : "");
                setAwayJacketPantName((data["awayTeamUniform"].jacketPant_Name !== undefined) ? data["awayTeamUniform"].jacketPant_Name : "");
                setAwayJacketPantImg((data["awayTeamUniform"].jacketPant_Img_Url) !== undefined ? data["awayTeamUniform"].jacketPant_Img_Url : "");
                setAwayLongSleeveName((data["awayTeamUniform"].lS_Name !== undefined) ? data["awayTeamUniform"].lS_Name : "");
                setAwayLongSleeveImg((data["awayTeamUniform"].lS_Img_Url !== undefined) ? data["awayTeamUniform"].lS_Img_Url : "");
                setAwayShortSleeveName((data["awayTeamUniform"].sS_Name !== undefined) ? data["awayTeamUniform"].sS_Name : "");
                setAwayShortSleeveImg((data["awayTeamUniform"].sS_Img_Url !== undefined) ? data["awayTeamUniform"].sS_Img_Url : "");
                setAwayHatName((data["awayTeamUniform"].hat_Name !== undefined) ? data["awayTeamUniform"].hat_Name : "");
                setAwayHatImg((data["awayTeamUniform"].hat_Img_Url !== undefined) ? data["awayTeamUniform"].hat_Img_Url : "");
                setAwayTowelName((data["awayTeamUniform"].towel_Name !== undefined) ? data["awayTeamUniform"].towel_Name : "");
                setAwayTowelImg((data["awayTeamUniform"].towel_Img_Url !== undefined) ? data["awayTeamUniform"].towel_Img_Url : "");
            }
        }).catch(err => {
            throw err;
        });
    }

    const backToDashboard = () => {
        props.history.goBack();
    }

    const pushToOutfitDetail = () => {
        let outfitData = (opositeTeamType === "1") ? outfitHomeTeamData : outfitAwayTeamData;
        let teamName = (opositeTeamType === "1") ? homeTeamName : awayTeamName;

        let teams = allTeams.filter(function (el) {
            return el.teamName == teamName;
        });

        let teamSEOName = (teams !== undefined && teams.length > 0) ? teams[0].teamNameSEO : "";

        props.history.push({
            pathname: `${RouteConstants.GAME_OUTFIT}/${teamSEOName}?gameid=${gameId}`,
            state: { data: outfitData }
        })

    }

    return (
        <Container maxWidth="false" >
            <HelmetMetaData
                title={"NBA LockerVision"}
                quote={"Every Locker, Every Player, Every Game."}
                url={window.location.href}
                hashtag={"#nba"}
                description={"NBA - LockerVision."}
            ></HelmetMetaData>
            {getLoadingloading && <CircularLoader />}
            <Grid container spacing={2}>
                <Box component="div" display="flex" alignItems="center" mt={3} mb={3} justifyContent="space-between" className={classes.title}>
                    <Box display="flex" alignItems="center">
                        <img src={(opositeTeamType === "1") ? homeTeamLogoURL : awayTeamLogoURL} className={classes.teamIcons} alt={`NBA LockerVision ${(opositeTeamType === "1") ? homeTeamName : awayTeamName}`} title={`NBA LockerVision ${(opositeTeamType === "1") ? homeTeamName : awayTeamName}`} />
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h5" component="h5">
                                {(opositeTeamType === "1") ? homeTeamName : awayTeamName}
                            </Typography>
                            <Typography variant="caption" component="p">
                                {Helper.FormatDayWithDate(gameDate)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box alignItems="center" display="flex" justifyContent="center">
                        <Chip variant="outlined"
                            label=""
                            icon={<ArrowBackIcon style={{ fontSize: 17, color: '#8f8f8f', 'margin-left': '12px' }} />}
                            className={classes.backchip}
                            onClick={(e) => {
                                e.preventDefault();
                                backToDashboard();
                            }} />
                        <TeamShopIcon teamName={(opositeTeamType === "1") ? homeTeamName : awayTeamName} />
                        <Chip
                            label={(opositeTeamType === "2") ? "Home Team" : "Away Team"}
                            clickable
                            component="a"
                            onClick={(e) => {
                                e.preventDefault();
                                setOpositeTeamType((opositeTeamType === "1") ? "2" : "1");
                            }}
                            onDelete={() => { return }}
                            deleteIcon={<OutfitIcon style={{ fontSize: 14, color: '#8f8f8f' }} />}
                            variant="outlined"
                            color="primary"
                            className={classes.chip}
                        />
                    </Box>
                </Box>
            </Grid>

            <Grid container className={classes.mainContainer} spacing={5}>
                <Grid item xs={6} sm={4} md={3}>
                    <Card className={classes.cardRoot}>
                        <Box display="flex" justifyContent="center" className={classes.customChip}>
                            <Chip label={(opositeTeamType === "1") ? homeEditionName : awayEditionName}
                                style={Helper.checkForComingSoon((opositeTeamType === "1") ? homeEditionImg : awayEditionImg) ? { background: "black", color: "white" } : { background: (opositeTeamType === "1") ? homeBGColor : awayBGColor, color: (opositeTeamType === "1") ? homeTextColor : awayTextColor }} />
                        </Box>
                        <CardActionArea>
                            <CardActions className={classes.cardAction}>
                                <Link>
                                    <SocialMediaContainer
                                        name="Uniform Edition"
                                        teamName={(opositeTeamType === "1") ? homeTeamName : awayTeamName}
                                        date={Helper.FormatDayWithDate(gameDate)}
                                        img={(opositeTeamType === "1") ? homeEditionImg : awayEditionImg}
                                        editionname={(opositeTeamType === "1") ? homeEditionName : awayEditionName}
                                        GameIDForShare={gameId} TeamIDForShare={teamId} />

                                </Link>
                                {/* <Link>
                                    <FavoriteBorderIcon />
                                </Link> */}
                            </CardActions>
                            <CardMedia
                                className={classes.cardMedia}
                                onClick={(e) => {
                                    e.preventDefault();
                                    pushToOutfitDetail();
                                }}
                            >
                                <img src={(opositeTeamType === "1") ? homeEditionImg : awayEditionImg} alt={`NBA LockerVision ${(opositeTeamType === "1") ? homeEditionName : awayEditionName}`} title={`NBA LockerVision ${(opositeTeamType === "1") ? homeEditionName : awayEditionName}`} />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="body2" component="p" align="center" className={classes.outfitText}>
                                    UNIFORM EDITION
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Card className={classes.cardRoot}>
                        <Box display="flex" justifyContent="center" className={classes.customChip}>
                            <Chip label={(opositeTeamType === "1") ? homeJacketPantName : awayJacketPantName}
                                style={Helper.checkForComingSoon((opositeTeamType === "1") ? homeJacketPantImg : awayJacketPantImg) ? { background: "black", color: "white" } : { background: (opositeTeamType === "1") ? homeBGColor : awayBGColor, color: (opositeTeamType === "1") ? homeTextColor : awayTextColor }} />
                        </Box>
                        <CardActionArea>
                            <CardActions className={classes.cardAction}>
                                <Link>
                                    <SocialMediaContainer
                                        name="Jacket/Pant"
                                        teamName={(opositeTeamType === "1") ? homeTeamName : awayTeamName}
                                        date={Helper.FormatDayWithDate(gameDate)}
                                        img={(opositeTeamType === "1") ? homeJacketPantImg : awayJacketPantImg}
                                        editionname={(opositeTeamType === "1") ? homeJacketPantName : awayJacketPantName}
                                        GameIDForShare={gameId} TeamIDForShare={teamId} />
                                </Link>
                                {/* <Link>
                                    <FavoriteBorderIcon />
                                </Link> */}
                            </CardActions>

                            <CardMedia
                                className={classes.cardMedia}
                                onClick={(e) => {
                                    e.preventDefault();
                                    pushToOutfitDetail();
                                }}
                            >
                                <img src={(opositeTeamType === "1") ? homeJacketPantImg : awayJacketPantImg} alt={`NBA LockerVision ${(opositeTeamType === "1") ? homeJacketPantName : awayJacketPantName}`} title={`NBA LockerVision ${(opositeTeamType === "1") ? homeJacketPantName : awayJacketPantName}`} />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="body2" component="p" align="center" className={classes.outfitText}>
                                    JACKET/PANT
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Card className={classes.cardRoot}>
                        <Box display="flex" justifyContent="center" className={classes.customChip}>
                            <Chip label={(opositeTeamType === "1") ? homeLongSleeveName : awayLongSleeveName}
                                 style={Helper.checkForComingSoon((opositeTeamType === "1") ? homeLongSleeveImg : awayLongSleeveImg) ? { background: "black", color: "white" } : { background: (opositeTeamType === "1") ? homeBGColor : awayBGColor, color: (opositeTeamType === "1") ? homeTextColor : awayTextColor }} />
                        </Box>
                        <CardActionArea>
                            <CardActions className={classes.cardAction}>
                                <Link>
                                    <SocialMediaContainer
                                        name="Long Sleeve"
                                        teamName={(opositeTeamType === "1") ? homeTeamName : awayTeamName}
                                        date={Helper.FormatDayWithDate(gameDate)}
                                        img={(opositeTeamType === "1") ? homeLongSleeveImg : awayLongSleeveImg}
                                        editionname={(opositeTeamType === "1") ? homeLongSleeveName : awayLongSleeveName}
                                        GameIDForShare={gameId} TeamIDForShare={teamId} />
                                </Link>
                                {/* <Link>
                                    <FavoriteBorderIcon />
                                </Link> */}
                            </CardActions>

                            <CardMedia
                                className={classes.cardMedia}
                                onClick={(e) => {
                                    e.preventDefault();
                                    pushToOutfitDetail();
                                }}
                            >
                                <img src={(opositeTeamType === "1") ? homeLongSleeveImg : awayLongSleeveImg} alt={`NBA LockerVision ${(opositeTeamType === "1") ? homeLongSleeveName : awayLongSleeveName}`} title={`NBA LockerVision ${(opositeTeamType === "1") ? homeLongSleeveName : awayLongSleeveName}`} />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="body2" component="p" align="center" className={classes.outfitText}>
                                    LONG SLEEVE
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Card className={classes.cardRoot}>
                        <Box display="flex" justifyContent="center" className={classes.customChip}>
                            <Chip label={(opositeTeamType === "1") ? homeShortSleeveName : awayShortSleeveName}
                                 style={Helper.checkForComingSoon((opositeTeamType === "1") ? homeShortSleeveImg : awayShortSleeveImg) ? { background: "black", color: "white" } : { background: (opositeTeamType === "1") ? homeBGColor : awayBGColor, color: (opositeTeamType === "1") ? homeTextColor : awayTextColor }} />
                        </Box>
                        <CardActionArea>
                            <CardActions className={classes.cardAction}>
                                <Link>
                                    <SocialMediaContainer
                                        name="Short Sleeve"
                                        teamName={(opositeTeamType === "1") ? homeTeamName : awayTeamName}
                                        date={Helper.FormatDayWithDate(gameDate)}
                                        img={(opositeTeamType === "1") ? homeShortSleeveImg : awayShortSleeveImg}
                                        editionname={(opositeTeamType === "1") ? homeShortSleeveName : awayShortSleeveName}
                                        GameIDForShare={gameId} TeamIDForShare={teamId} />
                                </Link>
                                {/* <Link>
                                    <FavoriteBorderIcon />
                                </Link> */}
                            </CardActions>

                            <CardMedia
                                className={classes.cardMedia}
                                onClick={(e) => {
                                    e.preventDefault();
                                    pushToOutfitDetail();
                                }}
                            >
                                <img src={(opositeTeamType === "1") ? homeShortSleeveImg : awayShortSleeveImg} alt={`NBA LockerVision ${(opositeTeamType === "1") ? homeShortSleeveName : awayShortSleeveName}`} title={`NBA LockerVision ${(opositeTeamType === "1") ? homeShortSleeveName : awayShortSleeveName}`} />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="body2" component="p" align="center" className={classes.outfitText}>
                                    SHORT SLEEVE
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Card className={classes.cardRootHat}>
                        <Box display="flex" justifyContent="center" className={classes.customChip}>
                            <Chip label={(opositeTeamType === "1") ? homeTowelName : awayTowelName}
                                style={Helper.checkForComingSoon((opositeTeamType === "1") ? homeTowelImg : awayTowelImg) ? { background: "black", color: "white" } :{ background: (opositeTeamType === "1") ? homeBGColor : awayBGColor, color: (opositeTeamType === "1") ? homeTextColor : awayTextColor }} />
                        </Box>
                        <CardActionArea>
                            <CardActions className={classes.cardAction}>
                                <Link>
                                    <SocialMediaContainer
                                        name="Towel"
                                        teamName={(opositeTeamType === "1") ? homeTeamName : awayTeamName}
                                        date={Helper.FormatDayWithDate(gameDate)}
                                        img={(opositeTeamType === "1") ? homeTowelImg : awayTowelImg}
                                        editionname={(opositeTeamType === "1") ? homeTowelName : awayTowelName}
                                        GameIDForShare={gameId} TeamIDForShare={teamId} />
                                </Link>
                                {/* <Link>
                                    <FavoriteBorderIcon />
                                </Link> */}
                            </CardActions>


                            <CardMedia
                                className={classes.cardMediaHat}
                                onClick={(e) => {
                                    e.preventDefault();
                                    pushToOutfitDetail();
                                }}
                            >
                                <img src={(opositeTeamType === "1") ? homeTowelImg : awayTowelImg} alt={`NBA LockerVision ${(opositeTeamType === "1") ? homeTowelName : awayTowelName}`} title={`NBA LockerVision ${(opositeTeamType === "1") ? homeTowelName : awayTowelName}`} />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="body2" component="p" align="center" className={classes.outfitText}>
                                    TOWEL
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Card className={classes.cardRootHat}>
                        <Box display="flex" justifyContent="center" className={classes.customChip}>
                            <Chip label={(opositeTeamType === "1") ? homeHatName : awayHatName}
                                style={Helper.checkForComingSoon((opositeTeamType === "1") ? homeHatImg : awayHatImg) ? { background: "black", color: "white" } :{ background: (opositeTeamType === "1") ? homeBGColor : awayBGColor, color: (opositeTeamType === "1") ? homeTextColor : awayTextColor }} />
                        </Box>
                        <CardActionArea>
                            <CardActions className={classes.cardAction}>
                                <Link>
                                    <SocialMediaContainer
                                        name="Hat"
                                        teamName={(opositeTeamType === "1") ? homeTeamName : awayTeamName}
                                        date={Helper.FormatDayWithDate(gameDate)}
                                        img={(opositeTeamType === "1") ? homeHatImg : awayHatImg}
                                        editionname={(opositeTeamType === "1") ? homeHatName : awayHatName}
                                        GameIDForShare={gameId} TeamIDForShare={teamId} />
                                </Link>
                                {/* <Link>
                                    <FavoriteBorderIcon />
                                </Link> */}
                            </CardActions>

                            <CardMedia
                                className={classes.cardMediaHat}
                                onClick={(e) => {
                                    e.preventDefault();
                                    pushToOutfitDetail();
                                }}
                            >
                                <img src={(opositeTeamType === "1") ? homeHatImg : awayHatImg} alt={`NBA LockerVision ${(opositeTeamType === "1") ? homeHatName : awayHatName}`} title={`NBA LockerVision ${(opositeTeamType === "1") ? homeHatName : awayHatName}`} />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="body2" component="p" align="center" className={classes.outfitText}>
                                    HAT
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                {
                    ((opositeTeamType === "1") ? homeCourtName : awayCourtName) !== '' ?
                        <Grid item xs={6} sm={4} md={3}>
                            <Card className={classes.cardRootHat}>
                                <Box display="flex" justifyContent="center" className={classes.customChip}>
                                    <Chip label={(opositeTeamType === "1") ? homeCourtName : awayCourtName}
                                        style={Helper.checkForComingSoon((opositeTeamType === "1") ? homeCourtImg : awayCourtImg) ? { background: "black", color: "white" } :{ background: (opositeTeamType === "1") ? homeBGColor : awayBGColor, color: (opositeTeamType === "1") ? homeTextColor : awayTextColor }} />
                                </Box>
                                <CardActionArea>
                                    <CardActions className={classes.cardAction}>
                                        <Link>
                                            <SocialMediaContainer
                                                name="Court"
                                                teamName={(opositeTeamType === "1") ? homeTeamName : awayTeamName}
                                                date={Helper.FormatDayWithDate(gameDate)}
                                                img={(opositeTeamType === "1") ? homeCourtImg : awayCourtImg}
                                                editionname={(opositeTeamType === "1") ? homeCourtName : awayCourtName}
                                                GameIDForShare={gameId} TeamIDForShare={teamId} />
                                        </Link>
                                        {/* <Link>
                                            <FavoriteBorderIcon />
                                        </Link> */}
                                    </CardActions>

                                    <CardMedia
                                        className={classes.cardMediaHat}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            pushToOutfitDetail();
                                        }}
                                    >
                                        <img src={(opositeTeamType === "1") ? homeCourtImg : awayCourtImg} alt={`NBA LockerVision ${(opositeTeamType === "1") ? homeCourtName : awayCourtName}`} />
                                    </CardMedia>
                                    <CardContent>
                                        <Typography variant="body2" component="p" align="center" className={classes.outfitText}>
                                            COURT
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        :
                        null
                }
                {sponsorsData !== undefined && sponsorsData.length > 0 && sponsorsData.map((item, index) => {
                    if (item.adSource !== undefined) {
                        return (
                            <SponsorAd from="Outfit" sponsorsAdSource={item.adSource} sponsorsAdLink={item.adLink} />
                        )
                    }
                })}

            </Grid>
        </Container>
    );
}
export default withRouter(GameOutfitContainer);