import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { getInSeasonGamePics } from "../../services/home.service";
import { getTrendingOutfits } from "../../services/uniform.service";
import { withRouter } from 'react-router-dom';
import BasketBallHoop from "../CustomIcons/BasketBallHoop";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(5),
    },
    mainContainer: {
        background: 'rgba(0, 0, 0, .65) !important',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderRadius: theme.spacing(1.25),
        marginTop: theme.spacing(-3),
    },
    cardRoot: {
        background: 'rgba(0, 0, 0, .3) !important',
        border: '1px solid #888888',
        boxShadow: 'none',
        height: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardHeader-avatar': {
            display: 'flex',
            width: '100%',
            marginRight: '0',
        },
        '& .MuiCardActions-root': {
            background: '#23282c',
            color: '#888888',
            border: '1px solid #000',
            borderTop: 'transparent',
        },
    },

    cardRootTradendingContainer: {
        background: 'rgba(0, 0, 0, .3)  !important',
        border: '1px solid #888888',
        boxShadow: 'none',
        height: '75%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardHeader-avatar': {
            display: 'flex',
            width: '100%',
            marginRight: '0',
        },
    },

    versus: {
        fontFamily: '"Action NBA  Web"  !important',
        fontWeight: '500',
        color: '#fff',
    },

    topHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#888888',
        padding: theme.spacing(1.5, 0),
        '& h6': {
            fontSize: '1rem',
            position: 'relative',
        },
        '& h6:after': {
            content: '""',
            position: 'absolute',
            height: '1px',
            background: '#888888',
            width: '27%',
            left: '0',
            bottom: '0',
        },
        '& .MuiButton-text': {
            color: '#888888',
            fontSize: '.75rem',
        },
    },
    boxContainer: {
        borderTop: '1px solid #888',
        borderBottom: '1px solid #888',
        '& > .MuiGrid-item:first-child': {
            borderRight: '1px solid #888888',
        },
    },
    cardMedia: {
        display: 'flex',
        background: '#000000',
        border: '1px solid #888888',
        padding: '0',
        borderRadius: theme.spacing(.5),
        height: '100%',
        '& img': {
            height: '100%',
            width: '100%',
            borderRadius: theme.spacing(.5),
            objectFit: 'contain',
        },
    },

    teamIcons: {
        maxWidth: '70px',
        border: '1px solid #888888',
        cursor: 'pointer',
        [theme.breakpoints.only('md')]: {
            maxWidth: '100px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '80px',
        },
    },

    teamLogo: {
        maxWidth: '27px',
    },
    textCenter: {
        textAlign: 'center',
        color: '#888888',
        paddingBottom: "20px"
    },
    paddingBottom0: {
        paddingBottom: '0 !important',
    },
    teamOutfitIcons: {
        maxWidth: '80px',
        margin: '0 auto',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100px',
        },
    },
    chipPosition: {
        position: 'relative',
    },
    chip: {
        fontSize: '.63rem',
        letterSpacing: '0.0500em',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.5, 0, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
        [theme.breakpoints.down('md')]: {
            fontSize: '.60rem',
        },
        '& .MuiChip-label': {
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, .5, 0, .5)
            },
        },
    },
    customEditionChip: {
        position: 'absolute',
        zIndex: '1',
        top: '0',
        width: '100%',
        left: '0',
        right: '0',
        '& .MuiChip-root': {
            height: 'auto',
            fontSize: '.50rem',
            fontWeight: '500',
            border: '1px solid #b5b5b5',
            '& .MuiChip-label': {
                paddingLeft: theme.spacing(.75),
                paddingRight: theme.spacing(.75),
            },
        },
    },
    teamName: {
        fontFamily: '"Action NBA  Web"  !important',
        fontWeight: '200 !important',
        lineHeight: '1 !important',
    },

    trendingTeamName: {
        fontFamily: '"Action NBA  Web"  !important',
        color: '#888888',
        lineHeight: '1',
        textTransform: 'uppercase'
    },
    outfitCaption: {
        color: '#888888',
        textTransform: 'uppercase'
    },
}));


const NoGameInSessionContainer = (props) => {
    const classes = useStyles();
    const [Games, setGamesPics] = useState([]);
    const [TrendingOutfit, setTrendingOutfit] = useState([]);


    useEffect(() => {
        getOutfitPics();
        //GetTrendingOutfits();
    }, [])

    const getOutfitPics = () => {
        getInSeasonGamePics().then(data => {
            if (data !== undefined) {
                setGamesPics(data);
            }
        }).catch(err => {
            throw err;
        });
    }
    const GetTrendingOutfits = () => {
        getTrendingOutfits().then(data => {
            if (data !== undefined) {
                setTrendingOutfit(data);
            }
        }).catch(err => {
            throw err;
        });
    }
    const pushToOutfitDetail = (gameId, teamId) => {
        props.history.push({
            pathname: '/storyguide',
            state: { outfitGameId: gameId, outfitTeamId: teamId }
        })
    }


    return (
        <Container maxWidth="false" disableGutters={true}>
            {Games != undefined && Games.length == 0}
            <Grid item xs={12} sm={12}>
                <div className={classes.textCenter}>
                    <BasketBallHoop fontSize="large" />
                    {/*<Typography variant="body1" component="h6">No Games are scheduled for selected filter</Typography>*/}
                    <Typography variant="body1" component="h6">No Games are available</Typography>
                </div>
            </Grid>
        </Container>
    );
}
export default withRouter(NoGameInSessionContainer);