import React, { useState, useEffect, useContext } from "react";
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import AppContext from '../../common/AppContext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ScheduleDateRange from './ScheduleDateRange';
import { loadCSS } from 'fg-loadcss';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import AppConstants from '../../common/AppConstants';
import Divider from '@material-ui/core/Divider';
import { Typography } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';


const drawerWidth = 312;
const useStyles = makeStyles((theme) => ({
    root: {
        '& > .fa': {
            margin: theme.spacing(2),
        },
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        background: 'rgba(0, 0, 0, .9)',
        color: '#b5b5b5',
        borderRight: '1px solid #282828',
        marginTop: '64px'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    drawerContainer: {
        overflow: 'auto',
        paddingBottom: theme.spacing(3),
        '& .MuiListItem-button': {
            '&:hover': {
                background: '#2a2a2a',
            },
        },
    },
    img: {
        height: theme.spacing(4),
        width: theme.spacing(4)
    },
    MenuItemButton: {
        textDecoration: 'none',
        backgroundColor: '#2a2a2a'
    },
    MenuItemDrawer: {
        color: "#fff",
        position: "absolute",
        top: "70px",
        left: "10px"
    },
    // MenuListItemButton: {
    //     '&:hover, &:focus': {
    //         color: '#FFF !importantt',
    //     },
    // },
    menuListItems: {
        '& .MuiMenuItem-root': {
        }
    },
    '& .rlc-date-time-selects': {
        display: "none"
    },
    '& .rlc-day  rlc-day-out-of-month rlc-day-16': {
        fontSize: '20px'
    },
    drawerActions: {
        '& > *': {
            margin: theme.spacing(0),
        },
    },
    '& .MuiBottomNavigation-root.MuiBottomNavigationAction-wrapper': {
        backgroundColor: 'black'
    },
    applySection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-center',
    },
    drawerActionButton: {
        color: '#8f8f8f'
    },
    drawerActionLabel: {
        margin: theme.spacing(.5),
    },
    applyFilters: {
        color: "#fff",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(12),
        fontSize: '12px',
        width: '105px',
        height: '25px',
        cursor: 'pointer',
        backgroundColor: '#565454'
    },
    resetFilters: {
        color: "#fff",
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(1),
        fontSize: '12px',
        width: '125px',
        height: '25px',
        cursor: 'pointer',
        backgroundColor: '#565454'
    },
    dateLabel: {
        paddingLeft: theme.spacing(3),
    },
    scheduleIcon: {
        verticalAlign: 'middle',
        fontSize: '17px'
    },
    hideDrawer: {
        cursor: 'pointer',
        color: '#8f8f8f',
    },
    seasonDateRange: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    listDivider: {
        borderTop: '1px solid #282828'
    },
    listDividerLast: {
        borderTop: '1px solid #282828',
        borderBottom: '1px solid #282828'
    },
    calender: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        '& .rlc-calendar': {
            border: '1px solid #7c7777',
        },
        '& .rlc-days .rlc-day-out-of-month.rlc-day': {
            fontSize: '14px',
            color: '#7c7777',
        },
        '& .rlc-days .rlc-day.rlc-day-disabled.rlc-day': {
            fontSize: '14px',
            backgroundColor: 'black',
            color: '#7c7777',
            cursor: 'not-allowed'
        },
        '& .rlc-days .rlc-day': {
            fontSize: '14px',
            color: '#b3b3b3',
            fontWeight: 'bold'
        },
        '& .rlc-day-end-selection, .rlc-day-inside-selection, .rlc-day-selected, .rlc-day-start-selection': {
            backgroundColor: '#565454',
        },
        '& .rlc-month-and-year-wrapper': {
            borderBottom: '1px solid #7c7777',
            borderTop: '1px solid #7c7777'
        },
        '& .rlc-date-number': { color: '#b3b3b3', fontSize: '27px' },
        '& .rlc-detail-month-year': { color: '#b3b3b3' },
        '& .rlc-detail-day': { color: '#b3b3b3' },
        '& .rlc-days-label .rlc-day-label': { color: '#b3b3b3', fontSize: '12px' },
        '& .rlc-month-and-year': { color: '#b3b3b3' },
        '& .rlc-navigation-button-wrapper, .rlc-nexts, .rlc-navigation-button, .rlc-next-month': { color: '#b3b3b3' },
    }
}));

const SideBarFilter = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [drawer, showDrawer] = useState(false);
    const [openDates, setopenDates] = useState(false);
    const [openTeams, setopenTeams] = useState(false);
    const [teams, setTeamList] = useState('');
    const [openGameType, setopenGameType] = useState(false);

    let selectedTeam = sessionStorage.getItem(AppConstants.TEAM_FILTER);
    const [selectedTeamId, setSeletedTeamId] = useState(selectedTeam === null ? "" : selectedTeam);

    let selGameType = sessionStorage.getItem(AppConstants.GAME_TYPE_FILTER);
    const [selectedGameType, setSelectedGameType] = useState(selGameType === null ? "" : selGameType);

    const { GameTypes } = useContext(AppContext);

    useEffect(() => {
        const node = loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );

        showDrawer(props.isShowDrawer);
        setTeamList(props.teams);

    }, [props.isShowDrawer, props.teams]);


    const handleTeamClick = (dataValue) => {
        if (dataValue !== undefined) {
            //If user selects 'All Teams' and Game Type is not 'All Games', show message to the user that
            //Game type is set to 'All Games' because 'All Teams' is selected. 
            //Team must be selected to apply game type filter
            if (dataValue === AppConstants.ALL_TEAMS &&
                sessionStorage.getItem(AppConstants.GAME_TYPE_FILTER) !== AppConstants.ALL_GAMES) {
                sessionStorage.setItem(AppConstants.GAME_TYPE_FILTER, AppConstants.ALL_GAMES);
                setSelectedGameType(AppConstants.ALL_GAMES);
                props.showAllGameTypeSelectedMessage();
            }

            setSeletedTeamId(dataValue);
            sessionStorage.setItem(AppConstants.TEAM_FILTER, dataValue);
        }
    }

    const handleGameTypeClick = (dataValue) => {
        if (dataValue !== undefined) {
            if (sessionStorage.getItem(AppConstants.TEAM_FILTER) === AppConstants.ALL_TEAMS) {
                props.showSelectTeamMessage();
            }
            else {
                sessionStorage.setItem(AppConstants.GAME_TYPE_FILTER, dataValue);
                setSelectedGameType(dataValue);
            }
        }
    }

    const handleResetFiltersClick = (e) => {
        sessionStorage.setItem(AppConstants.START_DATE_FILTER, '');
        sessionStorage.setItem(AppConstants.END_DATE_FILTER, '');
        sessionStorage.setItem(AppConstants.GAME_TYPE_FILTER, AppConstants.ALL_GAMES);
        sessionStorage.setItem(AppConstants.TEAM_FILTER, AppConstants.ALL_TEAMS);
        props.getData(true);
        
    }

    return (
        <>
            <React.Fragment>
                <Drawer
                    anchor={'left'}
                    open={drawer}
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    onClose={() => {
                        showDrawer(false);
                        props.changeDrawerStatus(false);
                    }}>

                    <Toolbar>
                        <div className={classes.drawerActions}>
                            <IconButton
                                className={classes.drawerActionButton}
                                aria-label="apply"
                                onClick={() => { props.getData() }}>
                                <CheckCircleIcon />
                                <Typography variant="caption" className={classes.drawerActionLabel}>Apply</Typography>
                            </IconButton>
                            <IconButton className={classes.drawerActionButton} aria-label="clear" onClick={handleResetFiltersClick}>
                                <HighlightOffIcon /> <Typography variant="caption" className={classes.drawerActionLabel}>Reset</Typography>
                            </IconButton>
                            <IconButton className={classes.hideDrawer} aria-label="clear" onClick={(e) => {
                                e.preventDefault();
                                props.changeDrawerStatus(false);
                            }} >
                                <Icon className="fas fa-chevron-circle-left" fontSize="small" />
                                <Typography variant="caption" className={classes.drawerActionLabel}
                                >Hide</Typography>
                            </IconButton>
                        </div>
                    </Toolbar>

                    <div className={classes.drawerContainer}>
                        <List>
                            <ListItem className={classes.listDivider} button onClick={() => {
                                setopenTeams(!openTeams);
                                setopenDates(false);
                                setopenGameType(false);
                            }} key={"Teams"}>

                                <ListItemText>Teams</ListItemText>
                                {openTeams ? <ExpandLess /> : <ExpandMoreIcon />}
                            </ListItem>

                            <Collapse in={openTeams} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {teams !== undefined && teams.length > 0 && teams.map((item, index) => {
                                        if (item.logoURL !== null)
                                            return <MenuItem value={item.teamId} className={(item.teamId == selectedTeamId) ? classes.MenuItemButton : ""} onClick={(e) => {
                                                e.preventDefault();
                                                handleTeamClick(e.target.value);
                                            }}><img src={item.logoURL} className={classes.img}></img>{item.teamName}</MenuItem>
                                        else
                                            return <MenuItem value={item.teamName} onClick={(e) => {
                                                e.preventDefault();
                                                handleTeamClick(e.target.textContent);
                                            }}>{item.teamName}</MenuItem>

                                    })}
                                </List>
                            </Collapse>
                            <Divider />

                            <ListItem
                                className={classes.listDivider}
                                button onClick={() => {
                                    setopenDates(!openDates);
                                    setopenTeams(false);
                                    setopenGameType(false);
                                }} key={"Calendar"}>

                                <ListItemText>Calendar</ListItemText>
                                {openDates ? <ExpandLess /> : <ExpandMoreIcon />}

                            </ListItem>

                            <Collapse in={openDates} timeout="auto" unmountOnExit className={classes.menuListItems}>
                                <Typography variant="caption" className={classes.seasonDateRange}>
                                    Season Date Range: {props.calenderMinDate} To {props.calenderMaxDate}
                                </Typography>

                                <div className={classes.calender}>
                                    <ScheduleDateRange
                                        calenderMinDate={props.calenderMinDate}
                                        calenderMaxDate={props.calenderMaxDate} />
                                </div>
                            </Collapse>

                            <Divider />

                            <ListItem
                                className={classes.listDividerLast}
                                button onClick={() => {
                                    setopenGameType(!openGameType);
                                    setopenTeams(false);
                                    setopenDates(false);
                                }} key={"GameType"}>

                                <ListItemText>Games</ListItemText>
                                {openGameType ? <ExpandLess /> : <ExpandMoreIcon />}
                            </ListItem>

                            <Collapse in={openGameType} timeout="auto" unmountOnExit className={classes.menuListItems}>
                                <List component="div" disablePadding>
                                    {GameTypes !== undefined && GameTypes.length > 0 && GameTypes.map((item, index) => {
                                        return <MenuItem value={item.GameTypeCode} className={(item.GameTypeCode == selectedGameType) ? classes.MenuItemButton : ""}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleGameTypeClick(e.target.textContent);
                                            }}>{item.Title}</MenuItem>
                                    })}
                                </List>
                            </Collapse>
                            <Divider />
                        </List>
                    </div>
                </Drawer>
            </React.Fragment>


        </>
    );
}
export default withRouter(SideBarFilter);
