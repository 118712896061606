import React , { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      background: 'rgba(255, 255, 255, .5)',
      padding: theme.spacing(1, 1.5),
      borderRadius: theme.spacing(2),
      '& .MuiDialogTitle-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'rgba(255, 255, 255, .5)',
        padding: theme.spacing(0),
        '& .MuiIconButton-root': {
          color: 'rgba(255, 255, 255, .5)',
        },
      },
    },
  }
}));

function DialogBox(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false)
  };

 return (
    <>
      <Dialog onClose={handleClose} className={classes.root} aria-labelledby="simple-dialog-title" open={open}>
        <MuiDialogTitle disableTypography>
            <Typography variant="caption">You have been Log out successfully.</Typography>
            <IconButton aria-label="close" size="small" className={classes.closeButton} onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
        </MuiDialogTitle>
      </Dialog>
    </>
  );
}
export default withRouter(DialogBox);