import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Helper from '../../common/Helper';

const useStyles = makeStyles((theme) => ({
    chip: {
        fontSize: '.63rem',
        letterSpacing: '0.0500em',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.5, 0, .5, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .5, 0, .5),
    },
    chipForXScroll: {
        fontSize: '.63rem',
        letterSpacing: '0.0500em',
        color: '#8f8f8f',
        borderColor: '#8f8f8f',
        margin: theme.spacing(.25, 0, .25, 0),
        textTransform: 'uppercase',
        padding: theme.spacing(0, .25, 0, .25),
        height: "20px",
    },
}));

const TeamShopIcon = (props) => {
    const classes = useStyles();

    const navigateToStore = (e) => {
        Helper.OpenShopLink(props.teamName)
    }

    return (
        <Chip
            id={props.teamName}
            clickable
            label="Shop"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigateToStore();
            }}
            onDelete={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigateToStore();
            }}
            deleteIcon={props.xScroll ? <></> : <></>}
            variant="outlined"
            color="primary"
            className={props.xScroll ? [classes.chipForXScroll, "penone"] : [classes.chip, "penone"]}
            data-gaeventname = "shop_click"
            data-gaeventcategory= {`${props.teamName} Shop`} 
            data-gaeventlabel={`${props.teamName} NBA Store`}
            data-gaeventaction={`${Helper.getShopUrl(props.teamName)}`}
        />
    )
}

export default TeamShopIcon;