import React, { useState, useEffect, useContext } from "react";
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { GetTeamList, GetEditionList, GetUpcomingGames } from "../../services/home.service";
import LoadingAppUI from '../../sharedComponents/emptyStateUIContainers/LoadingAppUI';
import SponsorAd from '../../sharedComponents/SponsorContainer/SponsorAd';
import CircularLoader from '../../sharedComponents/emptyStateUIContainers/CircularLoader';
import TeamSlider from "../LockerByCategory/TeamSlider";
import UpcomingGames from "../LockerByCategory/UpcomingGames";
import Featured from "./Featured";
import RouteConstants from "../../common/RouteConstants";
import GameSlider from "./GameSlider";
import StarEventsSlider from "../../sharedComponents/StarsEventSlider/StarEventSlider";
import SliderForTeam from "./SliderForTeam";
import SliderForEdition from "./SliderForEdition";
import HelmetMetaData from "../../sharedComponents/SocialMedia/HelmetMetaDeta";
import AppDataContext from '../../common/AppContext';
import AppConstants from '../../common/AppConstants';
import Helper from '../../common/Helper';

const useStyles = makeStyles((theme) => ({
    headerText: {
        color: '#fff',
        fontFamily: '"Action NBA  Web" !important',
        fontSize: '1.8rem',
        fontWeight: '300',
        lineHeight: '1'
    },
    link: {
        cursor: 'pointer'
    },
    headerContainer: {
        color: '#ffffff !important',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 3.5),
        },
    },
    gamesMainContainerForXScroll: {
        background: 'rgba(0, 0, 0, .65) !important',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderRadius: theme.spacing(1.25),
        '&::-webkit-scrollbar': {
            height: '4px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#585858',
            outline: '1px solid #585858',
        },

    },
    gameSlider: {
        color: '#ffffff !important',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 3.5)
        },
        '& .slick-slider': {
            height: "100%"
        },
    }
}));

const HeaderText = (props) => {
    const classes = useStyles();
    const { sponsorAds, sponsorAdsFetched, GetSponsorAds } = useContext(AppDataContext);
    //const [homeAdDetails, setHomeAdDetails] = useState(sponsorAdsFetched && sponsorAds.find(ad => ad.page === "home"));
    const [homeAdDetails, setHomeAdDetails] = useState(!sponsorAds || sponsorAds.length === 0 ?
        Helper.GetPageAds("home") : sponsorAds.find(ad => ad.page === "home"));

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!sponsorAdsFetched) {
            GetSponsorAds();
        }
        else {
            setHomeAdDetails(Helper.GetPageAds("home"));
        }
    }, [sponsorAdsFetched])

    return (
        <Container className={classes.headerContainer} maxWidth={false}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4" component="h4" className={classes.headerText}><span>{props.text}</span>
                </Typography>
                {AppConstants.DISPLAY_SPONSORS && sponsorAdsFetched && homeAdDetails &&
                    <Hidden smDown>
                        <Box display="flex">
                            <SponsorAd
                                adDisplay={AppConstants.AD_DISPLAY_DESKTOP}
                                adDetails={homeAdDetails}
                            />
                        </Box>
                    </Hidden>
                }
                {props.showViewAll === true && <Typography aria-label="View All" variant="body2" component="span" className={classes.link} onClick={() => props.onClick()}>View All
                </Typography>}
            </Box>
            {AppConstants.DISPLAY_SPONSORS && sponsorAdsFetched && homeAdDetails &&
                <Hidden mdUp>
                    <Box display="flex" alignItems="center" justifyContent="center" flex="1" marginTop="1">
                        <SponsorAd
                            adDisplay={AppConstants.AD_DISPLAY_MOBILE}
                            adDetails={sponsorAds.find(ad => ad.page === "home")}
                        />
                    </Box>
                </Hidden>
            }
        </Container>
    )
};



const Home = (props) => {
    const classes = useStyles();
    const { sponsorAdsFetched, starGames, showStarGames } = useContext(AppDataContext);
    const [isLoading, setLoading] = useState(true);
    const [TilesState, setTilesState] = useState([]);
    const [games, setUpcomingGames] = useState([]);
    const { allTeams, allEditions, sponsorAds } = useContext(AppDataContext);
    const keywords = process.env.REACT_APP_SEO_KEYWORDS_FOR_HOME_PAGE;
    const description = process.env.REACT_APP_SEO_DESCRIPTION_FOR_HOME_PAGE;
    useEffect(() => {
        window.scrollTo(0, 0);
        getUpcomingGames();
        sessionStorage.setItem("selectedTab", "Home");
        props.changeTabInHeader("Home");
    }, []);

    const getUpcomingGames = () => {
        let FavTeamId = "";
        if (localStorage.getItem("UserDetail") !== null && localStorage.getItem("UserDetail") !== undefined) {
            let userData = JSON.parse(localStorage.getItem("UserDetail"));
            FavTeamId = ((localStorage.getItem("UserDetail") !== null && localStorage.getItem("UserDetail") !== undefined && userData.favoriteTeams !== undefined) ? userData.favoriteTeams[0] : "");
        }

        GetUpcomingGames(FavTeamId)
            .then(data => {
                if (data && data.length > 0) {
                    setLoading(false);
                    setUpcomingGames(data);
                } else {
                    setLoading(false);
                    setUpcomingGames([]);
                }
            }).catch(err => {
                throw err;
            });
    }

    // helper function to redirect user to editions/teams page
    const pushtoRoute = (id, name, index, type) => {
        let byTeam = type === "team";
        let byEdition = type === "edition";
        if (byTeam) {
            sessionStorage.setItem("activeTileTeam", index);
            if (name !== "") {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_TEAM + "/" + name,
                    state: { team: byTeam, edition: byEdition, teamName: allTeams[index].teamName }
                });
            } else {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_TEAM,
                    state: { team: byTeam, edition: byEdition, teamName: allTeams[0].teamName }
                });
            }

        } else {
            sessionStorage.setItem("activeTileEdition", index);
            if (name !== "") {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_EDITION + "/" + name,
                    state: { team: byTeam, edition: byEdition, editionName: allEditions[index].editioN_NAME }
                });
            } else {
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_EDITION,
                    state: { team: byTeam, edition: byEdition, editionName: allEditions[0].editioN_NAME }
                });
            }
        }
    }
    const pushToScheduleRoute = () => {
        props.history.push({
            pathname: RouteConstants.SCHEDULE
        });
    }

    //let showStarsSection = starGames.allStarGames.length > 0 || starGames.risingStarData ? true : false;

    return (
        <div>
            <HelmetMetaData
                title={"NBA LockerVision - Home"}
                quote={"Every Locker, Every Player, Every Game."}
                url={window.location.href}
                hashtag={"#nba"}
                description={description}
                keywords={keywords}
            ></HelmetMetaData>
            {isLoading && !sponsorAdsFetched && <CircularLoader />}

            {!isLoading && sponsorAdsFetched && <>

                {showStarGames &&  <>
                    <HeaderText
                        renderAd={true}
                        showViewAll={false}
                        text="Uniforms for NBA All-Star Weekend"/>

                    <Grid container spacing={2} className={classes.gamesMainContainerForXScroll}>
                        <StarEventsSlider
                            highlightInHeader={() => {
                                props.changeTabInHeader("schedule");
                            }}
                            displayPage="Home"
                            starGames={starGames}
                            xScroll={true}
                        />
                    </Grid></>}

                {games !== undefined && games.length > 0 && <>
                    <HeaderText
                        renderAd={true}
                        showViewAll={true}
                        text={"Outfitting Schedule for Upcoming NBA Games"}
                        onClick={() => { pushToScheduleRoute(); }} />

                    <Grid container spacing={2} className={classes.gamesMainContainerForXScroll}>
                        <GameSlider highlightInHeader={() => {
                            props.changeTabInHeader("schedule");
                        }} Games={(games !== undefined && games.length) > 0 ? games : []} xScroll={true} />
                    </Grid></>}

                <HeaderText
                    renderAd={false}
                    showViewAll={true}
                    text={"All NBA Uniforms By Team"}
                    onClick={() => { pushtoRoute(null, "atlanta-hawks", 0, "team") }} />

                <Grid container spacing={2} className={classes.gamesMainContainerForXScroll}>
                    <TeamSlider
                        populateGames={(teamID, teamName, index) => {
                            //calling props function to change highlight in header
                            props.changeTabInHeader("team");
                            pushtoRoute(teamID, teamName, index, "team");
                        }}
                        Teams={allTeams}
                        ActiveState={TilesState}
                        type={"team"}
                        dashboard={true}
                    />
                </Grid>

                <HeaderText
                    renderAd={false}
                    showViewAll={true}
                    text={"All NBA Uniforms By Edition"}
                    onClick={() => { pushtoRoute(null, "association-edition", 0, "edition") }} />

                <Grid container spacing={2} className={classes.gamesMainContainerForXScroll}>
                    <TeamSlider
                        populateGames={(editionID, editioN_NAME, index) => {
                            //calling props function to change highlight in header
                            props.changeTabInHeader("edition");
                            pushtoRoute(editionID, editioN_NAME, index, "edition")
                        }}
                        Editions={allEditions}
                        ActiveState={TilesState}
                        type={"edition"}
                        dashboard={true}
                    />
                </Grid>
            </>}
        </div>
    )
}

export default withRouter(Home);