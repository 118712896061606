import React, { useState, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        marginRight: theme.spacing(0.5),
        fontSize: "15px"
    },
    list: {
        '&:focus': {
            outline: 'none',
        },
    },
    menu: {
        width: '250px'
    },
    itemIcon: {
        minWidth: '35px'
    },
    avatar: {
        backgroundColor: "black"
    },
    version: {
        paddingLeft: theme.spacing(2)
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },

}));

export default function AppBarMenu(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
 
    return (
        <div className={classes.root}>
                    <>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            size="small"
                        >
                            <AccountCircle />
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >

                            <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                className={classes.root}
                            >

                                <ListItem className={classes.menu} style={{ paddingBottom: '20px' }}>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>{`${props.firstName.charAt(0)}${props.lastName.charAt(0)}`}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText id="user-name" primary={`${props.firstName} ${props.lastName}`} secondary="" />
                                </ListItem>
                               

                                <Divider></Divider>
                                <MenuItem onClick={handleClose} >
                                    <ListItemIcon className={classes.itemIcon}>
                                        <PermIdentityOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="My Profile"  onClick={(e) => {
                                         e.preventDefault(); 
                                         props.openProfileWidget(); 
                                         }}/>
                                </MenuItem>

                                <MenuItem onClick={handleClose} >
                                    <ListItemIcon className={classes.itemIcon}>
                                        <VpnKeyOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Change Password" onClick={(e) => {
                                         e.preventDefault(); 
                                         props.openChangePasswordWidget(); 
                                         }} />
                                </MenuItem>

                                <MenuItem onClick={handleClose} >
                                    <ListItemIcon className={classes.itemIcon}>
                                        <ExitToAppIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Log out"  onClick={(e) => {
                                         e.preventDefault(); 
                                         props.signOut(); 
                                         }}/>
                                </MenuItem>

                            </List>
                        </Menu>
                     </>
        </div>
    );
} 