const RouteConstants = {
    HOME: '/',
    SIGN_UP: '/signup',
    SIGN_IN: '/signin',
    ABOUT_US: '/about',
    SCHEDULE: '/schedule',
    OUTFIT: '/outfit',
    GAME_OUTFIT: '/game-outfit',
    SESSION_TIMEOUT: '/sessionTimeout',
    ACCESS_DENIED: '/accessDenied',
    LOCKER_BY_TEAM:'/team',
    LOCKER_BY_EDITION: '/edition',    
    ALL_STAR_GAME_OUTFIT: '/allstar-outfit',
    RISING_STAR_GAME_OUTFIT: '/risingstar-outfit',
}

export default RouteConstants;